import server from "./server";

export const postUserLogin = async (data) => {
  data = {
    ...data,
    loginType: "dashboard",
  };

  const response = await server().post("/auth/login", data);

  return response.data;
};

export const getCurrentUser = async (data) => {
  const response = await server().get("/auth/myProfile", data);

  return response.data;
};

export const getAllUsersApi = async (searchParams) => {
  const response = await server().get(`/users/?${searchParams}`);

  return response.data;
};

export const addUserApi = async (data) => {
  const response = await server().post("/auth/register", data);

  return response.data;
};

export const getUserApi = async (id) => {
  const response = await server().get(`/users/${id}`);

  return response.data;
};

export const editUserApi = async ({ id, data }) => {
  const response = await server().put(`/auth/editProfileDashboard/${id}`, data);

  return response.data;
};

export const penaltyUserApi = async ({ id, data }) => {
  const response = await server().put(`/auth/editProfileDashboard/${id}`, data);

  return response.data;
};

export const deleteUserApi = async (id) => {
  const response = await server().delete(`/users/${id}`);

  return response.data;
};
