import {
  GET_ALL_SETTINGS,
  GET_ALL_SETTINGS_SUCCESS,
  GET_ALL_SETTINGS_FAILURE,

  EDIT_MAIN_SETTINGS,
  EDIT_MAIN_SETTINGS_SUCCESS,
  EDIT_MAIN_SETTINGS_FAILURE,

  EDIT_SOCIALS_SETTINGS,
  EDIT_SOCIALS_SETTINGS_SUCCESS,
  EDIT_SOCIALS_SETTINGS_FAILURE,


} from "./actionTypes";

export const getAllSettings = () => {
  return {
    type: GET_ALL_SETTINGS,
  };
}
export const getAllSettingsSuccess = (payload) => {
  return {
    type: GET_ALL_SETTINGS_SUCCESS,
    payload: payload,
  };
}

export const getAllSettingsFailure = (error) => {
  return {
    type: GET_ALL_SETTINGS_FAILURE,
    payload: error,
  };
}

export const editMainSettings = (payload) => {
  return {
    type: EDIT_MAIN_SETTINGS,
    payload: payload
  };
}
export const editMainSettingsSuccess = (payload) => {
  return {
    type: EDIT_MAIN_SETTINGS_SUCCESS,
    payload: payload,
  };
}

export const editMainSettingsFailure = (error) => {
  return {
    type: EDIT_MAIN_SETTINGS_FAILURE,
    payload: error,
  };
}

export const editSocialsSettings = (payload) => {
  return {
    type: EDIT_SOCIALS_SETTINGS,
    payload: payload
  };
}
export const editSocialsSettingsSuccess = (payload) => {
  return {
    type: EDIT_SOCIALS_SETTINGS_SUCCESS,
    payload: payload,
  };
}

export const editSocialsSettingsFailure = (error) => {
  return {
    type: EDIT_SOCIALS_SETTINGS_FAILURE,
    payload: error,
  };
}


