import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { editAdmin, getAdmin, getAllRoles } from "store/actions";
import Loader from "components/shared/Loader";
import { Input } from "components/shared/FormComponents";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import Select from "react-select";


const EditAdmin = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { singleAdmin, loading, error } = useSelector((state) => state.admins);
  const { roles } = useSelector((state) => state.roles);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  // effects
  useEffect(() => {
    dispatch(getAdmin(id));
    dispatch(getAllRoles());

  }, [dispatch, id]);

  useEffect(() => {
    if (singleAdmin && Object.keys(singleAdmin).length > 0) {

      reset({
        firstName: singleAdmin?.firstName,
        lastName: singleAdmin?.lastName,
        role: singleAdmin?.role?.id
      });
    }

  }, [reset, singleAdmin]);

  // error 
  useEffect(() => {
    if (error) {
      if (error?.statusCode === 422) {
        error?.errors?.forEach((err) => {
          toastErrorMessage(err.msg, locale);
        })
      } else {
        toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
      }
    }

  }, [error, formatMessage, locale, navigate]);



  const handleSelectOptions = (options) => {

    if (options.length < 0) return []

    return options.map((option) => ({
      value: option?.id,
      label: option?.name
    }
    ));
  }


  const onSubmit = (data) => {

    dispatch(editAdmin({ id, data, navigate }));
  };



  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"editAdmin"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>

              <Col xl={8} md={12}>
                <input type="hidden"  {...register("roleType")} value="admin" />

                <Row>
                  {/* phone  */}
                  <Col lg={6} xs={12}>
                    <div className="form-group disabled-form-group">
                      <h5>
                        <FormattedMessage id={"phone"} />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "phone" })}
                          value={singleAdmin?.phone}
                          disabled
                        />
                      </div>

                    </div>
                  </Col>

                  {/* email  */}
                  <Col lg={6} xs={12}>
                    <div className="form-group disabled-form-group ">
                      <h5>
                        <FormattedMessage id={"email"} />
                      </h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "email" })}
                          disabled
                          value={singleAdmin?.email}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                {/* firstName */}
                <Row>
                  <Col lg={12} xs={12}>
                    <Input langId="firstName" name="firstName" register={register} errors={errors} />
                  </Col>
                </Row>
                {/* lastName */}
                <Row>
                  <Col lg={12} xs={12}>
                    <Input langId="lastName" name="lastName" register={register} errors={errors} />
                  </Col>
                </Row>



                {/* role  */}
                <Col lg={12} xs={12}>
                  <div className="form-group required">
                    <h5><FormattedMessage id={"role"} /> </h5>
                    <div>
                      <Controller
                        control={control}
                        render={({ field: { onChange, value, name, ref, selected } }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            placeholder={<FormattedMessage id={"role"} />}
                            options={handleSelectOptions(roles)}
                            onChange={(selectedOption) => {
                              onChange(selectedOption?.value);
                              setValue(name, selectedOption?.value)
                            }}
                            isRtl={true}
                            isSearchable={false}
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue={{ value: singleAdmin?.role?.id, label: singleAdmin?.role?.name }}
                          />
                        )}
                        name={"role"}
                        rules={{ required: true }}
                      />
                    </div>
                    <p className="error-hint">
                      {errors?.role &&
                        <FormattedMessage id={"requiredField"} />}
                    </p>
                  </div>
                </Col>


                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdmin;
