import { combineReducers } from "redux";

// Authentication
import authentication from "./authentication/reducer";
import categories from "./categories/reducer";
import addressTypes from "./addressTypes/reducer";
import skills from "./skills/reducer";
import banks from "./banks/reducer";
import settings from "./settings/reducer";
import pages from "./pages/reducer";
import users from "./users/reducer";
import roles from "./roles/reducer";
import permissions from "./permissions/reducer";
import transactionRequests from "./transactionRequests/reducer";
import jobs from "./jobs/reducer";
import bankAccounts from "./bankAccounts/reducer";
import contacts from "./contacts/reducer";
import admins from "./admins/reducer";
import requestsReports from "./requestsReports/reducer";
import announcements from "./announcements/reducer";
import ads from "./ads/reducer";
import countries from "./countries/reducer";

const rootReducer = combineReducers({
  authentication,
  categories,
  addressTypes,
  skills,
  banks,
  pages,
  settings,
  users,
  roles,
  permissions,
  transactionRequests,
  jobs,
  bankAccounts,
  contacts,
  admins,
  requestsReports,
  announcements,
  ads,
  countries,
});

export default rootReducer;
