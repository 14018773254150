import {
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_FAILURE,
  ADD_BANK_ACCOUNT_SUCCESS,

  DELETE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT_FAILURE,
  DELETE_BANK_ACCOUNT_SUCCESS,

  EDIT_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT_FAILURE,
  EDIT_BANK_ACCOUNT_SUCCESS,

  GET_ALL_BANK_ACCOUNTS,
  GET_ALL_BANK_ACCOUNTS_FAILURE,
  GET_ALL_BANK_ACCOUNTS_SUCCESS,

  GET_BANK_ACCOUNT,
  GET_BANK_ACCOUNT_FAILURE,
  GET_BANK_ACCOUNT_SUCCESS
} from "./actionTypes";


export const getAllBankAccounts = (payload) => {
  return {
    type: GET_ALL_BANK_ACCOUNTS,
    payload: payload,
  };
}
export const getAllBankAccountsSuccess = (payload) => {
  return {
    type: GET_ALL_BANK_ACCOUNTS_SUCCESS,
    payload: payload,
  };
}

export const getAllBankAccountsFailure = (error) => {
  return {
    type: GET_ALL_BANK_ACCOUNTS_FAILURE,
    payload: error,
  };
}

export const addBankAccount = (payload) => {
  return {
    type: ADD_BANK_ACCOUNT,
    payload: payload,
  };
}
export const addBankAccountSuccess = (payload) => {
  return {
    type: ADD_BANK_ACCOUNT_SUCCESS,
    payload: payload,
  };
}

export const addBankAccountFailure = (error) => {
  return {
    type: ADD_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
}

export const getBankAccount = (payload) => {
  return {
    type: GET_BANK_ACCOUNT,
    payload: payload,
  };
}
export const getBankAccountSuccess = (payload) => {
  return {
    type: GET_BANK_ACCOUNT_SUCCESS,
    payload: payload,
  };
}

export const getBankAccountFailure = (error) => {
  return {
    type: GET_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
}

export const editBankAccount = (payload) => {
  return {
    type: EDIT_BANK_ACCOUNT,
    payload: payload,
  };
}
export const editBankAccountSuccess = (payload) => {
  return {
    type: EDIT_BANK_ACCOUNT_SUCCESS,
    payload: payload,
  };
}

export const editBankAccountFailure = (error) => {
  return {
    type: EDIT_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
}

export const deleteBankAccount = (payload) => {
  return {
    type: DELETE_BANK_ACCOUNT,
    payload: payload,
  };
}

export const deleteBankAccountSuccess = (payload) => {
  return {
    type: DELETE_BANK_ACCOUNT_SUCCESS,
    payload: payload,
  };
}

export const deleteBankAccountFailure = (error) => {
  return {
    type: DELETE_BANK_ACCOUNT_FAILURE,
    payload: error,
  };
}