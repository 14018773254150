import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_ADDRESS_TYPE,
  DELETE_ADDRESS_TYPE,
  EDIT_ADDRESS_TYPE,
  GET_ALL_ADDRESS_TYPES,
  GET_ADDRESS_TYPE,
} from "./actionTypes";
import {
  addAddressTypeApi,
  getAllAddressTypesApi,
  getAddressTypeApi,
  editAddressTypeApi,
  deleteAddressTypeApi,
} from "api/addressTypes";
import {
  addAddressTypeFailure,
  addAddressTypeSuccess,
  deleteAddressTypeSuccess,
  deleteAddressTypeFailure,
  editAddressTypeSuccess,
  editAddressTypeFailure,
  getAllAddressTypesFailure,
  getAllAddressTypesSuccess,
  getAddressTypeFailure,
  getAddressTypeSuccess,
} from "./actions";

function* getAllAddressTypes() {
  try {
    const { data } = yield call(getAllAddressTypesApi);

    yield put(getAllAddressTypesSuccess(data));
  } catch (error) {
    yield put(getAllAddressTypesFailure(error?.response?.data || error));
  }
}

function* addAddressType({ payload }) {
  try {
    const { data } = yield call(addAddressTypeApi, payload.data);

    yield put(addAddressTypeSuccess(data));
    payload.navigate("/addressTypes");
  } catch (error) {
    yield put(addAddressTypeFailure(error?.response?.data || error));
  }
}

function* getAddressType({ payload }) {
  try {
    const { data } = yield call(getAddressTypeApi, payload);
    yield put(getAddressTypeSuccess(data));
  } catch (error) {
    yield put(getAddressTypeFailure(error?.response?.data || error));
  }
}

function* editAddressType({ payload }) {
  try {
    const { data } = yield call(editAddressTypeApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editAddressTypeSuccess(data));
  } catch (error) {
    yield put(editAddressTypeFailure(error?.response?.data || error));
  }
}

function* deleteAddressType({ payload }) {
  try {
    yield call(deleteAddressTypeApi, payload);
    yield put(deleteAddressTypeSuccess(payload));
  } catch (error) {
    yield put(deleteAddressTypeFailure(error?.response?.data || error));
  }
}

export function* watchGetAllAddressTypes() {
  yield takeEvery(GET_ALL_ADDRESS_TYPES, getAllAddressTypes);
}

export function* watchAddAddressType() {
  yield takeEvery(ADD_ADDRESS_TYPE, addAddressType);
}

export function* watchGetAddressType() {
  yield takeEvery(GET_ADDRESS_TYPE, getAddressType);
}

export function* watchEditAddressType() {
  yield takeEvery(EDIT_ADDRESS_TYPE, editAddressType);
}

export function* watchDeleteAddressType() {
  yield takeEvery(DELETE_ADDRESS_TYPE, deleteAddressType);
}

function* addressTypesSaga() {
  yield all([
    fork(watchGetAllAddressTypes),
    fork(watchAddAddressType),
    fork(watchGetAddressType),
    fork(watchEditAddressType),
    fork(watchDeleteAddressType),
  ]);
}

export default addressTypesSaga;
