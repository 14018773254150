import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import server from "api/server";
import removeImg from "../../assets/svgs/close.svg";
import imageUpload from "../../assets/svgs/imageUpload.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { editAddressType, getAddressType } from "store/actions";
import Loader from "components/shared/Loader";


const EditAddressType = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, error, singleAddressType } = useSelector((state) => state.addressTypes);

  const [selectedImage, setSelectedImage] = useState({ preview: null, path: "" });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  // effects
  useEffect(() => {
    dispatch(getAddressType(id));
  }, [dispatch, id]);

  useEffect(() => {

    reset({
      name: singleAddressType.name,
      "image": singleAddressType.image
    });

    setSelectedImage({
      preview: null,
      path: singleAddressType.image,
    });


  }, [reset, singleAddressType]);

  // error 
  useEffect(() => {

    if (error) {
      let errorMessage = formatMessage({ id: "sendingErrorMessage" });

      if (error?.statusCode === 422) {
        errorMessage = formatMessage({ id: "validationAddAddressTypeErrorMessage" });
      }

      toast.error(errorMessage, {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error, formatMessage, locale]);

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);

      setSelectedImage({
        preview: e.target.files[0],
        path: data.data.files[0].path,
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage("");
  };

  const onSubmit = (data) => {

    if (!selectedImage?.path) {
      toast.error(formatMessage({ id: "imageErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }

    data.image = `uploads/${selectedImage?.path?.split("/").at(-1)}`;


    dispatch(editAddressType({ id, data, navigate }));
  };


  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"editAddressType"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  {/* name  */}
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"name"} /> ( <FormattedMessage id={"arabic-language"} /> )
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "name" })}
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.ar?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"name"} />  ( <FormattedMessage id={"english-language"} /> )
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "name" })}
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.en?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>


                  {/* image  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"image"} /></h5>

                      <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img src={imageUpload} width={46} height={46} alt="" />
                        </div>
                        <label>
                          {(selectedImage?.preview || selectedImage?.path) && (
                            <div>
                              <img
                                src={
                                  selectedImage && selectedImage.preview
                                    ? URL.createObjectURL(selectedImage.preview)
                                    : selectedImage?.path ? selectedImage?.path : imageUpload
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button className="removeImg" onClick={removeSelectedImage}>
                                <img src={removeImg} alt="" />
                              </button>
                            </div>
                          )}
                          <input accept="image/*" type="file" onChange={imageChange} />
                        </label>
                      </div>
                    </div>

                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"saveChanges"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAddressType;
