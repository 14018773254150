import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";


import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { editPage, getPage } from "store/actions";
import Loader from "components/shared/Loader";
import ContentArray from "./ContentArray";
import { v4 } from "uuid";


const EditPage = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, singlePage } = useSelector((state) => state.pages);
  const [sections, setSections] = useState([]);
  const { id } = useParams();
  const { register, reset, handleSubmit, formState: { errors }, } = useForm();


  // get single page by slug 
  useEffect(() => {
    dispatch(getPage(id));
  }, [dispatch, id]);

  useEffect(() => {

    reset({
      title: singlePage?.title?.split("-")?.join(" "),

    });


    if (singlePage?.content) {
      const updatedSections = [];

      Object.entries(singlePage?.content).forEach(([key, section]) => {
        section.id = v4();
        updatedSections.push(section)
      });

      setSections(updatedSections)
    }

  }, [reset, singlePage]);
  // error 
  useEffect(() => {

    if (error) {
      let errorMessage = formatMessage({ id: "sendingErrorMessage" });

      if (error?.statusCode === 422) {
        errorMessage = formatMessage({ id: "validationEditPageErrorMessage" });
      }

      toast.error(errorMessage, {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error, formatMessage, locale]);


  const onSubmit = (data) => {
    const content = {}
    sections.forEach((section, index) => {

      content[`section${index + 1}`] = {
        title: section.title ? section.title : "",
        description: section.description ? section.description : "",
        image: section.image ? section.image : ""
      }
    })
    data.content = content;

    dispatch(editPage({ id, data, navigate }));
  };


  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"editPage"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  {/* title  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      {/* <h5>
                        <FormattedMessage id={"title"} />
                      </h5> */}
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline page_title__"
                          placeholder={formatMessage({ id: "title" })}
                          {...register("title", { required: true })}
                          disabled
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.title?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>


                  {/* content - sections */}
                  <Col lg={12}>
                    <ContentArray sections={sections} setSections={setSections} />
                  </Col>


                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"saveChanges"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPage;
