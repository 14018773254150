
import BootyPagination from 'components/shared/Pagination';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getAllJobs } from 'store/actions';
import Table from './Table';

const Jobs = () => {

  const { jobs, loading } = useSelector((state) => state.jobs);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getAllJobs(searchParams));
  }, [dispatch, searchParams]);

  return (
    <>
      <div className="jobs-wrap">
        <Table data={jobs?.jobs} loading={loading} />
        <BootyPagination metaData={jobs?.metadata} />
      </div>
    </>
  )
}

export default Jobs

