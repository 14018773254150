import React, { useMemo, useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown, Modal } from "react-bootstrap";
import Filter from "components/shared/Filter";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { deleteUser, getAllUsers } from "store/actions";
import { checkPermissions } from "helpers/authentication/checkPermissions";
import { useForm } from "react-hook-form";
import server from "api/server";
import useSearchFormParam from "components/shared/SearchHook";
// import { deleteUser } from "store/actions";

const Table = (props) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [showModal, setShowModal] = useState(false);

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [registerSearch, handleSubmitSearch, params, EmptyButton] =
    useSearchFormParam();

  const cleanTxt = (str) =>
    `${str}`?.replaceAll(" ", "")?.replaceAll(",", "")?.toLowerCase();

  // const filteredItems = props?.data?.filter(
  //   (item) =>
  //     cleanTxt(JSON.stringify(Object.values(item).join())).indexOf(
  //       cleanTxt(filterText)
  //     ) !== -1
  // );

  const subHeaderComponent = useMemo(() => {
    // return (
    //   <Filter
    //     onFilter={(e) => setFilterText(e.target.value)}
    //     onClear={handleClear}
    //     filterText={filterText}
    //   />
    // );
    return (
      <div className="filter-wrp filter-toolbar__search ">
        <form className="form-group" onSubmit={handleSubmitSearch}>
          <input
            id="search"
            type="text"
            placeholder={formatMessage({ id: "search" })}
            className="form-control"
            {...registerSearch("txt")}
          />
          <button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#fff"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </button>
          <EmptyButton />
        </form>
      </div>
    );
  }, [formatMessage, handleSubmitSearch, registerSearch]);

  // const handleEditUser = (id) => {
  //   navigate(`/users/${id}`);
  // };

  const handleDeleteUser = (id) => {
    dispatch(deleteUser(id));
  };
  const handleSendPenalty = (id) => {
    setShowModal(true);
    setUserId(id);
  };
  const handleShowUser = (id) => {
    navigate(`/users/show/${id}`);
  };

  const columns = [
    {
      name: <FormattedMessage id={"image"} />,
      selector: (row) => <img className="store-logo" src={row?.image} alt="" />,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"userName"} />,
      selector: (row) =>
        checkPermissions(["users-show"]) ? (
          <Link
            to={`/users/show/${row?.id}`}
          >{`${row?.firstName} ${row?.lastName}`}</Link>
        ) : (
          `${row?.firstName} ${row?.lastName}`
        ),

      sortable: true,
    },
    // {
    //   name: <FormattedMessage id={"role"} />,
    //   selector: (row) => row?.role ? row?.role?.name : "user",
    //   sortable: true,
    // },

    {
      name: <FormattedMessage id={"email"} />,
      selector: (row) => row?.email,
      sortable: true,
    },

    {
      cell: (row) => (
        <>
          <div className="global-dropdown">
            <Dropdown>
              <Dropdown.Toggle>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="evenOdd"
                  className="bi bi-three-dots-vertical"
                  viewBox="0 0 16 16"
                >
                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {checkPermissions(["users-show"]) && (
                  <Dropdown.Item onClick={(e) => handleShowUser(row.id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="#fff"
                      className="bi bi-eye show"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                    </svg>
                    <FormattedMessage id={"show"} />
                  </Dropdown.Item>
                )}

                {/* {checkPermissions(["users-update"]) && <Dropdown.Item onClick={(e) => handleEditUser(row.id)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="#fff"
                    className="bi bi-pencil edit"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg>
                  <FormattedMessage id={"edit"} />
                </Dropdown.Item>} */}

                {checkPermissions(["users-delete"]) && (
                  <Dropdown.Item onClick={(e) => handleSendPenalty(row.id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-exclamation-diamond-fill bg-warning"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                    </svg>
                    <FormattedMessage id={"penalty"} />
                  </Dropdown.Item>
                )}

                {checkPermissions(["users-delete"]) && (
                  <Dropdown.Item onClick={(e) => handleDeleteUser(row.id)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="#fff"
                      className="bi bi-trash delete"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                      <path
                        fillRule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                      />
                    </svg>
                    <FormattedMessage id={"delete"} />
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const onSubmit = async (data) => {
    try {
      data.user = userId;
      setLoading(true);

      await server().post("/auth/addPenalty", data);
      reset();
      setShowModal(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id={"usersTableShow"} />{" "}
                </h4>
                <p>
                  {" "}
                  <FormattedMessage id={"usersTableShowAll"} />
                </p>
              </div>
            </div>
          </Col>
          <Modal show={showModal} onHide={() => setShowModal(!showModal)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id={"penaltyUser"} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="d-flex flex-column gap-3"
              >
                <input
                  className="form-control"
                  type="number"
                  {...register("penaltyValue")}
                  placeholder={formatMessage({ id: "penaltyValue" })}
                />
                <textarea
                  className="form-control"
                  type="text"
                  {...register("reason")}
                  placeholder={formatMessage({ id: "reason" })}
                />
                <button className="btn btn-primary" disabled={loading}>
                  <FormattedMessage id="send" />
                </button>
              </form>
            </Modal.Body>
          </Modal>
          <div className="card-body">
            <DataTable
              columns={columns}
              // data={filteredItems}
              data={props?.data || []}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              selectableRows
              subHeader
              selectableRowsComponent={BootyCheckbox}
              subHeaderComponent={subHeaderComponent}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
