import ControlArea from 'components/Layout/ControlArea';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAdmins } from 'store/actions';
import Icon from "assets/svgs/admins.svg";
import Table from './Table';
import { checkPermissions } from 'helpers/authentication/checkPermissions';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';

const Admins = () => {

  const { admins, loading, error } = useSelector((state) => state.admins);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false)


  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllAdmins());
  }, [dispatch]);

  // error 
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }

  }, [error, formatMessage, locale, isDispatched]);

  return (
    <>
      <div className="admins-wrap">
        {checkPermissions(["admins-store"]) && <ControlArea
          btnTxt={<FormattedMessage id={"addAdmin"} />}
          cardTxt={<FormattedMessage id={"admins"} />}
          icon={Icon}
          url="/admins/add"
        />}

        <Table data={admins} loading={loading} />
      </div>

    </>
  )
}

export default Admins

