import { getId } from "helpers/functions";
import {
  DELETE_JOB,
  DELETE_JOB_FAILURE,
  DELETE_JOB_SUCCESS,
  GET_ALL_EMPLOYEE_JOBS,
  GET_ALL_EMPLOYEE_JOBS_FAILURE,
  GET_ALL_EMPLOYEE_JOBS_SUCCESS,
  GET_ALL_JOBS,
  GET_ALL_JOBS_FAILURE,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_PROVIDER_JOBS,
  GET_ALL_PROVIDER_JOBS_FAILURE,
  GET_ALL_PROVIDER_JOBS_SUCCESS,
  GET_JOB,
  GET_JOB_FAILURE,
  GET_JOB_SUCCESS,
} from "./actionTypes";

const initialState = {
  jobs: {},
  loading: false,
  error: "",
  singleJob: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all jobs
    case GET_ALL_JOBS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_JOBS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        jobs: action.payload,
      };
      break;

    case GET_ALL_JOBS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get  job
    case GET_JOB:
      state = {
        ...state,
        error: "",
        loading: true,
        singleJob: {},
      };
      break;

    case GET_JOB_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleJob: action.payload.job,
      };
      break;

    case GET_JOB_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // delete  job
    case DELETE_JOB:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case DELETE_JOB_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        jobs: {
          ...state?.jobs,
          jobs: state?.jobs?.jobs?.filter(
            (item) => getId(item) !== action.payload
          ),
        },
      };
      break;

    case DELETE_JOB_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get all jobs
    case GET_ALL_EMPLOYEE_JOBS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_EMPLOYEE_JOBS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        jobs: action.payload,
      };
      break;

    case GET_ALL_EMPLOYEE_JOBS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get all jobs
    case GET_ALL_PROVIDER_JOBS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_PROVIDER_JOBS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        jobs: action.payload,
      };
      break;

    case GET_ALL_PROVIDER_JOBS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
