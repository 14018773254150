import server from "./server";

export const getAllBankAccountsApi = async () => {
  const response = await server().get("/bankAccounts");

  return response.data;
}

export const addBankAccountApi = async (data) => {
  const response = await server().post("/bankAccounts", data);

  return response.data;
}


export const getBankAccountApi = async (id) => {

  const response = await server().get(`/bankAccounts/${id}`);


  return response.data;
}

export const editBankAccountApi = async ({ id, data }) => {
  const response = await server().put(`/bankAccounts/${id}`, data);

  return response.data;
}

export const deleteBankAccountApi = async (id) => {
  const response = await server().delete(`/bankAccounts/${id}`);
  return response.data;
}