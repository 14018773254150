import {
  GET_ALL_ADMINS,
  GET_ALL_ADMINS_FAILURE,
  GET_ALL_ADMINS_SUCCESS,

  ADD_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILURE,
  GET_ADMIN,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILURE,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  EDIT_ADMIN,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILURE

} from "./actionTypes";



const initialState = {
  admins: [],
  loading: false,
  error: "",
  singleAdmin: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all admins  
    case GET_ALL_ADMINS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_ADMINS_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        admins: action.payload.admins,
      };
      break;

    case GET_ALL_ADMINS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_ADMIN:
      state = {
        ...state,
        error: "",
        loading: true,
      }
      break;

    case ADD_ADMIN_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleAdmin: action.payload.admin,
      }
      break;

    case ADD_ADMIN_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        singleAdmin: {},
      }
      break;

    case GET_ADMIN:
      state = {
        ...state,
        error: "",
        loading: true,
        singleAdmin: {}
      }
      break;

    case GET_ADMIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleAdmin: action.payload.admin
      }
      break;

    case GET_ADMIN_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break;

    // edit admin  
    case EDIT_ADMIN:
      state = {
        ...state,
        error: "",
        loading: true,
        singleAdmin: {}
      };
      break;

    case EDIT_ADMIN_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleAdmin: action.payload.admin,
      };
      break;

    case EDIT_ADMIN_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;

    // delete page  
    case DELETE_ADMIN:
      state = {
        ...state,
        error: "",
        loading: true,
        singlePage: {}
      };
      break;

    case DELETE_ADMIN_SUCCESS:


      const adminsAfterDeleteing = [
        ...state?.admins?.filter((admin) => admin?.id !== action.payload),
      ];

      state = {
        ...state,
        error: "",
        loading: false,
        admins: adminsAfterDeleteing,
      };
      break;

    case DELETE_ADMIN_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
