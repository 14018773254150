import ControlArea from "components/Layout/ControlArea";
import { useState } from "react";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncements } from "store/actions";
// import Icon from "assets/svgs/medias.svg";
import Table from "./Table";
import { checkPermissions } from "helpers/authentication/checkPermissions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

const Announcements = () => {
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const { announcements, loading, error } = useSelector(
    (state) => state.announcements
  );
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAnnouncements());
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, isDispatched]);

  return (
    <>
      <div className="announcements-wrap">
        {/* {checkPermissions(["announcements-store"]) && ( */}
        <ControlArea
          btnTxt={<FormattedMessage id={"addAnnouncement"} />}
          cardTxt={<FormattedMessage id={"announcements"} />}
          // icon={Icon}
          url="/announcements/add"
        />
        {/* )} */}

        <Table data={announcements} loading={loading} />
      </div>
    </>
  );
};

export default Announcements;
