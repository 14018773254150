export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GET_ALL_JOBS_SUCCESS = "GET_ALL_JOBS_SUCCESS";
export const GET_ALL_JOBS_FAILURE = "GET_ALL_JOBS_FAILURE";

export const GET_JOB = "GET_JOB";
export const GET_JOB_SUCCESS = "GET_JOB_SUCCESS";
export const GET_JOB_FAILURE = "GET_JOB_FAILURE";

export const DELETE_JOB = "DELETE_JOB";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_FAILURE = "DELETE_JOB_FAILURE";

export const GET_ALL_EMPLOYEE_JOBS = "GET_ALL_EMPLOYEE_JOBS";
export const GET_ALL_EMPLOYEE_JOBS_SUCCESS = "GET_ALL_EMPLOYEE_JOBS_SUCCESS";
export const GET_ALL_EMPLOYEE_JOBS_FAILURE = "GET_ALL_EMPLOYEE_JOBS_FAILURE";

export const GET_ALL_PROVIDER_JOBS = "GET_ALL_PROVIDER_JOBS";
export const GET_ALL_PROVIDER_JOBS_SUCCESS = "GET_ALL_PROVIDER_JOBS_SUCCESS";
export const GET_ALL_PROVIDER_JOBS_FAILURE = "GET_ALL_PROVIDER_JOBS_FAILURE";
