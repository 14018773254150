import React, { Fragment, useEffect, useReducer, useState } from "react";
import Loader from "../../components/shared/Loader";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// import { updateMeta } from "../../store/actions/metaAction";
// import userPlaceholder from "../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server from "../../api/server";
import axios from "axios";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import { Input, QuillEditor, Select } from "components/shared/FormComponents";
import { useNavigate, useParams } from "react-router-dom";
import { getId, handleImage } from "helpers/functions";
import { useIntl } from "react-intl";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const BlogsShow = () => {
  // const auth = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const navigate = useNavigate();
  const { blogID } = useParams();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    resetField,
  } = useForm({
    defaultValues: {
      faqs: [],
      tags: [],
    },
  });

  // initial states
  const [category, setCategory] = useState([]);

  // form Needed states
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const onStart = async () => {
    setLoading(true);
    // dispatch(
    //   updateMeta({
    //     title: "اضافة مدونة جديدة",
    //     breadcrumb: "المدونات",
    //     button: {
    //       show: false,
    //       text: null,
    //       link: null,
    //     },
    //   })
    // );
    await Promise.all([
      await server(cookie).get(`/blogs/${blogID}`),
      await server(cookie).get("/blogCategories"),
    ])
      .then((data) => {
        setValue("category", getId(data[0]?.data?.data?.blog?.category));
        setValue("title", data[0]?.data?.data?.blog?.title);
        setValue("description", data[0]?.data?.data?.blog?.description);

        setValue(
          "image",
          `uploads/${data[0]?.data?.data?.blog?.image?.split("/").pop()}`
        );
        setValue(
          "tags",
          data[0]?.data?.data?.blog?.tags?.map((tag) => ({ value: tag }))
        );
        setValue("faqs", data[0]?.data?.data?.blog?.contents?.faqs);
        setValue("author", data[0]?.data?.data?.blog?.contents?.author);
        setValue("releaseDate", data[0]?.data?.data?.blog?.contents?.date);

        setCategory([
          ...data[1]?.data?.data?.blogCategories?.map((data) => ({
            name: data?.name?.[locale],
            slug: getId(data),
          })),
        ]);
      })
      .catch((error) => toast.error(error));
    setLoading(false);
  };

  // handleMethods

  const handlePicture = async (tempValue) => {
    if (tempValue.length < 1) return;
    setLoading(true);
    const blobUrl = URL?.createObjectURL(tempValue?.[0]);
    const formData = new FormData();
    formData.append(`file`, tempValue?.[0]);
    setLoading(false);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${cookie.get("token")}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (responseImage) {
        setValue(`image`, responseImage?.data?.data?.files?.[0].path);
        setValue(`pictureUrl`, blobUrl);
      })
      .catch(function (error) {
        console.log(error);
        toast.error("خطأ، برجاء المحاولة مره اخرى!");
        setLoading(false);
      });
    forceUpdate();
    setLoading(false);
    toast.success("تم رفع الصور بنجاح!");
  };

  const submitHandler = async (data) => {
    setLoading(true);
    try {
      const { tag, pictureUrl, releaseDate, faqs, author, ...finalData } = {
        ...data,
        name: `${data?.title?.en} blog`,
        tags: data?.tags.map((tag) => tag.value),
        contents: {
          author: data?.author,
          date: data.releaseDate,
          faqs: data.faqs,
        },
      };
      const dataResponse = await server(cookie).put(`/blogs/${blogID}`, {
        ...finalData,
      });
      reset();
      navigate("/blogs/index");

      // onStart();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // watchers
  useEffect(() => {
    onStart();
    register("image", { required: true });
  }, []);

  return (
    <section className="acc-form">
      <div className="card">
        <div className="card-head">
          <h4>تعديل مقالة</h4>
        </div>
        {loading && <Loader />}
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="row">
            <div className="row">
              <div className="col-md-6">
                <div className="card-body">
                  <div className="form-item form-group">
                    <h5 htmlFor="category">اختار الفئة</h5>
                    <Select
                      name="category"
                      id="category"
                      options={[...category]}
                      control={control}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card-body">
                  <div className="form-item form-group">
                    <h5 htmlFor="nameAr">العنوان</h5>
                    <Input
                      type="text"
                      name="title"
                      id="nameAr"
                      register={register}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="card-body">
                  <div className="form-item form-group">
                    <h5 htmlFor="nameEn">اسم بالغة الانجليزية</h5>
                    <Input
                      type="text"
                      name="title.en"
                      id="nameEn"
                      register={register}
                      required={true}
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-md-8">
                <div className="card-body">
                  <div className="form-item form-group">
                    <h5 htmlFor="descriptionAr">الوصف</h5>
                    <QuillEditor control={control} name="description" />
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="card-body">
                  <div className="form-item form-group">
                    <h5 htmlFor="descriptionEn">الوصف بالغة الانجليزية</h5>
                    <textarea
                      className="form-control"
                      rows={5}
                      type="text"
                      name="descriptionEn"
                      id="descriptionEn"
                      {...register(`description.en`, { required: true })}
                    />
                  </div>
                </div>
              </div> */}

              <div className="col-md-8">
                <div className="card-body">
                  <div className="form-item form-group">
                    <h5 htmlFor="picture">البانر</h5>
                    <input
                      className="form-control"
                      type="file"
                      id="picture"
                      {...register("pictureUrl", {
                        onChange: (e) => handlePicture(e.target.files),
                      })}
                    />
                    <img
                      src={handleImage(watch("image"))}
                      alt="avatar"
                      className="imagePreview"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-8">
                    <div className="card-body">
                      <div className="form-item form-group">
                        <h5 htmlFor="tag">العلامات</h5>
                        <input
                          className="form-control"
                          type="text"
                          name="tag"
                          id="tag"
                          {...register(`tag`)}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="card-body">
                      <div className="form-item form-group mt-3">
                        <p
                          className="bt bt-success btn-block secBtnForm"
                          onClick={() => {
                            tags.append({ value: getValues("tag") });
                            resetField("tag");
                          }}
                        >
                          اضافة العلامة
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ height: 50 }} />
                  <div className="row">
                    {tags.fields?.map((field, index) => {
                      return (
                        <Fragment key={v4()}>
                          <div className="col-md-8">
                            <div className="card-body">{field?.value}</div>
                          </div>
                          <div className="col-md-4">
                            <div className="card-body">
                              <div className="form-item form-group mt-3">
                                <div
                                  className="bt bt-danger btn-block secBtnForm"
                                  type="button"
                                  onClick={() => tags.remove(index)}
                                >
                                  احذف تلك العلامة
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                  </div> */}
                </div>
              </div>
              <div className="col-md-12" style={{ height: 50 }} />
              {/* {faqs.fields?.map((field, index) => {
                return (
                  <Fragment key={v4()}>
                    <div className="col-md-8">
                      <div className="card-body">
                        <div className="form-item form-group">
                          <h5 htmlFor="questionAr">السؤال بالغة العربية</h5>
                          <input
                            className="form-control"
                            type="text"
                            name="ar"
                            id="questionAr"
                            {...register(`faqs.${index}.question.ar`, {
                              required: true,
                            })}
                          />
                        </div>
                        <div className="form-item form-group">
                          <h5 htmlFor="questionEn">السؤال بالغة الانجليزية</h5>
                          <input
                            className="form-control"
                            type="text"
                            name="en"
                            id="questionEn"
                            {...register(`faqs.${index}.question.en`, {
                              required: true,
                            })}
                          />
                        </div>
                        <div className="form-item form-group">
                          <h5 htmlFor="featureAr">الاجابة بالغة العربية</h5>
                          <textarea
                            className="form-control"
                            rows={5}
                            type="text"
                            name="ar"
                            id="featureAr"
                            {...register(`faqs.${index}.answer.ar`, {
                              required: true,
                            })}
                          />
                        </div>
                        <div className="form-item form-group">
                          <h5 htmlFor="featureEn">الاجابة بالغة الانجليزية</h5>
                          <textarea
                            className="form-control"
                            rows={5}
                            type="text"
                            name="en"
                            id="featureEn"
                            {...register(`faqs.${index}.answer.en`, {
                              required: true,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-body">
                        <div className="form-item form-group mt-3">
                          <div
                            className="bt bt-danger btn-block secBtnForm"
                            type="button"
                            onClick={() => faqs.remove(index)}
                          >
                            احذف ذلك الوصف
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })} */}
              {/* <div
                className="col-md-8"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                الرجاء اضافة سؤال واحد علي الاقل
              </div>
              <div className="col-md-4">
                <div className="card-body">
                  <div className="form-item form-group mt-3">
                    <div
                      className="bt bt-success btn-block secBtnForm"
                      onClick={() => faqs.append({})}
                    >
                      اضافة سؤال
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-md-12">
                <div className="form-item form-group mt-3">
                  <button className="btn btn-success w-auto">ارسال</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};
export default BlogsShow;
