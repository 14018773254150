import {
  ADD_BANK, ADD_BANK_FAILURE, ADD_BANK_SUCCESS,
  DELETE_BANK, DELETE_BANK_FAILURE, DELETE_BANK_SUCCESS,
  EDIT_BANK, EDIT_BANK_FAILURE, EDIT_BANK_SUCCESS,
  GET_ALL_BANKS, GET_ALL_BANKS_FAILURE, GET_ALL_BANKS_SUCCESS,
  GET_BANK, GET_BANK_FAILURE, GET_BANK_SUCCESS
} from "./actionTypes";



const initialState = {
  banks: {},
  loading: false,
  error: "",
  singleBank: {},

};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all banks  
    case GET_ALL_BANKS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_BANKS_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        banks: action.payload,
      };
      break;

    case GET_ALL_BANKS_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add bank  
    case ADD_BANK:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_BANK_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleBank: action.payload.bank,
      };
      break;

    case ADD_BANK_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;


    // get bank  
    case GET_BANK:
      state = {
        ...state,
        error: "",
        loading: true,
        singleBank: {}
      };
      break;

    case GET_BANK_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleBank: action.payload.bank,
      };
      break;

    case GET_BANK_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit bank  
    case EDIT_BANK:
      state = {
        ...state,
        error: "",
        loading: true,
        singleBank: {}
      };
      break;

    case EDIT_BANK_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleBank: action.payload.bank,
      };
      break;

    case EDIT_BANK_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete bank  
    case DELETE_BANK:
      state = {
        ...state,
        error: "",
        loading: true,
        singleBank: {}
      };
      break;

    case DELETE_BANK_SUCCESS:
      const banksAfterDeleteing = [
        ...state?.banks?.banks?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        banks: {
          metadata: { ...state.banks.metadata },
          banks: banksAfterDeleteing
        },
      };
      break;

    case DELETE_BANK_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;



    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
