import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { addRole, getAllPermissions } from "store/actions";
import Loader from "components/shared/Loader";



const AddRole = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.roles);
  const { permissions } = useSelector((state) => state.permissions);


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // effects
  useEffect(() => {
    dispatch(getAllPermissions());
  }, [dispatch]);


  // error 
  useEffect(() => {

    if (error) {
      let errorMessage = formatMessage({ id: "sendingErrorMessage" });

      if (error?.statusCode === 422) {
        errorMessage = formatMessage({ id: "validationAddRoleErrorMessage" });
      }

      toast.error(errorMessage, {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/roles");

    }

  }, [error, formatMessage, locale, navigate]);


  const onSubmit = (data) => {
    dispatch(addRole({ data, navigate }));
  };


  const renderPermissions = () => {

    return permissions?.map((permission) => {
      return <Col lg="4" key={permission?.id}>
        <div className="form-group">
          <label htmlFor={permission?.id} className="checkbox-label">
            <input
              id={permission?.id}
              type="checkbox"
              value={permission?.slug}
              {...register("permissions")}
            />
            <span className="text__"> {permission?.name}</span>
          </label>
        </div>
      </Col>;
    })

  }

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"addRole"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  {/* name  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"name"} />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "name" })}
                          {...register("name", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5><FormattedMessage id={"permissions"} /> </h5>
                      <Row>
                        {renderPermissions()}
                      </Row>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"add"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRole;
