import React, { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";

import Filter from "components/shared/Filter";
import Loader from "components/shared/Loader";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { checkPermissions } from "helpers/authentication/checkPermissions";

const Table = (props) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: <FormattedMessage id={"userName"} />,
      selector: (row) => row?.userName,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"userName"} />,

      selector: (row) =>
        checkPermissions(["users-show"]) ? (
          <Link
            to={`/users/show/${row?.user?.id}`}
          >{`${row?.user?.firstName} ${row?.user?.lastName}`}</Link>
        ) : (
          `${row?.user?.firstName} ${row?.user?.lastName}`
        ),

      sortable: true,
    },
    {
      name: <FormattedMessage id={"bankNumber"} />,
      selector: (row) => row?.bankNumber,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"iban"} />,
      selector: (row) => row?.iban,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"swift"} />,
      selector: (row) => row?.swift,
      sortable: true,
    },
  ];
  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id={"BankAccountsTableShow"} />{" "}
                </h4>
                <p>
                  {" "}
                  <FormattedMessage id={"BankAccountsTableShowAll"} />
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              // selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              // selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
