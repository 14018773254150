import server from "./server";

export const getAnnouncementsApi = async () => {
  const response = await server().get(`/announcements/`);

  return response.data;
};

export const addAnnouncementApi = async (data) => {
  const response = await server().post(`/announcements/`, data);

  return response.data;
};

export const getSingleAnnouncementApi = async (id) => {
  const response = await server().get(`/announcements/${id}`);

  return response.data;
};

export const editAnnouncementApi = async ({ id, data }) => {
  const response = await server().put(`/announcements/${id}`, data);

  return response.data;
};

export const deleteAnnouncementApi = async (id) => {
  const response = await server().delete(`/announcements/${id}`);

  return response.data;
};
