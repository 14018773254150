import {
  ADD_SKILL,
  ADD_SKILL_FAILURE,
  ADD_SKILL_SUCCESS,

  DELETE_SKILL,
  DELETE_SKILL_FAILURE,
  DELETE_SKILL_SUCCESS,

  EDIT_SKILL,
  EDIT_SKILL_FAILURE,
  EDIT_SKILL_SUCCESS,

  GET_ALL_SKILLS,
  GET_ALL_SKILLS_FAILURE,
  GET_ALL_SKILLS_SUCCESS,

  GET_SKILL,
  GET_SKILL_FAILURE,
  GET_SKILL_SUCCESS
} from "./actionTypes";


export const getAllSkills = (payload) => {
  return {
    type: GET_ALL_SKILLS,
    payload: payload,
  };
}
export const getAllSkillsSuccess = (payload) => {
  return {
    type: GET_ALL_SKILLS_SUCCESS,
    payload: payload,
  };
}

export const getAllSkillsFailure = (error) => {
  return {
    type: GET_ALL_SKILLS_FAILURE,
    payload: error,
  };
}

export const addSkill = (payload) => {
  return {
    type: ADD_SKILL,
    payload: payload,
  };
}
export const addSkillSuccess = (payload) => {
  return {
    type: ADD_SKILL_SUCCESS,
    payload: payload,
  };
}

export const addSkillFailure = (error) => {
  return {
    type: ADD_SKILL_FAILURE,
    payload: error,
  };
}

export const getSkill = (payload) => {
  return {
    type: GET_SKILL,
    payload: payload,
  };
}
export const getSkillSuccess = (payload) => {
  return {
    type: GET_SKILL_SUCCESS,
    payload: payload,
  };
}

export const getSkillFailure = (error) => {
  return {
    type: GET_SKILL_FAILURE,
    payload: error,
  };
}

export const editSkill = (payload) => {
  return {
    type: EDIT_SKILL,
    payload: payload,
  };
}
export const editSkillSuccess = (payload) => {
  return {
    type: EDIT_SKILL_SUCCESS,
    payload: payload,
  };
}

export const editSkillFailure = (error) => {
  return {
    type: EDIT_SKILL_FAILURE,
    payload: error,
  };
}

export const deleteSkill = (payload) => {
  return {
    type: DELETE_SKILL,
    payload: payload,
  };
}

export const deleteSkillSuccess = (payload) => {
  return {
    type: DELETE_SKILL_SUCCESS,
    payload: payload,
  };
}

export const deleteSkillFailure = (error) => {
  return {
    type: DELETE_SKILL_FAILURE,
    payload: error,
  };
}