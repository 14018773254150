export const GET_ALL_ADMINS = "GET_ALL_ADMINS";
export const GET_ALL_ADMINS_SUCCESS = "GET_ALL_ADMINS_SUCCESS";
export const GET_ALL_ADMINS_FAILURE = "GET_ALL_ADMINS_FAILURE";

export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAILURE = "ADD_ADMIN_FAILURE";

export const GET_ADMIN = "GET_ADMIN";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";


export const EDIT_ADMIN = "EDIT_ADMIN";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_FAILURE = "EDIT_ADMIN_FAILURE";

export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";