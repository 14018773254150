export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_SINGLE_COUNTRY = "GET_SINGLE_COUNTRY";
export const GET_SINGLE_COUNTRY_SUCCESS = "GET_SINGLE_COUNTRY_SUCCESS";
export const GET_SINGLE_COUNTRY_FAILURE = "GET_SINGLE_COUNTRY_FAILURE";

export const ADD_COUNTRY = "ADD_COUNTRY";
export const ADD_COUNTRY_SUCCESS = "ADD_COUNTRY_SUCCESS";
export const ADD_COUNTRY_FAILURE = "ADD_COUNTRY_FAILURE";

export const TOGGLE_COUNTRY = "TOGGLE_COUNTRY";
export const TOGGLE_COUNTRY_SUCCESS = "TOGGLE_COUNTRY_SUCCESS";
export const TOGGLE_COUNTRY_FAILURE = "TOGGLE_COUNTRY_FAILURE";

export const EDIT_COUNTRY = "EDIT_COUNTRY";
export const EDIT_COUNTRY_SUCCESS = "EDIT_COUNTRY_SUCCESS";
export const EDIT_COUNTRY_FAILURE = "EDIT_COUNTRY_FAILURE";

export const DELETE_COUNTRY = "DELETE_COUNTRY";
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS";
export const DELETE_COUNTRY_FAILURE = "DELETE_COUNTRY_FAILURE";
