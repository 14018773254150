export const GET_ALL_PAGES = "GET_ALL_PAGES";
export const GET_ALL_PAGES_SUCCESS = "GET_ALL_PAGES_SUCCESS";
export const GET_ALL_PAGES_FAILURE = "GET_ALL_PAGES_FAILURE";

export const GET_PAGE = "GET_PAGE";
export const GET_PAGE_SUCCESS = "GET_PAGE_SUCCESS";
export const GET_PAGE_FAILURE = "GET_PAGE_FAILURE";

export const ADD_PAGE = "ADD_PAGE";
export const ADD_PAGE_SUCCESS = "ADD_PAGE_SUCCESS";
export const ADD_PAGE_FAILURE = "ADD_PAGE_FAILURE";

export const EDIT_PAGE = "EDIT_PAGE";
export const EDIT_PAGE_SUCCESS = "EDIT_PAGE_SUCCESS";
export const EDIT_PAGE_FAILURE = "EDIT_PAGE_FAILURE";

export const DELETE_PAGE = "DELETE_PAGE";
export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS";
export const DELETE_PAGE_FAILURE = "DELETE_PAGE_FAILURE";