import { getId } from "helpers/functions";
import {
  DELETE_REQUEST,
  DELETE_REQUEST_FAILURE,
  DELETE_REQUEST_SUCCESS,
  GET_ALL_REQUESTS,
  GET_ALL_REQUESTS_FAILURE,
  GET_ALL_REQUESTS_SUCCESS,
  GET_ALL_USER_REQUESTS,
  GET_ALL_USER_REQUESTS_FAILURE,
  GET_ALL_USER_REQUESTS_SUCCESS,
  GET_REQUEST,
  GET_REQUEST_FAILURE,
  GET_REQUEST_SUCCESS,
  APPROVE_REQUEST,
  APPROVE_REQUEST_FAILURE,
  APPROVE_REQUEST_SUCCESS,
  REJECT_REQUEST,
  REJECT_REQUEST_SUCCESS,
  REJECT_REQUEST_FAILURE,
  APPROVE_ALL_REQUESTS,
  APPROVE_ALL_REQUESTS_SUCCESS,
  APPROVE_ALL_REQUESTS_FAILURE,
} from "./actionTypes";

const initialState = {
  transactionRequests: {},
  loading: false,
  error: "",
  singleTransactionRequest: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all transactionRequests
    case GET_ALL_REQUESTS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_REQUESTS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        transactionRequests: action.payload,
      };
      break;

    case GET_ALL_REQUESTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get request
    case GET_REQUEST:
      state = {
        ...state,
        error: "",
        loading: true,
        singleTransactionRequest: {},
      };
      break;

    case GET_REQUEST_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleTransactionRequest: action.payload.request,
      };
      break;

    case GET_REQUEST_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete request
    case DELETE_REQUEST:
      state = {
        ...state,
        error: "",
        loading: true,
        singleTransactionRequest: {},
      };
      break;

    case DELETE_REQUEST_SUCCESS:
      const transactionRequestsAfterDeleteing = [
        ...state?.transactionRequests?.filter(
          (type) => type?.id !== action.payload
        ),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        transactionRequests: transactionRequestsAfterDeleteing,
      };
      break;

    case DELETE_REQUEST_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case APPROVE_REQUEST:
      state = {
        ...state,
        error: "",
        loading: true,
        singleTransactionRequest: {},
      };
      break;

    case APPROVE_REQUEST_SUCCESS:
      const request = action?.payload?.request,
        newReqs = {
          ...state?.transactionRequests,
          requests: state?.transactionRequests?.requests?.map((e) =>
            getId(e) === getId(request) ? request : e
          ),
        };

      state = {
        ...state,
        error: "",
        loading: false,
        singleTransactionRequest: action.payload.request,
        transactionRequests: newReqs,
      };
      break;

    case APPROVE_REQUEST_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // --------------------------------
    case APPROVE_ALL_REQUESTS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case APPROVE_ALL_REQUESTS_SUCCESS:
      var transactionRequests = state.transactionRequests;
      transactionRequests.requests = state.transactionRequests?.requests?.map(
        (e) => {
          let item = action.payload?.transactionRequests?.find(
            (t) => getId(t) === getId(e)
          );
          if (item) return item;
          return e;
        }
      );
      state = {
        ...state,
        error: "",
        loading: false,
        transactionRequests,
      };
      break;

    case APPROVE_ALL_REQUESTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // --------------------------------
    case REJECT_REQUEST:
      state = {
        ...state,
        error: "",
        loading: true,
        singleTransactionRequest: {},
      };
      break;

    case REJECT_REQUEST_SUCCESS:
      const request1 = action?.payload?.request,
        newReqs1 = {
          ...state?.transactionRequests,
          requests: state?.transactionRequests?.requests?.map((e) =>
            getId(e) === getId(request1) ? request1 : e
          ),
        };

      state = {
        ...state,
        error: "",
        loading: false,
        singleTransactionRequest: action.payload.request,
        transactionRequests: newReqs1,
      };
      break;

    case REJECT_REQUEST_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get all user transactionRequests
    case GET_ALL_USER_REQUESTS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_USER_REQUESTS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        transactionRequests: action.payload,
      };
      break;

    case GET_ALL_USER_REQUESTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
