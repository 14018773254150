import {
  GET_ADS,
  GET_ADS_FAILURE,
  GET_ADS_SUCCESS,
  EDIT_AD,
  EDIT_AD_FAILURE,
  EDIT_AD_SUCCESS,
  DELETE_AD,
  DELETE_AD_SUCCESS,
  DELETE_AD_FAILURE,
  ADD_AD,
  ADD_AD_SUCCESS,
  ADD_AD_FAILURE,
  GET_SINGLE_AD,
  GET_SINGLE_AD_SUCCESS,
  GET_SINGLE_AD_FAILURE,
} from "./actionTypes";

export const getAds = (payload) => {
  return {
    type: GET_ADS,
    payload: payload,
  };
};

export const getAdsSuccess = (payload) => {
  return {
    type: GET_ADS_SUCCESS,
    payload: payload,
  };
};

export const getAdsFailure = (error) => {
  return {
    type: GET_ADS_FAILURE,
    payload: error,
  };
};

// ----------------------------

export const getSingleAd = (payload) => {
  return {
    type: GET_SINGLE_AD,
    payload: payload,
  };
};

export const getSingleAdSuccess = (payload) => {
  return {
    type: GET_SINGLE_AD_SUCCESS,
    payload: payload,
  };
};

export const getSingleAdFailure = (error) => {
  return {
    type: GET_SINGLE_AD_FAILURE,
    payload: error,
  };
};

// ----------------------------

export const addAd = (payload) => {
  return {
    type: ADD_AD,
    payload: payload,
  };
};

export const addAdSuccess = (payload) => {
  return {
    type: ADD_AD_SUCCESS,
    payload: payload,
  };
};

export const addAdFailure = (error) => {
  return {
    type: ADD_AD_FAILURE,
    payload: error,
  };
};
// ----------------------------

export const editAd = (payload) => {
  return {
    type: EDIT_AD,
    payload: payload,
  };
};

export const editAdSuccess = (payload) => {
  return {
    type: EDIT_AD_SUCCESS,
    payload: payload,
  };
};

export const editAdFailure = (error) => {
  return {
    type: EDIT_AD_FAILURE,
    payload: error,
  };
};

// ----------------------------

export const deleteAd = (payload) => {
  return {
    type: DELETE_AD,
    payload: payload,
  };
};

export const deleteAdSuccess = (payload) => {
  return {
    type: DELETE_AD_SUCCESS,
    payload: payload,
  };
};

export const deleteAdFailure = (error) => {
  return {
    type: DELETE_AD_FAILURE,
    payload: error,
  };
};
