import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  DELETE_REQUEST,
  GET_ALL_REQUESTS,
  GET_REQUEST,
  APPROVE_REQUEST,
  REJECT_REQUEST,
  GET_ALL_USER_REQUESTS,
  APPROVE_ALL_REQUESTS,
} from "./actionTypes";
import {
  getAllTransactionRequestsApi,
  getTransactionRequestApi,
  deleteTransactionRequestApi,
  approveTransactionRequestApi,
  rejectTransactionRequestApi,
  getAllUserTransactionRequestsApi,
  approveAllTransactionsRequestApi,
} from "api/transactionRequests";
import {
  approveAllTransactionsRequestFailure,
  approveAllTransactionsRequestSuccess,
  approveTransactionRequestFailure,
  approveTransactionRequestSuccess,
  deleteTransactionRequestFailure,
  deleteTransactionRequestSuccess,
  getAllTransactionRequestsFailure,
  getAllTransactionRequestsSuccess,
  getAllUserTransactionRequestsFailure,
  getAllUserTransactionRequestsSuccess,
  getTransactionRequestFailure,
  getTransactionRequestSuccess,
  rejectTransactionRequestFailure,
  rejectTransactionRequestSuccess,
} from "./actions";
import { toast } from "react-toastify";

function* getAllTransactionRequests({ payload }) {
  try {
    const { data } = yield call(getAllTransactionRequestsApi, payload);
    yield put(getAllTransactionRequestsSuccess(data));
  } catch (error) {
    yield put(getAllTransactionRequestsFailure(error?.response?.data || error));
  }
}

function* getTransactionRequest({ payload }) {
  try {
    const { data } = yield call(getTransactionRequestApi, payload);
    yield put(getTransactionRequestSuccess(data));
  } catch (error) {
    yield put(getTransactionRequestFailure(error?.response?.data || error));
  }
}

function* deleteTransactionRequest({ payload }) {
  try {
    yield call(deleteTransactionRequestApi, payload);
    yield put(deleteTransactionRequestSuccess(payload));
  } catch (error) {
    yield put(deleteTransactionRequestFailure(error?.response?.data || error));
  }
}

function* approveTransactionRequest({ payload }) {
  try {
    const { data } = yield call(approveTransactionRequestApi, payload);
    yield put(approveTransactionRequestSuccess(data));
  } catch (error) {
    yield put(approveTransactionRequestFailure(error?.response?.data || error));
    yield payload.toast.error(
      error.response?.data?.errors.msg?.[payload?.locale] || error,
      {
        position: payload.locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );
  }
}

function* approveAllTransactionsRequest({ payload }) {
  try {
    const { data } = yield call(approveAllTransactionsRequestApi, payload);
    yield put(approveAllTransactionsRequestSuccess(data));
    if (payload?.formatMessage)
      toast.success(
        payload?.formatMessage({ id: "approvedDoneOn" }) +
          data?.transactionRequests?.length,
        {
          theme: "colored",
        }
      );
  } catch (error) {
    console.log("error", error);
    yield put(
      approveAllTransactionsRequestFailure(error?.response?.data || error)
    );
    if (payload.toast)
      yield payload.toast.error(
        error.response?.data?.errors.msg?.[payload?.locale] || error,
        {
          position: payload.locale === "ar" ? "bottom-left" : "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
  }
}

function* rejectTransactionRequest({ payload }) {
  try {
    const { data } = yield call(rejectTransactionRequestApi, payload);
    yield put(rejectTransactionRequestSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(rejectTransactionRequestFailure(error?.response?.data || error));
  }
}

function* getAllUserTransactionRequests({ payload }) {
  try {
    const { data } = yield call(getAllUserTransactionRequestsApi, payload.id);
    yield put(getAllUserTransactionRequestsSuccess(data));
  } catch (error) {
    yield put(
      getAllUserTransactionRequestsFailure(error?.response?.data || error)
    );
  }
}

export function* watchGetAllTransactionRequests() {
  yield takeEvery(GET_ALL_REQUESTS, getAllTransactionRequests);
}

export function* watchGetTransactionRequest() {
  yield takeEvery(GET_REQUEST, getTransactionRequest);
}

export function* watchDeleteTransactionRequest() {
  yield takeEvery(DELETE_REQUEST, deleteTransactionRequest);
}

export function* watchApproveTransactionRequest() {
  yield takeEvery(APPROVE_REQUEST, approveTransactionRequest);
}

export function* watchApproveAllTransactionsRequest() {
  yield takeEvery(APPROVE_ALL_REQUESTS, approveAllTransactionsRequest);
}

export function* watchRejectTransactionRequest() {
  yield takeEvery(REJECT_REQUEST, rejectTransactionRequest);
}
export function* watchGetAllUserTransactionRequests() {
  yield takeEvery(GET_ALL_USER_REQUESTS, getAllUserTransactionRequests);
}

function* transactionRequestsSaga() {
  yield all([
    fork(watchGetAllTransactionRequests),
    fork(watchGetTransactionRequest),
    fork(watchDeleteTransactionRequest),
    fork(watchApproveTransactionRequest),
    fork(watchApproveAllTransactionsRequest),
    fork(watchRejectTransactionRequest),
    fork(watchGetAllUserTransactionRequests),
  ]);
}

export default transactionRequestsSaga;
