import {
  ADD_BANK,
  ADD_BANK_FAILURE,
  ADD_BANK_SUCCESS,

  DELETE_BANK,

  DELETE_BANK_FAILURE,

  DELETE_BANK_SUCCESS,

  EDIT_BANK,

  EDIT_BANK_FAILURE,

  EDIT_BANK_SUCCESS,

  GET_ALL_BANKS,
  GET_ALL_BANKS_FAILURE,
  GET_ALL_BANKS_SUCCESS,

  GET_BANK,
  GET_BANK_FAILURE,
  GET_BANK_SUCCESS
} from "./actionTypes";


export const getAllBanks = (payload) => {
  return {
    type: GET_ALL_BANKS,
    payload: payload,
  };
}
export const getAllBanksSuccess = (payload) => {

  return {
    type: GET_ALL_BANKS_SUCCESS,
    payload: payload,
  };
}

export const getAllBanksFailure = (error) => {
  return {
    type: GET_ALL_BANKS_FAILURE,
    payload: error,
  };
}

export const addBank = (payload) => {
  return {
    type: ADD_BANK,
    payload: payload,
  };
}
export const addBankSuccess = (payload) => {
  return {
    type: ADD_BANK_SUCCESS,
    payload: payload,
  };
}

export const addBankFailure = (error) => {
  return {
    type: ADD_BANK_FAILURE,
    payload: error,
  };
}

export const getBank = (payload) => {
  return {
    type: GET_BANK,
    payload: payload,
  };
}
export const getBankSuccess = (payload) => {
  return {
    type: GET_BANK_SUCCESS,
    payload: payload,
  };
}

export const getBankFailure = (error) => {
  return {
    type: GET_BANK_FAILURE,
    payload: error,
  };
}

export const editBank = (payload) => {
  return {
    type: EDIT_BANK,
    payload: payload,
  };
}
export const editBankSuccess = (payload) => {
  return {
    type: EDIT_BANK_SUCCESS,
    payload: payload,
  };
}

export const editBankFailure = (error) => {
  return {
    type: EDIT_BANK_FAILURE,
    payload: error,
  };
}

export const deleteBank = (payload) => {
  return {
    type: DELETE_BANK,
    payload: payload,
  };
}

export const deleteBankSuccess = (payload) => {
  return {
    type: DELETE_BANK_SUCCESS,
    payload: payload,
  };
}

export const deleteBankFailure = (error) => {
  return {
    type: DELETE_BANK_FAILURE,
    payload: error,
  };
}