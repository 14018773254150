import React from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { addCountry } from "store/actions";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { FormattedMessage } from "react-intl";
import countries from "helpers/countriesData.json";
const AddCountry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    dispatch(addCountry({ data, navigate }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>اضف دولة جديدة</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>كود البلد</h5>
                      <div>
                        <Controller
                          control={control}
                          render={({
                            field: { onChange, value, name, ref, selected },
                          }) => (
                            <ReactSelect
                              inputRef={ref}
                              name={name}
                              placeholder={<FormattedMessage id={"role"} />}
                              options={countries}
                              onChange={(selectedOptions) => {
                                onChange(selectedOptions?.value);
                              }}
                              value={countries?.find(
                                (country) => value === country?.value
                              )}
                              // isMulti
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                            />
                          )}
                          name={"countryCode"}
                          rules={{ required: true }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Row />
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        اضافة الدولة
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCountry;
