import BootyPagination from "components/shared/Pagination";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getAllReportRequests } from "store/actions";
import Table from "./Table";
import { FormattedMessage, useIntl } from "react-intl";
import { Dropdown } from "react-bootstrap";

const ReportRequests = () => {
  const dispatch = useDispatch();
  const { transactionRequests, loading, appPresntage } = useSelector(
    (state) => state.requestsReports
  );
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getAllReportRequests(searchParams));
  }, [dispatch, searchParams]);

  return (
    <>
      <div className="requests-wrap">
        <Table
          data={transactionRequests?.transactions}
          loading={loading}
          topElement={
            <div className="row col-6 mt-4 justify-content-space-evenly card-head">
              <h4 className="col">
                <FormattedMessage id="appFee" /> :{" "}
                {`${parseFloat(appPresntage).toFixed(2)} `}
                <FormattedMessage id="sar" />
                <br />
                <small style={{ color: "inherit", fontSize: "12px" }}>
                  *<FormattedMessage id="appFeeDesc" />
                </small>
              </h4>
            </div>
          }
        />

        <BootyPagination metaData={transactionRequests?.metadata} />
      </div>
    </>
  );
};

export default ReportRequests;
