import {
  GET_ALL_ADMINS,
  GET_ALL_ADMINS_FAILURE,
  GET_ALL_ADMINS_SUCCESS,
  ADD_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILURE,
  GET_ADMIN,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILURE,
  EDIT_ADMIN,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_FAILURE,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE
} from "./actionTypes";


export const getAllAdmins = (payload) => {
  return {
    type: GET_ALL_ADMINS,
    payload: payload,
  };
}
export const getAllAdminsSuccess = (payload) => {
  return {
    type: GET_ALL_ADMINS_SUCCESS,
    payload: payload,
  };
}

export const getAllAdminsFailure = (error) => {
  return {
    type: GET_ALL_ADMINS_FAILURE,
    payload: error,
  };
}

// add user 
export const addAdmin = (payload) => {
  return {
    type: ADD_ADMIN,
    payload: payload
  }
}

export const addAdminSuccess = (payload) => {
  return {
    type: ADD_ADMIN_SUCCESS,
    payload: payload
  }
}

export const addAdminFailure = (error) => {
  return {
    type: ADD_ADMIN_FAILURE,
    payload: error
  }
}

// get user 
export const getAdmin = (payload) => {
  return {
    type: GET_ADMIN,
    payload: payload
  }
}

export const getAdminSuccess = (payload) => {
  return {
    type: GET_ADMIN_SUCCESS,
    payload: payload
  }
}

export const getAdminFailure = (error) => {
  return {
    type: GET_ADMIN_FAILURE,
    payload: error
  }
}

// edit user 
export const editAdmin = (payload) => {
  return {
    type: EDIT_ADMIN,
    payload: payload
  }
}

export const editAdminSuccess = (payload) => {
  return {
    type: EDIT_ADMIN_SUCCESS,
    payload: payload
  }
}

export const editAdminFailure = (error) => {
  return {
    type: EDIT_ADMIN_FAILURE,
    payload: error
  }
}

// delete user
export const deleteAdmin = (payload) => {
  return {
    type: DELETE_ADMIN,
    payload: payload
  }
}

export const deleteAdminSuccess = (payload) => {

  return {
    type: DELETE_ADMIN_SUCCESS,
    payload: payload
  }
}

export const deleteAdminFailure = (error) => {
  return {
    type: DELETE_ADMIN_FAILURE,
    payload: error
  }
} 