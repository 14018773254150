export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const GET_ANNOUNCEMENTS_FAILURE = "GET_ANNOUNCEMENTS_FAILURE";
export const GET_ANNOUNCEMENTS_SUCCESS = "GET_ANNOUNCEMENTS_SUCCESS";

export const GET_SINGLE_ANNOUNCEMENT = "GET_SINGLE_ANNOUNCEMENT";
export const GET_SINGLE_ANNOUNCEMENT_FAILURE =
  "GET_SINGLE_ANNOUNCEMENT_FAILURE";
export const GET_SINGLE_ANNOUNCEMENT_SUCCESS =
  "GET_SINGLE_ANNOUNCEMENT_SUCCESS";

export const ADD_ANNOUNCEMENT = "ADD_ANNOUNCEMENT";
export const ADD_ANNOUNCEMENT_FAILURE = "ADD_ANNOUNCEMENT_FAILURE";
export const ADD_ANNOUNCEMENT_SUCCESS = "ADD_ANNOUNCEMENT_SUCCESS";

export const EDIT_ANNOUNCEMENT = "EDIT_ANNOUNCEMENT";
export const EDIT_ANNOUNCEMENT_FAILURE = "EDIT_ANNOUNCEMENT_FAILURE";
export const EDIT_ANNOUNCEMENT_SUCCESS = "EDIT_ANNOUNCEMENT_SUCCESS";

export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
export const DELETE_ANNOUNCEMENT_FAILURE = "DELETE_ANNOUNCEMENT_FAILURE";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";
