import {
  DELETE_REQUEST,
  DELETE_REQUEST_FAILURE,
  DELETE_REQUEST_SUCCESS,
  GET_ALL_REQUESTS,
  GET_ALL_REQUESTS_FAILURE,
  GET_ALL_REQUESTS_SUCCESS,
  GET_ALL_USER_REQUESTS,
  GET_ALL_USER_REQUESTS_FAILURE,
  GET_ALL_USER_REQUESTS_SUCCESS,
  GET_REQUEST,
  GET_REQUEST_FAILURE,
  GET_REQUEST_SUCCESS,
  APPROVE_REQUEST,
  APPROVE_REQUEST_FAILURE,
  APPROVE_REQUEST_SUCCESS,
  APPROVE_ALL_REQUESTS,
  APPROVE_ALL_REQUESTS_FAILURE,
  APPROVE_ALL_REQUESTS_SUCCESS,
  REJECT_REQUEST,
  REJECT_REQUEST_FAILURE,
  REJECT_REQUEST_SUCCESS,
} from "./actionTypes";

export const getAllTransactionRequests = (payload) => {
  return {
    type: GET_ALL_REQUESTS,
    payload: payload,
  };
};
export const getAllTransactionRequestsSuccess = (payload) => {
  return {
    type: GET_ALL_REQUESTS_SUCCESS,
    payload: payload,
  };
};

export const getAllTransactionRequestsFailure = (error) => {
  return {
    type: GET_ALL_REQUESTS_FAILURE,
    payload: error,
  };
};

export const getTransactionRequest = (payload) => {
  return {
    type: GET_REQUEST,
    payload: payload,
  };
};
export const getTransactionRequestSuccess = (payload) => {
  return {
    type: GET_REQUEST_SUCCESS,
    payload: payload,
  };
};

export const getTransactionRequestFailure = (error) => {
  return {
    type: GET_REQUEST_FAILURE,
    payload: error,
  };
};

export const deleteTransactionRequest = (payload) => {
  return {
    type: DELETE_REQUEST,
    payload: payload,
  };
};

export const deleteTransactionRequestSuccess = (payload) => {
  return {
    type: DELETE_REQUEST_SUCCESS,
    payload: payload,
  };
};

export const deleteTransactionRequestFailure = (error) => {
  return {
    type: DELETE_REQUEST_FAILURE,
    payload: error,
  };
};

export const approveTransactionRequest = (payload) => {
  return {
    type: APPROVE_REQUEST,
    payload: payload,
  };
};

export const approveTransactionRequestSuccess = (payload) => {
  return {
    type: APPROVE_REQUEST_SUCCESS,
    payload: payload,
  };
};

export const approveTransactionRequestFailure = (error) => {
  return {
    type: APPROVE_REQUEST_FAILURE,
    payload: error,
  };
};

// -------------------------------------------------------

export const approveAllTransactionsRequest = (payload) => {
  return {
    type: APPROVE_ALL_REQUESTS,
    payload: payload,
  };
};

export const approveAllTransactionsRequestSuccess = (payload) => {
  return {
    type: APPROVE_ALL_REQUESTS_SUCCESS,
    payload: payload,
  };
};

export const approveAllTransactionsRequestFailure = (error) => {
  return {
    type: APPROVE_ALL_REQUESTS_FAILURE,
    payload: error,
  };
};
// -------------------------------------------------------

export const rejectTransactionRequest = (payload) => {
  return {
    type: REJECT_REQUEST,
    payload: payload,
  };
};

export const rejectTransactionRequestSuccess = (payload) => {
  return {
    type: REJECT_REQUEST_SUCCESS,
    payload: payload,
  };
};

export const rejectTransactionRequestFailure = (error) => {
  return {
    type: REJECT_REQUEST_FAILURE,
    payload: error,
  };
};

export const getAllUserTransactionRequests = (payload) => {
  return {
    type: GET_ALL_USER_REQUESTS,
    payload: payload,
  };
};

export const getAllUserTransactionRequestsSuccess = (payload) => {
  return {
    type: GET_ALL_USER_REQUESTS_SUCCESS,
    payload: payload,
  };
};

export const getAllUserTransactionRequestsFailure = (error) => {
  return {
    type: GET_ALL_USER_REQUESTS_FAILURE,
    payload: error,
  };
};
