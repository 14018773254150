import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
// import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";
import server from "../../api/server";
import DataTable from "react-data-table-component";
import BootyPagination from "components/shared/Pagination";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import Swal from "sweetalert2";
import { getId } from "helpers/functions";
import { checkPermissions } from "helpers/authentication/checkPermissions";
import ControlArea from "components/Layout/ControlArea";
// import DataTableExtensions from "react-data-table-component-extensions";
// import { confirmAlert } from "react-confirm-alert";
import Icon from "assets/svgs/blog.svg";

const BlogsIndex = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { formatMessage, locale } = useIntl();
  const [params] = useSearchParams();

  const onStart = async (params = "") => {
    // dispatch(
    // 	updateMeta({
    // 		title: "المدونة",
    // 		breadcrumb: "المدوانات",
    // 		button: {
    // 			show: true,
    // 			text: "اضافة مدونة جديدة",
    // 			link: "/blogs/create",
    // 		},
    // 	})
    // );

    try {
      const pageData = await server(cookie).get(`/blogs${params}`);

      setData(pageData.data.data);

      setLoading(false);
    } catch (error) {
      toast.error("خطأ، برجاء المحاولة لاحقا!");
      setLoading(false);
    }
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: formatMessage({ id: "confirmDelete" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        handleConfrimDelete(id);
      }
    });
  };

  const columns = [
    // {
    // 	name: "id",
    // 	selector: "id",
    // 	sortable: true,
    // },
    {
      name: "الاسم",
      selector: (row) => row?.title?.[locale] || row?.title,
      sortable: true,
      width: "auto",
    },
    {
      name: "",
      selector: "settings",
      cell: (row) => (
        <div className="global-dropdown">
          <Dropdown>
            <Dropdown.Toggle>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="evenOdd"
                className="bi bi-three-dots-vertical"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                // onClick={(e) => handleEditBank(row?.id)}

                to={`/blogs/show/${getId(row)}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="#fff"
                  className="bi bi-pencil edit"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
                <FormattedMessage id={"edit"} />
              </Dropdown.Item>

              <Dropdown.Item onClick={(e) => handleDelete(getId(row))}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="#fff"
                  className="bi bi-trash delete"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
                <FormattedMessage id={"delete"} />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      width: "50px",
    },
  ];

  const handleConfrimDelete = (articleID) => {
    deleteArticle(articleID);
  };

  const deleteArticle = (CommentID) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/blogs/${CommentID}`, {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
          Accept: "application/json",
        },
      })
      .then((_) => onStart() && toast.success("تم الحذف بنجاح"))
      .catch((_) => toast.error("حدث خطا!"));
  };

  useEffect(() => {
    const paramsStr = params?.size > 0 ? `?${params}` : "";
    onStart(paramsStr);
  }, [params]);

  return (
    <section className="">
      {/* {loading && <Loader />} */}
      {checkPermissions(["categories-index"]) && (
        <ControlArea
          btnTxt={<FormattedMessage id={"addBlog"} />}
          cardTxt={<FormattedMessage id={"blogs"} />}
          icon={Icon}
          url="/blogs/create"
        />
      )}
      <div className="datatable card">
        {/* <DataTableExtensions columns={columns} data={data}> */}
        <DataTable
          columns={columns}
          data={data?.blogs}
          progressPending={loading}
          progressComponent={<Loader />}
          defaultSortField="name"
          // selectableRows
          // subHeader
          // noHeader
          // defaultSortField="id"
          // defaultSortAsc={false}
          // pagination
          // highlightOnHover
          // direction="rtl"
          // striped
        />
        {/* </DataTableExtensions> */}
        <BootyPagination metaData={data?.metadata} />
      </div>
    </section>
  );
};

export default BlogsIndex;
