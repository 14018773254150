import server from "./server";

export const getAllBanksApi = async (searchParams) => {
  const response = await server().get(`/banks/?${searchParams}`);

  return response.data;
}

export const addBankApi = async (data) => {
  const response = await server().post("/banks", data);

  return response.data;
}


export const getBankApi = async (id) => {
  const response = await server().get(`/banks/${id}`);

  return response.data;
}

export const editBankApi = async ({ id, data }) => {
  const response = await server().put(`/banks/${id}`, data);

  return response.data;
}

export const deleteBankApi = async (id) => {
  const response = await server().delete(`/banks/${id}`);

  return response.data;
}