import { useState } from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllBankAccounts } from "store/actions";
import Table from "./Table";

const BankAccounts = () => {
  const { bankAccounts, loading, error } = useSelector(
    (state) => state.bankAccounts
  );
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllBankAccounts());
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [error, formatMessage, locale, isDispatched]);

  return (
    <>
      <div className="bankAccounts-wrap">
        <Table data={bankAccounts} loading={loading} />
      </div>
    </>
  );
};

export default BankAccounts;
