import {


  GET_ALL_PERMISSIONS,
  GET_ALL_PERMISSIONS_FAILURE,
  GET_ALL_PERMISSIONS_SUCCESS,


} from "./actionTypes";


export const getAllPermissions = (payload) => {
  return {
    type: GET_ALL_PERMISSIONS,
    payload: payload,
  };
}
export const getAllPermissionsSuccess = (payload) => {
  return {
    type: GET_ALL_PERMISSIONS_SUCCESS,
    payload: payload,
  };
}

export const getAllPermissionsFailure = (error) => {
  return {
    type: GET_ALL_PERMISSIONS_FAILURE,
    payload: error,
  };
}
