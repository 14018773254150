import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "flatpickr/dist/flatpickr.css";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { getUser } from "store/actions";
import Loader from "components/shared/Loader";
import { Col, Row } from "react-bootstrap";
import { getDateOfBirthWithFormat } from "helpers/functions";
import BankAccountsTable from "./BankAccountsTable";
import Stars from "components/Stars";
import NotFound from "views/NotFound";

const ShowUser = () => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { singleUser, loading, error } = useSelector((state) => state.users);

  // effects
  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch, id]);

  // error
  useEffect(() => {
    if (error && error?.errors) {
      error?.errors.forEach((error) => {
        toast.error(error?.msg, {
          position: locale === "ar" ? "bottom-left" : "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    }
  }, [error, locale, navigate]);

  const renderMainUserInfo = () => {
    return (
      <div className="card">
        <div className="card-body user-details">
          <div className="user-main-info-wrapper">
            <div className="user-main-block">
              <div className={`img-block `}>
                <img className="store-logo" src={singleUser?.image} alt="" />
              </div>
              <div className="user-main-details">
                <h4 className="user-main-head">
                  {`${singleUser?.firstName} ${singleUser?.lastName}`}
                  {/* <span className={`user-login-status ${singleUser?.online ? "online" : "ofline"}`}>({singleUser?.online ? <FormattedMessage id={"online"} /> : <FormattedMessage id={"ofline"} />})</span> */}
                </h4>
                <Stars rate={singleUser?.rate} />
              </div>
            </div>

            <div>
              <div className="img-id-block">
                <img src={singleUser?.idImage} alt="" />
              </div>
            </div>
          </div>

          <Row>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"firstName"} /> :{" "}
                </h5>
                <p className="user-parg-detail">{singleUser?.firstName}</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"lastName"} /> :{" "}
                </h5>
                <p className="user-parg-detail">{singleUser?.lastName}</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"bio"} /> :{" "}
                </h5>
                <p className="user-parg-detail">{singleUser?.bio}</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"isVerified"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  <FormattedMessage id={`${singleUser?.isVerified || " "}`} />
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"phone"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  {singleUser?.phoneInfo
                    ? `(${singleUser?.phoneInfo?.countryDialCode})${singleUser?.phoneInfo?.phone}`
                    : `${singleUser?.phone || ""}`}
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"email"} /> :{" "}
                </h5>
                <p className="user-parg-detail">{singleUser?.email}</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"gender"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  <FormattedMessage id={singleUser?.gender || " "} />
                </p>
              </div>
            </Col>

            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"accountType"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  <FormattedMessage id={singleUser?.type || " "} />
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"jobType"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  <FormattedMessage id={singleUser?.jobType || " "} />
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"commercialRegistrationNo"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  {singleUser?.commercialRegistrationNo}
                </p>
              </div>
            </Col>

            {/* <Col lg={4} md={6} sm={12} className="m-b-15" >
            <div className="user-block">
              <h5 className="user-head"> <FormattedMessage id={"role"} /> : </h5>
              <p className="user-parg-detail">{singleUser?.role ? singleUser?.role?.name : "user"}</p>
            </div>
          </Col> */}
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"level"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  <FormattedMessage id={singleUser?.level || " "} />
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"dateOfBirth"} /> :{" "}
                </h5>
                {singleUser?.dateOfBirth ? (
                  <p className="user-parg-detail">
                    {getDateOfBirthWithFormat(singleUser?.dateOfBirth)}
                  </p>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block user-skills-blocks">
                <h5 className="user-head">
                  <FormattedMessage id={"skills"} /> :{" "}
                </h5>
                <div className="user-parg-detail user-skills">
                  {singleUser?.skills?.map((skill) => {
                    return (
                      <span key={skill?.id} className="user-skill-block">
                        {skill?.name}
                      </span>
                    );
                  })}
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"price"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  {singleUser?.price} <FormattedMessage id={"sar"} />
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"balance"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  {singleUser?.balance} <FormattedMessage id={"sar"} />
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"rate"} /> :{" "}
                </h5>
                <p className="user-parg-detail">{singleUser?.rate}</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"reviewsCount"} /> :{" "}
                </h5>
                <p className="user-parg-detail">{singleUser?.reviewsCount}</p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"rehiringjobSize"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  {singleUser?.rehiringjobSize}
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"totalNumberOfjobs"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  {singleUser?.totalNumberOfjobs}
                </p>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} className="m-b-15">
              <div className="user-block">
                <h5 className="user-head">
                  <FormattedMessage id={"totalNumberOfRehiring"} /> :{" "}
                </h5>
                <p className="user-parg-detail">
                  {singleUser?.totalNumberOfRehiring}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const renderUserBankAccounts = () => {
    if (singleUser?.bankAccounts?.length === 0) return;
    return <BankAccountsTable data={singleUser?.bankAccounts} />;
  };

  const renderPortfolioItems = () => {
    if (singleUser?.portfolioItems?.length === 0) return;

    return (
      <div className="card">
        <div className="card-head">
          <h4>
            <FormattedMessage id="portfolioItems" />
          </h4>
        </div>
        <div className="card-body user-portfolioItems">
          <Row>
            {singleUser?.portfolioItems?.map((item) => {
              return (
                <Col lg={3} md={6} sm={12} key={item?.id}>
                  <div className="user-portfolioItem">
                    <img src={item?.source} alt="" />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  };

  const renderJobsLinks = () => {
    return (
      <>
        <div className="card user-internal-pages-links">
          <Row>
            <Col lg={4} md={4} sm={12}>
              <Link
                to={`/users/show/${id}/transactionRequests`}
                className="btn btn-big btn-outline-info"
                data-animation="ripple"
              >
                <FormattedMessage id="transactionRequests" />
              </Link>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <Link
                to={`/users/show/${id}/providerJobs`}
                className="btn btn-big btn-outline-info"
                data-animation="ripple"
              >
                <FormattedMessage id="jobsAsProvider" />
              </Link>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <Link
                to={`/users/show/${id}/employeeJobs`}
                className="btn btn-big btn-outline-info"
                data-animation="ripple"
              >
                <FormattedMessage id="jobsAsEmployee" />
              </Link>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const renderClientReviews = () => {
    if (!singleUser?.clientReviews?.length) return;

    return (
      <div className="user-clients-reviews card">
        <div className="card-head">
          <div>
            <h4>
              <FormattedMessage id="clientsReviews" />
            </h4>
          </div>
        </div>
        <Row>
          {singleUser?.clientReviews?.map((review) => {
            return (
              <Col className="m-b-15" lg="4" md="12" sm="12" key={review._id}>
                <div className="review-block">
                  <Stars rate={review?.rate} />

                  <p className="review-desc">{review?.comment}</p>

                  <Link
                    className="provider-person"
                    to={`/users/show/${review?.jobProvider?.id}`}
                  >
                    <div className="provider-person-image">
                      <img
                        className="store-logo"
                        src={review?.jobProvider?.image}
                        alt=""
                      />
                    </div>
                    <div className="provider-details">
                      <h4 className="provider-person-name">{`${review?.jobProvider?.firstName} ${review?.jobProvider?.lastName}`}</h4>
                    </div>
                  </Link>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  if (loading) return <Loader />;

  if (error) return <NotFound />;

  return (
    <>
      {renderMainUserInfo()}
      {renderJobsLinks()}
      {renderUserBankAccounts()}
      {renderPortfolioItems()}
      {renderClientReviews()}
    </>
  );
};

export default ShowUser;
