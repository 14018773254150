import {
  DELETE_REQUEST,
  DELETE_REQUEST_FAILURE,
  DELETE_REQUEST_SUCCESS,
  GET_ALL_REQUESTS,
  GET_ALL_REQUESTS_FAILURE,
  GET_ALL_REQUESTS_SUCCESS,
  GET_ALL_USER_REQUESTS,
  GET_ALL_USER_REQUESTS_FAILURE,
  GET_ALL_USER_REQUESTS_SUCCESS,
  GET_REQUEST,
  GET_REQUEST_FAILURE,
  GET_REQUEST_SUCCESS,
  APPROVE_REQUEST,
  APPROVE_REQUEST_FAILURE,
  APPROVE_REQUEST_SUCCESS,
  APPROVE_ALL_REQUESTS,
  APPROVE_ALL_REQUESTS_FAILURE,
  APPROVE_ALL_REQUESTS_SUCCESS,
  REJECT_REQUEST,
  REJECT_REQUEST_FAILURE,
  REJECT_REQUEST_SUCCESS,
  WITHDRAWAL_REQUEST,
  WITHDRAWAL_REQUEST_FAILURE,
  WITHDRAWAL_REQUEST_SUCCESS,
} from "./actionTypes";

export const getAllReportRequests = (payload) => {
  return {
    type: GET_ALL_REQUESTS,
    payload: payload,
  };
};
export const getAllReportRequestsSuccess = (payload) => {
  return {
    type: GET_ALL_REQUESTS_SUCCESS,
    payload: payload,
  };
};

export const getAllReportRequestsFailure = (error) => {
  return {
    type: GET_ALL_REQUESTS_FAILURE,
    payload: error,
  };
};

export const getReportRequest = (payload) => {
  return {
    type: GET_REQUEST,
    payload: payload,
  };
};
export const getReportRequestSuccess = (payload) => {
  return {
    type: GET_REQUEST_SUCCESS,
    payload: payload,
  };
};

export const getReportRequestFailure = (error) => {
  return {
    type: GET_REQUEST_FAILURE,
    payload: error,
  };
};
// ---------------------------------------------------------
export const withdrawalRequest = (payload) => {
  return {
    type: WITHDRAWAL_REQUEST,
    payload: payload,
  };
};
export const withdrawalRequestSuccess = (payload) => {
  return {
    type: WITHDRAWAL_REQUEST_SUCCESS,
    payload: payload,
  };
};

export const withdrawalRequestFailure = (error) => {
  return {
    type: WITHDRAWAL_REQUEST_FAILURE,
    payload: error,
  };
};

export const deleteReportRequest = (payload) => {
  return {
    type: DELETE_REQUEST,
    payload: payload,
  };
};

export const deleteReportRequestSuccess = (payload) => {
  return {
    type: DELETE_REQUEST_SUCCESS,
    payload: payload,
  };
};

export const deleteReportRequestFailure = (error) => {
  return {
    type: DELETE_REQUEST_FAILURE,
    payload: error,
  };
};

export const approveReportRequest = (payload) => {
  return {
    type: APPROVE_REQUEST,
    payload: payload,
  };
};

export const approveReportRequestSuccess = (payload) => {
  return {
    type: APPROVE_REQUEST_SUCCESS,
    payload: payload,
  };
};

export const approveReportRequestFailure = (error) => {
  return {
    type: APPROVE_REQUEST_FAILURE,
    payload: error,
  };
};

// -------------------------------------------------------

export const approveAllReportsRequest = (payload) => {
  return {
    type: APPROVE_ALL_REQUESTS,
    payload: payload,
  };
};

export const approveAllReportsRequestSuccess = (payload) => {
  return {
    type: APPROVE_ALL_REQUESTS_SUCCESS,
    payload: payload,
  };
};

export const approveAllReportsRequestFailure = (error) => {
  return {
    type: APPROVE_ALL_REQUESTS_FAILURE,
    payload: error,
  };
};
// -------------------------------------------------------

export const rejectReportRequest = (payload) => {
  return {
    type: REJECT_REQUEST,
    payload: payload,
  };
};

export const rejectReportRequestSuccess = (payload) => {
  return {
    type: REJECT_REQUEST_SUCCESS,
    payload: payload,
  };
};

export const rejectReportRequestFailure = (error) => {
  return {
    type: REJECT_REQUEST_FAILURE,
    payload: error,
  };
};

export const getAllUserReportRequests = (payload) => {
  return {
    type: GET_ALL_USER_REQUESTS,
    payload: payload,
  };
};

export const getAllUserReportRequestsSuccess = (payload) => {
  return {
    type: GET_ALL_USER_REQUESTS_SUCCESS,
    payload: payload,
  };
};

export const getAllUserReportRequestsFailure = (error) => {
  return {
    type: GET_ALL_USER_REQUESTS_FAILURE,
    payload: error,
  };
};
