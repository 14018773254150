import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { getJob } from "store/actions";
import Loader from "components/shared/Loader";
import { Col, Row } from "react-bootstrap";
import Stars from "components/Stars";

const ShowJob = () => {
  // const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, singleJob } = useSelector((state) => state.jobs);
  const { locale } = useIntl();

  // effects
  useEffect(() => {
    dispatch(getJob(id));
  }, [dispatch, id]);

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card job-details">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"showJob"} />
            </h4>
          </div>
          <div className="card-body table-details--">
            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"jobTitle"} />
                    </td>
                    <td>{singleJob?.title} </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"description"} />
                    </td>
                    <td>{singleJob?.description} </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"jobType"} />
                    </td>
                    <td>
                      <FormattedMessage id={singleJob?.jobType || " "} />{" "}
                    </td>
                  </tr>
                  {singleJob?.category &&
                    Object.keys(singleJob?.category).length !== 0 && (
                      <tr>
                        <td>
                          {" "}
                          <FormattedMessage id={"category"} />
                        </td>
                        <td>{singleJob?.category?.name?.[locale]}</td>
                      </tr>
                    )}

                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"skillsRequired"} />
                    </td>
                    <td>
                      <div className="job-skills-required">
                        {singleJob?.skillsRequired?.map((skill) => {
                          return (
                            <span key={skill?.id} className="skill-block">
                              {skill?.name}
                            </span>
                          );
                        })}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"workDates"} />
                    </td>
                    <td>
                      <div className="job-skills-required">
                        {singleJob?.workDates?.map((day) => {
                          return (
                            <span key={day?.id} className="skill-block">
                              {day?.day}
                            </span>
                          );
                        })}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"level"} />
                    </td>
                    <td>
                      <FormattedMessage id={singleJob?.level || " "} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"deadline"} />
                    </td>
                    <td>
                      {singleJob?.deadline} (<FormattedMessage id={"days"} />)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"gender"} />
                    </td>
                    <td>
                      <FormattedMessage id={singleJob?.gender || " "} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"priceType"} />
                    </td>
                    <td>
                      <FormattedMessage id={singleJob?.priceType || " "} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"status"} />
                    </td>
                    <td>
                      <FormattedMessage id={singleJob?.status || " "} />{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"price"} />
                    </td>
                    <td>
                      {singleJob?.price} <FormattedMessage id={"sar"} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"provider"} />
                    </td>
                    <td>
                      <Link
                        to={`/users/show/${singleJob?.provider?.id}`}
                      >{`${singleJob?.provider?.firstName} ${singleJob?.provider?.lastName}`}</Link>{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"employee"} />
                    </td>
                    <td>
                      <Link to={`/users/show/${singleJob?.employee?.id}`}>{`${
                        singleJob?.employee?.firstName || ""
                      } ${singleJob?.employee?.lastName || ""}`}</Link>{" "}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"createdAtDate"} />
                    </td>
                    <td>
                      {new Date(singleJob?.createdAt).toLocaleString("en")}{" "}
                    </td>
                  </tr>

                  {singleJob?.address &&
                    Object.keys(singleJob?.address).length !== 0 && (
                      <tr>
                        <td>
                          {" "}
                          <FormattedMessage id={"address"} />
                        </td>
                        <td>
                          <ul>
                            <li>
                              <span className="head__">
                                {" "}
                                <FormattedMessage id={"city"} /> :{" "}
                              </span>
                              <span className="text__">
                                {singleJob?.address?.city}{" "}
                              </span>
                            </li>
                            <li>
                              <span className="head__">
                                {" "}
                                <FormattedMessage id={"country"} /> :{" "}
                              </span>
                              <span className="text__">
                                {singleJob?.address?.country}{" "}
                              </span>
                            </li>
                            <li>
                              <span className="head__">
                                {" "}
                                <FormattedMessage
                                  id={"formattedAddress"}
                                /> :{" "}
                              </span>
                              <span className="text__">
                                {singleJob?.address?.formattedAddress}{" "}
                              </span>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {singleJob?.requests && (
          <div className="card job-requests">
            <div className="card-head">
              <h4>
                <FormattedMessage id={"requests"} />
              </h4>
            </div>
            <div className="job-requests-blocks">
              {
                <Row>
                  {singleJob?.requests?.map((request) => {
                    return (
                      <Col
                        className="m-b-15"
                        lg="6"
                        md="12"
                        sm="12"
                        key={request.id}
                      >
                        <div className="job-request-block">
                          <div className="job-request-employee-block">
                            <Link
                              className="employee-person"
                              to={`/users/show/${request?.employee?.id}`}
                            >
                              <div className="employee-person-image">
                                <img
                                  className="store-logo"
                                  src={request?.employee?.image}
                                  alt=""
                                />
                              </div>
                              <div className="employee-details">
                                <h4 className="employee-person-name">{`${request?.employee?.firstName} ${request?.employee?.lastName}`}</h4>
                                <Stars rate={request?.employee?.rate} />
                              </div>
                            </Link>
                          </div>

                          <p className="request-desc">{request?.proposal}</p>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              }
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ShowJob;
