import { getId } from "helpers/functions";
import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  ADD_COUNTRY,
  ADD_COUNTRY_SUCCESS,
  ADD_COUNTRY_FAILURE,
  DELETE_COUNTRY,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAILURE,
  GET_SINGLE_COUNTRY,
  GET_SINGLE_COUNTRY_SUCCESS,
  GET_SINGLE_COUNTRY_FAILURE,
  TOGGLE_COUNTRY,
  TOGGLE_COUNTRY_SUCCESS,
  TOGGLE_COUNTRY_FAILURE,
} from "./actionTypes";

const initialState = {
  countries: [],
  loading: false,
  error: "",
  singleCountry: {},
};

const brands = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_COUNTRIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        countries: action.payload.countries,
      };
      break;
    case GET_COUNTRIES_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_COUNTRY:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_SINGLE_COUNTRY_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleCountry: action.payload.country,
      };
      break;

    case GET_SINGLE_COUNTRY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        singleCountry: {},
      };
      break;

    case ADD_COUNTRY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_COUNTRY_SUCCESS:
      state = {
        ...state,
        loading: false,
        countries: action.payload.countries,
      };
      break;
    case ADD_COUNTRY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case TOGGLE_COUNTRY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case TOGGLE_COUNTRY_SUCCESS:
      const countriesAfterUpdating = state?.countries?.map((country) =>
        getId(country) === getId(action.payload?.country)
          ? action.payload?.country
          : country
      );

      state = {
        ...state,
        loading: false,
        countries: countriesAfterUpdating,
      };
      break;
    case TOGGLE_COUNTRY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    case DELETE_COUNTRY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_COUNTRY_SUCCESS:
      const countriesAfterDeleteing = [
        ...state?.countries?.filter(
          (country) => getId(country) !== action.payload
        ),
      ];
      state = {
        ...state,
        loading: false,
        countries: countriesAfterDeleteing,
      };
      break;
    case DELETE_COUNTRY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default brands;
