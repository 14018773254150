import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import { editAd, getAllCategories, getAds, getSingleAd } from "store/actions";
import Loader from "components/shared/Loader";
import { Input, Textarea } from "components/shared/FormComponents";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import ImageUpload from "components/shared/ImageUpload";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { handleImage } from "helpers/functions";

const EditAd = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, error, ads, singleAd } = useSelector((state) => state.ads);

  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });

  // const [transparent, setTransparent] = useState(true);
  // effects
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    if (ads?.length === 0) dispatch(getAds());

    dispatch(getSingleAd(id));

    return () => reset({});
  }, [ads?.length, dispatch, id]);

  useEffect(() => {
    reset(singleAd);
    setSelectedImage({
      preview: null,
      path: singleAd?.url,
    });
  }, [locale, reset, singleAd]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, navigate]);

  const onSubmit = (data) => {
    // if (transparent) data.color = "";

    // if (!selectedImage?.path && !data.image) {
    //   toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
    //   return;
    // }

    data.url =
      //  `/uploads/${(
      selectedImage?.path || data.url;
    // )
    //   ?.split("/")
    //   .at(-1)}`;

    dispatch(editAd({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"editAd"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Col md={6} xs={12}>
                  <div className="form-group">
                    <div className="input-head-wrapper">
                      <h5>
                        <FormattedMessage id="type" />
                      </h5>
                    </div>
                    <div>
                      <select
                        className="form-control form-outline"
                        {...register("type")}
                      >
                        <option value="">اختر نوع الاعلان</option>
                        <option value="image">
                          {formatMessage({ id: "image" })}
                        </option>
                        <option value="video">
                          {formatMessage({ id: "video" })}
                        </option>
                      </select>
                    </div>
                    <p className="error-hint">
                      {errors?.payloadAction?.type === "required" && (
                        <FormattedMessage id="requiredField" />
                      )}
                    </p>
                  </div>
                </Col>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <Row>
                        <Col md="3" xs="12">
                          <h5>
                            {watch("type") && (
                              <FormattedMessage
                                id={
                                  watch("type") === "image" ? "image" : "video"
                                }
                              />
                            )}
                          </h5>
                          <ImageUpload
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                            type={watch("type")}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} xs={12}>
                    <Input
                      langId="title"
                      name="title.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <Input
                      langId="title"
                      name="title.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} xs={12}>
                    <Textarea
                      langId="description"
                      name="description.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <Textarea
                      langId="description"
                      name="description.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAd;
