import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";

import GuestRoute from "helpers/authentication/guestRoute";
import AuthRoute from "helpers/authentication/authRoute";

import Layout from "./components/Layout/Layout";
import Dashboard from "views/dashboard";
import NotFound from "views/NotFound";
import Login from "views/login";
import Logout from "views/Logout";
import { getCurrentUser } from "store/actions";
import Loader from "./components/shared/Loader";

import Categories from "views/categories";
import AddCategory from "views/categories/AddCategory";
import EditCategory from "views/categories/EditCategory";

import AddressTypes from "views/AddressTypes";
import AddAddressType from "views/AddressTypes/AddAddressType";
import EditAddressType from "views/AddressTypes/EditAddressType";
import Skills from "views/skills";
import AddSkill from "views/skills/AddSkill";
import EditSkill from "views/skills/EditSkill";
import AddBank from "views/banks/Addbank";
import EditBank from "views/banks/EditBank";
import Banks from "views/banks";
import Settings from "views/Settings";
import Pages from "views/pages/index";
import EditPage from "views/pages/EditPage";
import AddPage from "views/pages/AddPage";
import Users from "views/Users";
import AddUser from "views/Users/AddUser";
import EditUser from "views/Users/EditUser";
import Roles from "views/roles";
import AddRole from "views/roles/AddRole";
import EditRole from "views/roles/EditRole";
import Requests from "views/TransactionRequests";
import ShowRequest from "views/TransactionRequests/ShowRequest";
import ShowUser from "views/Users/ShowUser";
import ProviderJobs from "views/Users/ProviderJobs";
import EmployeeJobs from "views/Users/EmployeeJobs";
import TransactionRequests from "views/Users/TransactionRequests";
import BankAccounts from "views/BankAccounts";
import Jobs from "views/Jobs";
import ShowJob from "views/Jobs/ShowJob";
import ContactUs from "views/ContactUs";
import Admins from "views/Admins";
import AddAdmin from "views/Admins/AddAdmin";
import EditAdmin from "views/Admins/EditAdmin";
import ReportRequests from "views/RequestsReports";
import ShowReportRequest from "views/RequestsReports/ShowRequest";
import Announcements from "views/Announcements";
import AddAnnouncement from "views/Announcements/Add";
import EditAnnouncement from "views/Announcements/Edit";
import Ads from "views/Ads";
import AddAd from "views/Ads/Add";
import EditAd from "views/Ads/Edit";
import CountriesIndex from "views/countries/Index";
import AddCountry from "views/countries/AddCountry";
import EditCountry from "views/countries/EditCountry";
import BlogsIndex from "views/blogs/BlogsIndex";
import BlogsCreate from "views/blogs/BlogsCreate";
import BlogsShow from "views/blogs/BlogsShow";
import BlogCategoriesIndex from "views/blogs/categories/BlogCategoriesIndex";
import BlogCategoriesCreate from "views/blogs/categories/BlogCategoriesCreate";
import BlogCategoriesShow from "views/blogs/categories/BlogCategoriesShow";

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  //selectors
  const { isLoggedIn } = useSelector((state) => state?.authentication);
  useEffect(() => {
    if (token) dispatch(getCurrentUser());
  }, [token, dispatch]);

  const RenderApp = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />

              {/* pages */}
              <Route
                path="/pages"
                element={
                  <AuthRoute>
                    <Pages />
                  </AuthRoute>
                }
              />

              <Route
                path="/pages/addPage"
                element={
                  <AuthRoute>
                    <AddPage />
                  </AuthRoute>
                }
              />

              <Route
                path="/pages/:id"
                element={
                  <AuthRoute>
                    <EditPage />
                  </AuthRoute>
                }
              />

              {/* addressTypes */}
              <Route
                path="/addressTypes"
                element={
                  <AuthRoute>
                    <AddressTypes />
                  </AuthRoute>
                }
              />

              <Route
                path="/addressTypes/addAddressType"
                element={
                  <AuthRoute>
                    <AddAddressType />
                  </AuthRoute>
                }
              />

              <Route
                path="/addressTypes/:id"
                element={
                  <AuthRoute>
                    <EditAddressType />
                  </AuthRoute>
                }
              />

              {/* categories */}
              <Route
                path="/categories"
                element={
                  <AuthRoute>
                    <Categories />
                  </AuthRoute>
                }
              />

              <Route
                path="/categories/addCategory"
                element={
                  <AuthRoute>
                    <AddCategory />
                  </AuthRoute>
                }
              />

              <Route
                path="/categories/:id"
                element={
                  <AuthRoute>
                    <EditCategory />
                  </AuthRoute>
                }
              />

              {/* skills */}
              <Route
                path="/skills"
                element={
                  <AuthRoute>
                    <Skills />
                  </AuthRoute>
                }
              />

              <Route
                path="/skills/addSkill"
                element={
                  <AuthRoute>
                    <AddSkill />
                  </AuthRoute>
                }
              />

              <Route
                path="/skills/:id"
                element={
                  <AuthRoute>
                    <EditSkill />
                  </AuthRoute>
                }
              />

              {/* jobs */}
              <Route
                path="/jobs"
                element={
                  <AuthRoute>
                    <Jobs />
                  </AuthRoute>
                }
              />
              <Route
                path="/jobs/:id"
                element={
                  <AuthRoute>
                    <ShowJob />
                  </AuthRoute>
                }
              />

              {/* banks */}
              <Route
                path="/banks"
                element={
                  <AuthRoute>
                    <Banks />
                  </AuthRoute>
                }
              />

              <Route
                path="/banks/addBank"
                element={
                  <AuthRoute>
                    <AddBank />
                  </AuthRoute>
                }
              />

              <Route
                path="/banks/:id"
                element={
                  <AuthRoute>
                    <EditBank />
                  </AuthRoute>
                }
              />

              {/* bankAccounts */}
              <Route
                path="/bankAccounts"
                element={
                  <AuthRoute>
                    <BankAccounts />
                  </AuthRoute>
                }
              />

              {/* transactionRequests */}
              <Route
                path="/transactionRequests"
                element={
                  <AuthRoute>
                    <Requests />
                  </AuthRoute>
                }
              />
              <Route
                path="/transactionRequests/:id"
                element={
                  <AuthRoute>
                    <ShowRequest />
                  </AuthRoute>
                }
              />
              {/* ReportRequests */}
              <Route
                path="/ReportRequests"
                element={
                  <AuthRoute>
                    <ReportRequests />
                  </AuthRoute>
                }
              />
              <Route
                path="/ReportRequests/:id"
                element={
                  <AuthRoute>
                    <ShowReportRequest />
                  </AuthRoute>
                }
              />

              {/* users */}
              <Route
                path="/users"
                element={
                  <AuthRoute>
                    <Users />
                  </AuthRoute>
                }
              />
              <Route
                path="/users/addUser"
                element={
                  <AuthRoute>
                    <AddUser />
                  </AuthRoute>
                }
              />
              <Route
                path="/users/:id"
                element={
                  <AuthRoute>
                    <EditUser />
                  </AuthRoute>
                }
              />

              <Route
                path="/users/show/:id"
                element={
                  <AuthRoute>
                    <ShowUser />
                  </AuthRoute>
                }
              />

              <Route
                path="/users/show/:id/transactionRequests"
                element={
                  <AuthRoute>
                    <TransactionRequests />
                  </AuthRoute>
                }
              />

              <Route
                path="/users/show/:id/providerJobs"
                element={
                  <AuthRoute>
                    <ProviderJobs />
                  </AuthRoute>
                }
              />

              <Route
                path="/users/show/:id/employeeJobs"
                element={
                  <AuthRoute>
                    <EmployeeJobs />
                  </AuthRoute>
                }
              />

              {/* roles */}
              <Route
                path="/roles"
                element={
                  <AuthRoute>
                    <Roles />
                  </AuthRoute>
                }
              />

              <Route
                path="/roles/addRole"
                element={
                  <AuthRoute>
                    <AddRole />
                  </AuthRoute>
                }
              />

              <Route
                path="/roles/:id"
                element={
                  <AuthRoute>
                    <EditRole />
                  </AuthRoute>
                }
              />

              <Route
                path="/contactUs"
                element={
                  <AuthRoute>
                    <ContactUs />
                  </AuthRoute>
                }
              />
              {/* --------------------- */}
              <Route
                path="/ads"
                element={
                  <AuthRoute>
                    <Ads />
                  </AuthRoute>
                }
              />
              <Route
                path="/ads/add"
                element={
                  <AuthRoute>
                    <AddAd />
                  </AuthRoute>
                }
              />
              <Route
                path="/ads/:id"
                element={
                  <AuthRoute>
                    <EditAd />
                  </AuthRoute>
                }
              />
              {/* ---------------------- */}
              <Route path="/blogs/index" element={<BlogsIndex />} />
              <Route path="/blogs/create" element={<BlogsCreate />} />
              <Route path="/blogs/show/:blogID" element={<BlogsShow />} />

              <Route
                path="/blogCategories/index"
                element={<BlogCategoriesIndex />}
              />
              <Route
                path="/blogCategories/create"
                element={<BlogCategoriesCreate />}
              />
              <Route
                path="/blogCategories/show/:blogCategoryID"
                element={<BlogCategoriesShow />}
              />
              {/* ---------------------- */}

              <Route
                path="/countries"
                element={
                  <AuthRoute path="/countries">
                    <CountriesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/countries/addcountry"
                element={
                  <AuthRoute path="/countries/addcountry">
                    <AddCountry />
                  </AuthRoute>
                }
              />

              <Route
                path="/countries/:id"
                element={
                  <AuthRoute path="/countries/:id">
                    <EditCountry />
                  </AuthRoute>
                }
              />

              {/* -------------------------- */}
              <Route
                path="/announcements"
                element={
                  <AuthRoute>
                    <Announcements />
                  </AuthRoute>
                }
              />
              <Route
                path="/announcements/add"
                element={
                  <AuthRoute>
                    <AddAnnouncement />
                  </AuthRoute>
                }
              />
              <Route
                path="/announcements/:id"
                element={
                  <AuthRoute>
                    <EditAnnouncement />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings"
                element={
                  <AuthRoute>
                    <Settings />
                  </AuthRoute>
                }
              />

              {/* admins */}
              <Route
                path="/admins"
                element={
                  <AuthRoute>
                    <Admins />
                  </AuthRoute>
                }
              />
              <Route
                path="/admins/add"
                element={
                  <AuthRoute>
                    <AddAdmin />
                  </AuthRoute>
                }
              />
              <Route
                path="/admins/:id"
                element={
                  <AuthRoute>
                    <EditAdmin />
                  </AuthRoute>
                }
              />

              <Route
                path="*"
                element={
                  <AuthRoute>
                    <NotFound />
                  </AuthRoute>
                }
              />
            </Route>

            {/*Auth Routes */}
            <Route
              path="/logout"
              element={
                <AuthRoute>
                  <Logout />
                </AuthRoute>
              }
            />
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <Login />
                </GuestRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  };

  if (!token) return RenderApp();
  if (isLoggedIn !== null) return RenderApp();
  return <Loader />;
};

export default App;
