import server from "./server";

export const getAllJobsApi = async (searchParams) => {
  const response = await server().get(`/jobs/dashboard/?${searchParams}`);

  return response.data;
};

export const getAllUserEmployeeJobsApi = async ({ id, searchParams }) => {
  const response = await server().get(
    `/jobs/employeeJobsDashBoard/${id}?${searchParams}`
  );

  return response.data;
};

export const getAllUserProviderJobsApi = async ({ id, searchParams }) => {
  const response = await server().get(
    `/jobs/providerJobsDashBoard/${id}?${searchParams}`
  );

  return response.data;
};

// getJobApi
export const getJobApi = async (id) => {
  const response = await server().get(`/jobs/${id}`);
  return response.data;
};
export const deleteJobApi = async (id) => {
  const response = await server().delete(`/jobs/${id}`);
  return response.data;
};
