import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_ADS,
  EDIT_AD,
  DELETE_AD,
  GET_SINGLE_AD,
  ADD_AD,
} from "./actionTypes";
import {
  getAdsSuccess,
  getAdsFailure,
  editAdSuccess,
  editAdFailure,
  deleteAdSuccess,
  deleteAdFailure,
  getSingleAdSuccess,
  getSingleAdFailure,
  addAdSuccess,
  addAdFailure,
} from "./actions";
import {
  addAdApi,
  deleteAdApi,
  editAdApi,
  getAdsApi,
  getSingleAdApi,
} from "api/ads";

function* getAdsSaga() {
  try {
    const { data } = yield call(getAdsApi);

    yield put(getAdsSuccess(data));
  } catch (error) {
    yield put(getAdsFailure(error?.response?.data || error));
  }
}

// -----------------------------------

function* addAdSaga({ payload }) {
  try {
    const { data } = yield call(addAdApi, payload?.data);

    yield put(addAdSuccess(data));
    if (payload?.navigate) payload.navigate("/ads");
  } catch (error) {
    yield put(addAdFailure(error?.response?.data || error));
  }
}

// -----------------------------------

function* getSingleAdSaga({ payload }) {
  try {
    const { data } = yield call(getSingleAdApi, payload);

    yield put(getSingleAdSuccess(data));
  } catch (error) {
    yield put(getSingleAdFailure(error?.response?.data || error));
  }
}

function* editAdSaga({ payload }) {
  try {
    const { data } = yield call(editAdApi, payload);

    yield put(editAdSuccess(data));

    if (payload?.navigate) yield payload?.navigate("/ads");
  } catch (error) {
    yield put(editAdFailure(error?.response?.data || error));
  }
}

function* deleteAdSaga({ payload }) {
  try {
    yield call(deleteAdApi, payload);

    yield put(deleteAdSuccess(payload));
  } catch (error) {
    yield put(deleteAdFailure(error?.response?.data || error));
  }
}

export function* watchGetAds() {
  yield takeEvery(GET_ADS, getAdsSaga);
}

export function* watchAddAd() {
  yield takeEvery(ADD_AD, addAdSaga);
}

export function* watchGetSingleAd() {
  yield takeEvery(GET_SINGLE_AD, getSingleAdSaga);
}

export function* watchEditAd() {
  yield takeEvery(EDIT_AD, editAdSaga);
}

export function* watchDeleteAd() {
  yield takeEvery(DELETE_AD, deleteAdSaga);
}

function* adsSaga() {
  yield all([fork(watchGetAds)]);
  yield all([fork(watchAddAd)]);
  yield all([fork(watchGetSingleAd)]);
  yield all([fork(watchEditAd)]);
  yield all([fork(watchDeleteAd)]);
}

export default adsSaga;
