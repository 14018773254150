import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";
import Loader from "components/shared/Loader";
import { FormattedMessage } from "react-intl";

const Table = (props) => {
  // const { locale } = useIntl();

  const columns = [

    {
      name: <FormattedMessage id={"userName"} />,
      selector: (row) => row?.userName,
      sortable: false,
    },
    {
      name: <FormattedMessage id={"bankName"} />,
      selector: (row) => row?.bank?.name,
      sortable: false,
    },
    {
      name: <FormattedMessage id={"bankNumber"} />,
      selector: (row) => row?.bankNumber,
      sortable: false,
    },
    {
      name: <FormattedMessage id={"iban"} />,
      selector: (row) => row?.iban,
      sortable: false,
    },
    {
      name: <FormattedMessage id={"swift"} />,
      selector: (row) => row?.swift,
      sortable: false,
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4><FormattedMessage id={"BankAccountsTableShow"} /> </h4>
                <p> <FormattedMessage id={"BankAccountsTableShowAll"} /></p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={props?.data}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
