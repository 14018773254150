import {
  ADD_ADDRESS_TYPE,
  ADD_ADDRESS_TYPE_FAILURE,
  ADD_ADDRESS_TYPE_SUCCESS,

  DELETE_ADDRESS_TYPE,
  DELETE_ADDRESS_TYPE_FAILURE,
  DELETE_ADDRESS_TYPE_SUCCESS,

  EDIT_ADDRESS_TYPE,
  EDIT_ADDRESS_TYPE_FAILURE,
  EDIT_ADDRESS_TYPE_SUCCESS,

  GET_ALL_ADDRESS_TYPES,
  GET_ALL_ADDRESS_TYPES_FAILURE,
  GET_ALL_ADDRESS_TYPES_SUCCESS,

  GET_ADDRESS_TYPE,
  GET_ADDRESS_TYPE_FAILURE,
  GET_ADDRESS_TYPE_SUCCESS
} from "./actionTypes";


export const getAllAddressTypes = () => {

  return {
    type: GET_ALL_ADDRESS_TYPES,
    // payload: payload,
  };
}
export const getAllAddressTypesSuccess = (payload) => {
  return {
    type: GET_ALL_ADDRESS_TYPES_SUCCESS,
    payload: payload,
  };
}

export const getAllAddressTypesFailure = (error) => {
  return {
    type: GET_ALL_ADDRESS_TYPES_FAILURE,
    payload: error,
  };
}

export const addAddressType = (payload) => {
  return {
    type: ADD_ADDRESS_TYPE,
    payload: payload,
  };
}
export const addAddressTypeSuccess = (payload) => {
  return {
    type: ADD_ADDRESS_TYPE_SUCCESS,
    payload: payload,
  };
}

export const addAddressTypeFailure = (error) => {
  return {
    type: ADD_ADDRESS_TYPE_FAILURE,
    payload: error,
  };
}

export const getAddressType = (payload) => {
  return {
    type: GET_ADDRESS_TYPE,
    payload: payload,
  };
}
export const getAddressTypeSuccess = (payload) => {
  return {
    type: GET_ADDRESS_TYPE_SUCCESS,
    payload: payload,
  };
}

export const getAddressTypeFailure = (error) => {
  return {
    type: GET_ADDRESS_TYPE_FAILURE,
    payload: error,
  };
}

export const editAddressType = (payload) => {
  return {
    type: EDIT_ADDRESS_TYPE,
    payload: payload,
  };
}
export const editAddressTypeSuccess = (payload) => {
  return {
    type: EDIT_ADDRESS_TYPE_SUCCESS,
    payload: payload,
  };
}

export const editAddressTypeFailure = (error) => {
  return {
    type: EDIT_ADDRESS_TYPE_FAILURE,
    payload: error,
  };
}

export const deleteAddressType = (payload) => {
  return {
    type: DELETE_ADDRESS_TYPE,
    payload: payload,
  };
}

export const deleteAddressTypeSuccess = (payload) => {
  return {
    type: DELETE_ADDRESS_TYPE_SUCCESS,
    payload: payload,
  };
}

export const deleteAddressTypeFailure = (error) => {
  return {
    type: DELETE_ADDRESS_TYPE_FAILURE,
    payload: error,
  };
}