import {
  ADD_BANK_ACCOUNT, ADD_BANK_ACCOUNT_FAILURE, ADD_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT, DELETE_BANK_ACCOUNT_FAILURE, DELETE_BANK_ACCOUNT_SUCCESS,
  EDIT_BANK_ACCOUNT, EDIT_BANK_ACCOUNT_FAILURE, EDIT_BANK_ACCOUNT_SUCCESS,
  GET_ALL_BANK_ACCOUNTS, GET_ALL_BANK_ACCOUNTS_FAILURE, GET_ALL_BANK_ACCOUNTS_SUCCESS,
  GET_BANK_ACCOUNT, GET_BANK_ACCOUNT_FAILURE, GET_BANK_ACCOUNT_SUCCESS
} from "./actionTypes";



const initialState = {
  bankAccounts: [],
  loading: false,
  error: "",
  singleBankAccount: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all bankAccounts  
    case GET_ALL_BANK_ACCOUNTS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_BANK_ACCOUNTS_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        bankAccounts: action.payload.bankAccounts,
      };
      break;

    case GET_ALL_BANK_ACCOUNTS_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add bankAccount  
    case ADD_BANK_ACCOUNT:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_BANK_ACCOUNT_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleBankAccount: action.payload.bankAccount,
      };
      break;

    case ADD_BANK_ACCOUNT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get bankAccount  
    case GET_BANK_ACCOUNT:
      state = {
        ...state,
        error: "",
        loading: true,
        singleBankAccount: {}
      };
      break;

    case GET_BANK_ACCOUNT_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleBankAccount: action.payload.bankAccount,
      };
      break;

    case GET_BANK_ACCOUNT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit bankAccount  
    case EDIT_BANK_ACCOUNT:
      state = {
        ...state,
        error: "",
        loading: true,
        singleBankAccount: {}
      };
      break;

    case EDIT_BANK_ACCOUNT_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleBankAccount: action.payload.bankAccount,
      };
      break;

    case EDIT_BANK_ACCOUNT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete bankAccount  
    case DELETE_BANK_ACCOUNT:
      state = {
        ...state,
        error: "",
        loading: true,
        singleBankAccount: {}
      };
      break;

    case DELETE_BANK_ACCOUNT_SUCCESS:
      const bankAccountsAfterDeleteing = [
        ...state?.bankAccounts?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        bankAccounts: bankAccountsAfterDeleteing,
      };
      break;

    case DELETE_BANK_ACCOUNT_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;



    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
