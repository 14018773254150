import { all } from "redux-saga/effects";

//public
import AuthenticationSaga from "./authentication/saga";
import categoriesSaga from "./categories/saga";
import addressTypesSaga from "./addressTypes/saga";
import skillsSaga from "./skills/saga";
import banksSaga from "./banks/saga";
import settingsSaga from "./settings/saga";
import pagesSaga from "./pages/saga";
import usersSaga from "./users/saga";
import rolesSaga from "./roles/saga";
import permissionsSaga from "./permissions/saga";
import transactionRequestsSaga from "./transactionRequests/saga";
import jobsSaga from "./jobs/saga";
import bankAccountsSaga from "./bankAccounts/saga";
import contactsSaga from "./contacts/saga";
import adminsSaga from "./admins/saga";
import requestsReportsSaga from "./requestsReports/saga";
import announcementSaga from "./announcements/saga";
import adsSaga from "./ads/saga";
import countriesSaga from "./countries/saga";

export default function* rootSaga() {
  yield all([
    AuthenticationSaga(),
    categoriesSaga(),
    addressTypesSaga(),
    skillsSaga(),
    banksSaga(),
    settingsSaga(),
    pagesSaga(),
    usersSaga(),
    rolesSaga(),
    permissionsSaga(),
    transactionRequestsSaga(),
    jobsSaga(),
    bankAccountsSaga(),
    contactsSaga(),
    adminsSaga(),
    requestsReportsSaga(),
    announcementSaga(),
    adsSaga(),
    countriesSaga(),
  ]);
}
