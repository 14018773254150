import {
  ADD_CATEGORY, ADD_CATEGORY_FAILURE, ADD_CATEGORY_SUCCESS,
  DELETE_CATEGORY, DELETE_CATEGORY_FAILURE, DELETE_CATEGORY_SUCCESS,
  EDIT_CATEGORY, EDIT_CATEGORY_FAILURE, EDIT_CATEGORY_SUCCESS,
  GET_ALL_CATEGORIES, GET_ALL_CATEGORIES_FAILURE, GET_ALL_CATEGORIES_SUCCESS,
  GET_CATEGORY, GET_CATEGORY_FAILURE, GET_CATEGORY_SUCCESS
} from "./actionTypes";



const initialState = {
  categories: [],
  loading: false,
  error: "",
  singleCategory: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all categories  
    case GET_ALL_CATEGORIES:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_CATEGORIES_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        categories: action.payload.categories,
      };
      break;

    case GET_ALL_CATEGORIES_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add category  
    case ADD_CATEGORY:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_CATEGORY_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleCategory: action.payload.category,
      };
      break;

    case ADD_CATEGORY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;


    // get category  
    case GET_CATEGORY:
      state = {
        ...state,
        error: "",
        loading: true,
        singleCategory: {}
      };
      break;

    case GET_CATEGORY_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleCategory: action.payload.category,
      };
      break;

    case GET_CATEGORY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit category  
    case EDIT_CATEGORY:
      state = {
        ...state,
        error: "",
        loading: true,
        singleCategory: {}
      };
      break;

    case EDIT_CATEGORY_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleCategory: action.payload.category,
      };
      break;

    case EDIT_CATEGORY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete category  
    case DELETE_CATEGORY:
      state = {
        ...state,
        error: "",
        loading: true,
        singleCategory: {}
      };
      break;

    case DELETE_CATEGORY_SUCCESS:
      const categoriesAfterDeleteing = [
        ...state?.categories?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        categories: categoriesAfterDeleteing,
      };
      break;

    case DELETE_CATEGORY_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;



    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
