import server from "./server";

export const getAllTransactionRequestsApi = async (searchParams) => {
  const response = await server().get(
    `/userTransactionRequests/dashboard/?${searchParams}`
  );

  return response.data;
};

export const getTransactionRequestApi = async (id) => {
  const response = await server().get(`/userTransactionRequests/${id}`);
  return response.data;
};

export const deleteTransactionRequestApi = async (id) => {
  const response = await server().delete(`/userTransactionRequests/${id}`);
  return response.data;
};

export const approveTransactionRequestApi = async ({ id }) => {
  const response = await server().post(
    `/userTransactionRequests/approve/${id}`
  );

  return response.data;
};

export const approveAllTransactionsRequestApi = async () => {
  const response = await server().post(`/userTransactionRequests/approveAll/`);

  return response.data;
};

export const rejectTransactionRequestApi = async (id) => {
  const response = await server().post(`/userTransactionRequests/reject/${id}`);

  return response.data;
};

export const getAllUserTransactionRequestsApi = async (id) => {
  const response = await server().get(
    `/userTransactionRequests/dashboard/${id}`
  );

  return response.data;
};
