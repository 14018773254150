import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editCountry, getSingleCountry } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import { FormattedMessage } from "react-intl";
import ReactSelect from "react-select";
import countries from "helpers/countriesData.json";

const EditCountry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { singleCountry, loading } = useSelector((state) => state?.countries);

  const { register, handleSubmit, reset, control } = useForm();

  useEffect(() => {
    dispatch(getSingleCountry(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      ...singleCountry,
    });
  }, [reset, singleCountry]);

  const onSubmit = (data) => {
    dispatch(editCountry({ id, data, navigate }));
  };
  // {
  //   "_id": "6680030b526b5c19ced1cf05",

  //   "online": true,
  //   "portfolioItems": [],
  //   "createdAt": "2024-06-29T12:50:19.377Z",
  //   "updatedAt": "2024-06-29T12:53:00.648Z",
  //   "idImage": null,
  //   "id": "6680030b526b5c19ced1cf05",
  //   "completedJobs": [],
  //   "rehiringJobs": [],
  //   "clientReviews": [],
  //   "transactionRequests": [],
  //   "bankAccounts": [],
  //   "rehiringjobSize": 0,
  //   "totalNumberOfjobs": 0,
  //   "totalNumberOfRehiring": 0
  // }
  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل بيانات الدولة</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <div>
                        <h5>
                          <FormattedMessage id="supportedCountries" />
                        </h5>
                        <div>
                          <Controller
                            control={control}
                            render={({
                              field: { onChange, value, name, ref, selected },
                            }) => (
                              <ReactSelect
                                inputRef={ref}
                                name={name}
                                placeholder={<FormattedMessage id={"role"} />}
                                options={countries}
                                onChange={(selectedOptions) => {
                                  onChange(selectedOptions?.value);
                                }}
                                value={countries?.find(
                                  (country) => value === country?.value
                                )}
                                // isMulti
                                isRtl={true}
                                isSearchable={false}
                                className="basic-single"
                                classNamePrefix="select"
                              />
                            )}
                            name={"countryCode"}
                            rules={{ required: true }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Row />
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCountry;
