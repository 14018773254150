import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  approveTransactionRequest,
  getReportRequest,
  rejectTransactionRequest,
} from "store/actions";
import Loader from "components/shared/Loader";

const ShowReportRequest = () => {
  // const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, singleTransactionRequest } = useSelector(
    (state) => state.transactionRequests
  );

  // effects
  useEffect(() => {
    dispatch(getReportRequest(id));
  }, [dispatch, id]);

  // const handleRejectTransactionRequest = (id) => {
  //   dispatch(rejectTransactionRequest(id));
  // };

  // const handleApproveTransactionRequest = (id) => {
  //   dispatch(approveTransactionRequest(id));
  // };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"showTransactionRequest"} />
            </h4>
          </div>
          <div className="card-body table-details--">
            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"requestNo"} />
                    </td>
                    <td>{singleTransactionRequest?.id} </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"userName"} />
                    </td>
                    <td>
                      <Link
                        to={`/users/show/${singleTransactionRequest?.user?.id}`}
                      >{`${singleTransactionRequest?.user?.firstName} ${singleTransactionRequest?.user?.lastName}`}</Link>{" "}
                    </td>
                  </tr>
                  {singleTransactionRequest?.bankAccount && (
                    <tr>
                      <td>
                        {" "}
                        <FormattedMessage id={"bankAccount"} />
                      </td>
                      <td>
                        <Link
                          to={`/bankAccounts/${singleTransactionRequest?.bankAccount?.id}`}
                        >{`${singleTransactionRequest?.bankAccount?.userName}`}</Link>{" "}
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"createdAtDate"} />
                    </td>
                    <td>
                      {new Date(
                        singleTransactionRequest?.createdAt
                      ).toLocaleString("en")}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"amount"} />
                    </td>

                    <td>
                      {singleTransactionRequest?.amount}{" "}
                      <FormattedMessage id={"sar"} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FormattedMessage id={"status"} />
                    </td>

                    <td>
                      <div className="request-status">
                        <span className="text_">
                          {" "}
                          {singleTransactionRequest?.status}
                        </span>

                        {/* {singleTransactionRequest?.status === "pending" && (
                          <div className="request-buttons">
                            <button
                              className="btn btn-green request-btn"
                              onClick={() =>
                                handleApproveTransactionRequest(
                                  singleTransactionRequest?.id
                                )
                              }
                            >
                              <FormattedMessage id={"approve"} />
                            </button>
                            <button
                              className="btn btn-red request-btn"
                              onClick={() =>
                                handleRejectTransactionRequest(
                                  singleTransactionRequest?.id
                                )
                              }
                            >
                              <FormattedMessage id={"reject"} />
                            </button>
                          </div>
                        )} */}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowReportRequest;
