export const GET_ADS = "GET_ADS";
export const GET_ADS_FAILURE = "GET_ADS_FAILURE";
export const GET_ADS_SUCCESS = "GET_ADS_SUCCESS";

export const GET_SINGLE_AD = "GET_SINGLE_AD";
export const GET_SINGLE_AD_FAILURE = "GET_SINGLE_AD_FAILURE";
export const GET_SINGLE_AD_SUCCESS = "GET_SINGLE_AD_SUCCESS";

export const ADD_AD = "ADD_AD";
export const ADD_AD_FAILURE = "ADD_AD_FAILURE";
export const ADD_AD_SUCCESS = "ADD_AD_SUCCESS";

export const EDIT_AD = "EDIT_AD";
export const EDIT_AD_FAILURE = "EDIT_AD_FAILURE";
export const EDIT_AD_SUCCESS = "EDIT_AD_SUCCESS";

export const DELETE_AD = "DELETE_AD";
export const DELETE_AD_FAILURE = "DELETE_AD_FAILURE";
export const DELETE_AD_SUCCESS = "DELETE_AD_SUCCESS";
