import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_CONTACTS,
  DELETE_CONTACTS
} from "./actionTypes";

import { getAllcontactsApi, deleteContactApi } from "api/contacts";

import {
  getAllContactsSuccess,
  getAllContactsFailure,
  deleteContactSuccess,
  deleteContactFailure

} from "./actions"
import { toast } from "react-toastify";

// dispatch actions 
function* getAllContacts({ payload }) {
  try {
    const { data: contacts } = yield call(getAllcontactsApi, payload);

    yield put(getAllContactsSuccess(contacts));
  } catch (error) {
    yield put(getAllContactsFailure(error));
  }
}

function* deleteContact({ payload }) {
  try {
    yield call(deleteContactApi, payload);
    yield put(deleteContactSuccess(payload));

    toast.success("تم حذف العنصر", {
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  } catch (error) {
    yield put(deleteContactFailure(error));
  }
}


// watchers
export function* watchGetALLContacts() {
  yield takeEvery(GET_ALL_CONTACTS, getAllContacts);
}

export function* watchDeleteContact() {
  yield takeEvery(DELETE_CONTACTS, deleteContact);
}


function* contactsSaga() {
  yield all([fork(watchGetALLContacts)]);
  yield all([fork(watchDeleteContact)]);
}

export default contactsSaga;
