import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { addAnnouncement, getAnnouncements } from "store/actions";
import Loader from "components/shared/Loader";
import AddButton from "components/shared/AddButton";
import { Input, Textarea } from "components/shared/FormComponents";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import ImageUpload from "components/shared/ImageUpload";
import { handleImage } from "helpers/functions";

const AddAnnouncement = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { announcements, loading, error } = useSelector(
    (state) => state.announcements
  );

  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });
  // const [transparent, setTransparent] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { color: "#00000000" } });

  useEffect(() => {
    if (announcements?.length === 0) dispatch(getAnnouncements());
  }, [announcements?.length, dispatch]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, navigate]);

  const onSubmit = (data) => {
    // if (transparent) data.color = "";

    // if (!selectedImage?.path && !data.image) {
    //   toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
    //   return;
    // }

    data.image = `/uploads/${(selectedImage?.path || data.image)
      ?.split("/")
      .at(-1)}`;

    dispatch(addAnnouncement({ data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"addAnnouncement"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <Row>
                        <Col md="3" xs="12">
                          <h5>
                            <FormattedMessage id="icon" />
                          </h5>
                          <ImageUpload
                            selectedImage={selectedImage}
                            setSelectedImage={setSelectedImage}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Col md={6} xs={12}>
                  <div className="form-group">
                    <div className="input-head-wrapper">
                      <h5>
                        <FormattedMessage id="type" />
                      </h5>
                    </div>
                    <div>
                      <select
                        className="form-control form-outline"
                        {...register("type")}
                      >
                        <option value="normal">
                          {formatMessage({ id: "normal" })}
                        </option>
                        <option value="rate">
                          {formatMessage({ id: "rateUs" })}
                        </option>
                      </select>
                    </div>
                    <p className="error-hint">
                      {errors?.payloadAction?.type === "required" && (
                        <FormattedMessage id="requiredField" />
                      )}
                    </p>
                  </div>
                </Col>
                {/* <Row> */}
                <Col md={6} xs={12}>
                  <Input
                    langId="title"
                    // name="title.ar"
                    name="title"
                    register={register}
                    errors={errors}
                  />
                </Col>
                {/* <Col md={6} xs={12}>
                    <Input
                      langId="title"
                      name="title.en"
                      register={register}
                      errors={errors}
                    />
                  </Col> */}
                {/* </Row> */}
                {/* <Row> */}
                <Col md={6} xs={12}>
                  <Textarea
                    langId="description"
                    // name="description.ar"
                    name="description"
                    register={register}
                    errors={errors}
                  />
                </Col>
                {/* <Col md={6} xs={12}>
                    <Textarea
                      langId="description"
                      name="description.en"
                      register={register}
                      errors={errors}
                    />
                  </Col> */}
                {/* </Row> */}
                {/* <Row>
                  <Col md={6} xs={12}>
                    <label className="d-flex gap-2 form-group">
                      <input
                        type="checkbox"
                        langId="color"
                        name="color"
                        onChange={(e) => setTransparent(e.target.checked)}
                        defaultChecked={transparent}
                        value={transparent}
                        className=""
                      />
                      <h5>
                        <FormattedMessage id="transparent?" />
                      </h5>
                    </label>

                    {!transparent && (
                      <Input
                        type="color"
                        langId="color"
                        name="color"
                        register={register}
                        errors={errors}
                        rules={{ required: false }}
                        className=""
                        // colorformat="rgba" // not working
                      />
                    )}
                  </Col>
                </Row> */}

                <AddButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAnnouncement;
