import ControlArea from 'components/Layout/ControlArea';
import { checkPermissions } from 'helpers/authentication/checkPermissions';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllAddressTypes } from 'store/actions';
import Icon from "../../assets/svgs/addressType.svg";
import Table from './Table';

const Categories = () => {

  const { addressTypes, loading, error } = useSelector((state) => state.addressTypes);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();



  useEffect(() => {
    dispatch(getAllAddressTypes());
  }, [dispatch]);


  // error 
  useEffect(() => {
    if (error) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error, formatMessage, locale]);

  return (
    <>
      <div className="addressTypes-wrap">
        {checkPermissions(["addressTypes-store"]) && <ControlArea
          btnTxt={<FormattedMessage id={"addAddressType"} />}
          cardTxt={<FormattedMessage id={"addressTypes"} />}
          icon={Icon}
          url="/addressTypes/addAddressType"
        />}

        <Table data={addressTypes} loading={loading} />
      </div>


    </>
  )
}

export default Categories

