import {
  GET_ALL_CONTACTS,
  GET_ALL_CONTACTS_SUCCESS,
  GET_ALL_CONTACTS_FAILURE,

  DELETE_CONTACTS,
  DELETE_CONTACTS_SUCCESS,
  DELETE_CONTACTS_FAILURE,

} from "./actionTypes";


const initialState = {
  contacts: [],
  loading: false,
  error: "",
  singleContact: {},
  metadata: {}
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all contacts  
    case GET_ALL_CONTACTS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_ALL_CONTACTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        contacts: action.payload.contacts,
        metadata: action.payload.metadata
      };
      break;
    case GET_ALL_CONTACTS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete contact 
    case DELETE_CONTACTS:
      state = {
        ...state,
        loading: true
      }

      break;

    case DELETE_CONTACTS_SUCCESS:

      const contactsAfterDeleteing = [
        ...state?.contacts?.filter((contact) => contact?._id !== action.payload),
      ];
      state = {
        ...state,
        loading: false,
        contacts: contactsAfterDeleteing
      }
      break;

    case DELETE_CONTACTS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
