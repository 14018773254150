import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { addUser, getAllRoles, getAllSkills } from "store/actions";
import Loader from "components/shared/Loader";
import Select from "react-select";
import { genderSelectOptions, typeSelectOptions, levelSelectOptions, jobTypeSelectOptions } from "data";
import { getDateOfBirthWithFormat, getToday } from "helpers/functions";
import server from "api/server";
import imageUpload from "../../assets/svgs/imageUpload.svg";
import removeImg from "../../assets/svgs/close.svg";


const AddUser = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { skills } = useSelector((state) => state.skills);
  const { loading, error } = useSelector((state) => state.users);
  const [selectedImage, setSelectedImage] = useState({ preview: null, path: "" });
  const [selectedIdImage, setSelectedIdImage] = useState({ preview: null, path: "" });
  const { roles } = useSelector((state) => state.roles);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // effects
  useEffect(() => {
    dispatch(getAllSkills());
    dispatch(getAllRoles());
  }, [dispatch]);

  // error 
  useEffect(() => {

    if (error) {
      error?.errors.forEach(error => {
        toast.error(error?.msg, {
          position: locale === "ar" ? "bottom-left" : "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    }

  }, [error, formatMessage, locale, navigate]);


  //  image func
  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);

      setSelectedImage({
        preview: e.target.files[0],
        path: data.data.files[0].path,
      });

      e.target.value = null;
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage("");
  };

  // id image func
  const idImageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);

      setSelectedIdImage({
        preview: e.target.files[0],
        path: data.data.files[0].path,
      });

      e.target.value = null;
    }
  };
  const removeSelectedIdImage = () => {
    setSelectedIdImage("");
  };


  const onSubmit = (data) => {
    if (!selectedImage?.path || !selectedIdImage?.path) {
      toast.error(formatMessage({ id: "imageErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }

    data.image = `uploads/${selectedImage?.path?.split("/").at(-1)}`;
    data.idImage = `uploads/${selectedIdImage?.path?.split("/").at(-1)}`;
    // data.deviceId = "deviceId";
    // data.deviceType = "android";
    // data.fcmToken = "feHjrkNeU0WuvvMKFDBVr2:APA91bEs9hh9EbOLepdot-BwrJit0SAQ5FZfkgx0Z0gmfV1sWCi4gP2a5IZSYIpGNkWeIQxvA4hchbfkHqw2BoLQaa8_R3edFZYXAoT6n-nI1SaMcJniFQu8XQ3YrNJhsjHUHcs6yxrmz";
    data.deviceId = null;
    data.deviceType = null;
    data.fcmToken = null;

    data.dateOfBirth = getDateOfBirthWithFormat(data.dateOfBirth);

    if (data.skills) {
      data.skills = data.skills.map(skill => skill.value)
    }

    dispatch(addUser({ data, navigate }));
  };



  const handleSelectOptions = (options) => {

    if (options.length < 0) return []

    return options.map((option) => ({
      value: option?.id,
      label: option?.name
    }
    ));
  }



  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"addUser"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>

                  {/* firstName  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"firstName"} />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "firstName" })}
                          {...register("firstName", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.firstName?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  {/* lastName  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"lastName"} />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "lastName" })}
                          {...register("lastName", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.lastName?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  {/* phone  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"phone"} />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "phone" })}
                          {...register("phone", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.phone?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  {/* email  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"email"} />
                      </h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "email" })}
                          {...register("email", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.email?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  {/* password  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"password"} />
                      </h5>
                      <div>
                        <input
                          type="password"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "password" })}
                          {...register("password", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.password?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  {/* gender  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"gender"} /> </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({ field: { onChange, value, name, ref, selected } }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              placeholder={<FormattedMessage id={"gender"} />}
                              options={genderSelectOptions}
                              onChange={(selected) => {
                                onChange(selected?.value);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"

                            />
                          )}
                          name={"gender"}
                          rules={{ required: true }}
                        />

                      </div>
                      <p className="error-hint">
                        {errors?.gender &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  {/*  skills  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"skills"} /> </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({ field: { onChange, value, name, ref, selected } }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              placeholder={<FormattedMessage id={"skills"} />}
                              options={handleSelectOptions(skills)}
                              onChange={(selectedOption) => {
                                onChange(selectedOption);
                              }}
                              isRtl={true}
                              isMulti
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"

                            />
                          )}
                          name={"skills"}
                          rules={{ required: true }}
                        />



                      </div>
                      <p className="error-hint">
                        {errors?.skills &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  {/* type  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"accountType"} /> </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({ field: { onChange, value, name, ref, selected } }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              placeholder={<FormattedMessage id={"accountType"} />}
                              options={typeSelectOptions}
                              onChange={(selected) => {
                                onChange(selected?.value);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"

                            />
                          )}
                          name={"type"}
                          rules={{ required: true }}
                        />

                      </div>
                      <p className="error-hint">
                        {errors?.type &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  {/* jobType  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"jobType"} /> </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({ field: { onChange, value, name, ref, selected } }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              placeholder={<FormattedMessage id={"jobType"} />}
                              options={jobTypeSelectOptions}
                              onChange={(selected) => {
                                onChange(selected?.value);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"

                            />
                          )}
                          name={"jobType"}
                          rules={{ required: true }}
                        />

                      </div>
                      <p className="error-hint">
                        {errors?.jobType &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  {/* commercialRegistrationNo  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id={"commercialRegistrationNo"} />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "commercialRegistrationNo" })}
                          {...register("commercialRegistrationNo")}
                        />
                      </div>
                    </div>
                  </Col>

                  {/* role  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5><FormattedMessage id={"role"} /> </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({ field: { onChange, value, name, ref, selected } }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              placeholder={<FormattedMessage id={"role"} />}
                              options={handleSelectOptions(roles)}
                              onChange={(selectedOption) => {
                                onChange(selectedOption?.value);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"

                            />
                          )}
                          name={"role"}
                        />
                      </div>
                    </div>
                  </Col>

                  {/* level  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"level"} /> </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({ field: { onChange, value, name, ref, selected } }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              placeholder={<FormattedMessage id={"level"} />}
                              options={levelSelectOptions}
                              onChange={(selected) => {
                                onChange(selected?.value);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"

                            />
                          )}
                          name={"level"}
                          rules={{ required: true }}
                        />

                      </div>
                      <p className="error-hint">
                        {errors?.level &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  {/* dateOfBirth  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"dateOfBirth"} /> </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({ field: { onChange, value, name, ref, selected } }) => (
                            <Flatpickr
                              className="form-control form-outline"
                              placeholder={formatMessage({ id: "dateOfBirth" })}
                              onChange={(selected) => {
                                onChange(selected);
                              }}
                              options={{
                                enableTime: false,
                                dateFormat: "Y-m-d",
                                maxDate: getToday()
                              }}
                            />
                          )}
                          name={"dateOfBirth"}
                          rules={{ required: true }}
                        />


                      </div>
                      <p className="error-hint">
                        {errors?.dateOfBirth &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  {/* image  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"image"} /></h5>

                      <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img src={imageUpload} width={46} height={46} alt="" />
                        </div>
                        <label>
                          {(selectedImage?.preview || selectedImage?.path) && (
                            <div>
                              <img
                                src={
                                  selectedImage && selectedImage.preview
                                    ? URL.createObjectURL(selectedImage.preview)
                                    : selectedImage?.path ? selectedImage?.path : imageUpload
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button className="removeImg" onClick={removeSelectedImage}>
                                <img src={removeImg} alt="" />
                              </button>
                            </div>
                          )}
                          <input accept="image/*" type="file" onChange={imageChange} />
                        </label>
                      </div>
                    </div>

                  </Col>

                  {/*idImage  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"idImage"} /></h5>

                      <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img src={imageUpload} width={46} height={46} alt="" />
                        </div>
                        <label>
                          {(selectedIdImage?.preview || selectedIdImage?.path) && (
                            <div>
                              <img
                                src={
                                  selectedIdImage && selectedIdImage.preview
                                    ? URL.createObjectURL(selectedIdImage.preview)
                                    : selectedIdImage?.path ? selectedIdImage?.path : imageUpload
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button className="removeImg" onClick={removeSelectedIdImage}>
                                <img src={removeImg} alt="" />
                              </button>
                            </div>
                          )}
                          <input accept="image/*" type="file" onChange={idImageChange} />
                        </label>
                      </div>
                    </div>

                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"add"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
