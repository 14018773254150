import React, { useMemo, useState, forwardRef } from "react";
import DataTable from "react-data-table-component";
import { Badge, Col } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { Link } from "react-router-dom";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { checkPermissions } from "helpers/authentication/checkPermissions";
import { getId } from "helpers/functions";
import { useDispatch } from "react-redux";
import {
  approveAllTransactionsRequest,
  approveTransactionRequest,
  rejectTransactionRequest,
} from "store/actions";
import { toast } from "react-toastify";

const Table = (props) => {
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleRejectTransactionRequest = (id) => {
    dispatch(rejectTransactionRequest(id));
  };

  const handleApproveTransactionRequest = (id) => {
    dispatch(
      approveTransactionRequest({
        id,
        toast,
        locale,
      })
    );
  };

  const columns = [
    {
      name: <FormattedMessage id={"requestNo"} />,
      selector: (row) =>
        checkPermissions(["userTransactionRequests-show"]) ? (
          <Link to={`/transactionRequests/${row?.id}`}>{row?.id}</Link>
        ) : (
          row?.id
        ),
      sortable: true,
    },
    {
      name: <FormattedMessage id={"userName"} />,
      selector: (row) =>
        checkPermissions(["users-show"]) ? (
          <Link
            to={`/users/show/${row?.user.id}`}
          >{`${row?.user.firstName} ${row?.user.lastName}`}</Link>
        ) : (
          `${row?.user.firstName} ${row?.user.lastName}`
        ),
      sortable: true,
    },
    {
      name: <FormattedMessage id={"amount"} />,
      selector: (row) =>
        `${parseFloat(row?.amount).toFixed(2)} ${formatMessage({ id: "sar" })}`,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"status"} />,
      selector: (row) =>
        row?.status === "pending" ? (
          <Badge bg="warning">
            <FormattedMessage id={"pending"} />
          </Badge>
        ) : row?.status === "approved" ? (
          <Badge bg="success">
            <FormattedMessage id={"approved"} />
          </Badge>
        ) : (
          <Badge bg="danger">
            <FormattedMessage id={"rejected"} />
          </Badge>
        ),
      sortable: true,
    },
    {
      name: <FormattedMessage id={"createdAtDate"} />,
      selector: (row) => {
        return new Date(row?.createdAt).toLocaleString("en");
      },
      sortable: true,
    },
    {
      name: <FormattedMessage id={"actions"} />,
      selector: (row) => {
        if (row?.status === "pending")
          return (
            <div className="d-flex">
              <button
                title={formatMessage({ id: "approve" })}
                className="btn px-2 rounded-circle border-0 mx-1 btn-green"
                style={{
                  width: "35px",
                  height: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleApproveTransactionRequest(getId(row))}
                // disabled={row?.status != "pending"}
              >
                {/* <FormattedMessage id={"approve"} /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="black"
                  class="bi bi-check2-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg>
              </button>
              <button
                title={formatMessage({ id: "reject" })}
                className="btn px-2 rounded-circle border-0 mx-1 btn-red"
                style={{
                  width: "35px",
                  height: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleRejectTransactionRequest(getId(row))}
                // disabled={row?.status != "pending"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="white"
                  class="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
                {/* <FormattedMessage id={"reject"} /> */}
              </button>
            </div>
          );
      },
      sortable: true,
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id={"transactionRequestsTableShow"} />{" "}
                </h4>
                <p>
                  {" "}
                  <FormattedMessage id={"transactionRequestsTableShowAll"} />
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <div className="my-4">
              <button
                title={formatMessage({ id: "approveAll" })}
                className="btn px-2 rounded-2 border-0 mx-1 btn-green"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
                onClick={() =>
                  dispatch(
                    approveAllTransactionsRequest({
                      formatMessage,
                      toast,
                      locale,
                    })
                  )
                }
                // disabled={
                //   props?.data?.filter((e) => e?.status === "pending")
                //     ?.length === 0 || props?.loading
                // }
              >
                <FormattedMessage id={"approveAll"} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="white"
                  class="bi bi-check2-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg>
              </button>
            </div>
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
