import DataTable from "react-data-table-component";
import { Col, Row } from "react-bootstrap";
import Loader from "components/shared/Loader";
import { FormattedMessage } from "react-intl";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { handleSearchParamsChange } from "helpers/functions";
import { useState } from "react";

const JOBSTATUSES = ["finished", "pending", "approvalPending", "assigned"];

const Table = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedFilter, setSelectedFilter] = useState("All");

  const columns = [
    {
      name: <FormattedMessage id={"jobTitle"} />,
      selector: (row) => <Link to={`/jobs/${row?.id}`}>{row?.title}</Link>,
      sortable: true,
    },

    {
      name: <FormattedMessage id={"price"} />,
      selector: (row) => row?.price,
      sortable: true,
    },

    {
      name: <FormattedMessage id={"status"} />,
      selector: (row) => <FormattedMessage id={row?.status} />,
      sortable: false,
    },
    {
      name: <FormattedMessage id={"createdAtDate"} />,
      selector: (row) => {
        return new Date(row?.createdAt).toLocaleString("en");
      },
      sortable: true,
    },
  ];

  const handleStatusFilterClick = (e) => {
    e.preventDefault();
    const search = Object.fromEntries([...searchParams]);

    const params = {};
    if (e.target.getAttribute("href")) {
      params["status"] = e.target.getAttribute("href");
      setSelectedFilter(e.target.getAttribute("href"));
    } else {
      delete search?.status;
      setSelectedFilter("All");
    }
    const newSearch = handleSearchParamsChange(search, params);

    navigate(window.location.pathname + newSearch, { replace: true });
  };

  const renderJobsStatusFilter = () => {
    return (
      <Dropdown.Menu>
        <Dropdown.Item href="" onClick={handleStatusFilterClick}>
          <FormattedMessage id="All" />
        </Dropdown.Item>

        {JOBSTATUSES?.map((status) => {
          return (
            <Dropdown.Item
              key={status}
              href={status}
              onClick={handleStatusFilterClick}
            >
              <FormattedMessage id={status} />
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    );
  };

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <div className=" card-head-with-filter">
            <Row className="justify-content-between align-items-center">
              <Col xl={6} md={12} xs={12}>
                <div className="">
                  <div>
                    <h4>{props?.title} </h4>
                  </div>
                </div>
              </Col>

              <Col xl={4} md={6} xs={12}>
                {/* status filter  */}
                <div className="jobs-status-filter">
                  <span className="title__">
                    <FormattedMessage id={"statusFilter"} />
                  </span>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <FormattedMessage id={selectedFilter} />
                      <svg
                        width="11"
                        height="7"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.14376 6.13396L1.06422 2.05441C0.867468 1.85766 0.867468 1.53867 1.06422 1.34194L1.54003 0.866126C1.73645 0.66971 2.05478 0.669332 2.25166 0.865287L5.5 4.09841L8.74832 0.865287C8.9452 0.669332 9.26353 0.66971 9.45995 0.866126L9.93576 1.34194C10.1325 1.53869 10.1325 1.85768 9.93576 2.05441L5.85625 6.13396C5.65949 6.3307 5.34051 6.3307 5.14376 6.13396Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </Dropdown.Toggle>
                    {renderJobsStatusFilter()}
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div>

          <div className="card-body">
            <DataTable
              columns={columns}
              data={props?.data}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              subHeader
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
