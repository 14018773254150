export const GET_ALL_ADDRESS_TYPES = "GET_ALL_ADDRESS_TYPES";
export const GET_ALL_ADDRESS_TYPES_SUCCESS = "GET_ALL_ADDRESS_TYPES_SUCCESS";
export const GET_ALL_ADDRESS_TYPES_FAILURE = "GET_ALL_ADDRESS_TYPES_FAILURE";

export const GET_ADDRESS_TYPE = "GET_ADDRESS_TYPE";
export const GET_ADDRESS_TYPE_SUCCESS = "GET_ADDRESS_TYPE_SUCCESS";
export const GET_ADDRESS_TYPE_FAILURE = "GET_ADDRESS_TYPE_FAILURE";

export const ADD_ADDRESS_TYPE = "ADD_ADDRESS_TYPE";
export const ADD_ADDRESS_TYPE_SUCCESS = "ADD_ADDRESS_TYPE_SUCCESS";
export const ADD_ADDRESS_TYPE_FAILURE = "ADD_ADDRESS_TYPE_FAILURE";

export const EDIT_ADDRESS_TYPE = "EDIT_ADDRESS_TYPE";
export const EDIT_ADDRESS_TYPE_SUCCESS = "EDIT_ADDRESS_TYPE_SUCCESS";
export const EDIT_ADDRESS_TYPE_FAILURE = "EDIT_ADDRESS_TYPE_FAILURE";

export const DELETE_ADDRESS_TYPE = "DELETE_ADDRESS_TYPE";
export const DELETE_ADDRESS_TYPE_SUCCESS = "DELETE_ADDRESS_TYPE_SUCCESS";
export const DELETE_ADDRESS_TYPE_FAILURE = "DELETE_ADDRESS_TYPE_FAILURE";