import React, { useState } from "react";
import imageUpload from "assets/svgs/imageUpload.svg";
import removeImg from "assets/svgs/close.svg";
import server from "api/server";

const ImageUpload = ({ selectedImage, setSelectedImage, type = "image" }) => {
  const [loading, setLoading] = useState(false);
  const imageChange = async (e) => {
    setLoading(true);
    try {
      if (e.target.files && e.target.files.length > 0) {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        const { data } = await server().post("/upload", formData);

        setSelectedImage({
          preview: e.target.file,
          path: data.data.files[0].fullpath,
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const removeSelectedImage = () => {
    setSelectedImage("");
  };

  return (
    <div className="image-uploader image-contain">
      <div className="img-pat">
        <img src={imageUpload} width={46} height={46} alt="" />
      </div>
      <label>
        {loading && <h5 className="text-center">جاري الرفع...</h5>}
        {(selectedImage?.preview || selectedImage?.path) && (
          <div>
            {type === "image" ? (
              <img
                src={
                  selectedImage && selectedImage.preview
                    ? URL.createObjectURL(selectedImage.preview)
                    : selectedImage?.path
                    ? selectedImage?.path
                    : imageUpload
                }
                width={160}
                height={160}
                alt=""
              />
            ) : (
              <video
                controls
                src={
                  selectedImage && selectedImage.preview
                    ? URL.createObjectURL(selectedImage.preview)
                    : selectedImage?.path
                    ? selectedImage?.path
                    : imageUpload
                }
                className="mw-100 h-100"
                width={160}
                height={160}
                alt=""
              />
            )}
            <button className="removeImg" onClick={removeSelectedImage}>
              <img src={removeImg} alt="" />
            </button>
          </div>
        )}
        <input accept={`${type}/*`} type="file" onChange={imageChange} />
      </label>
    </div>
  );
};

export default ImageUpload;
