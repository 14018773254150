import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
  GET_ALL_USERS,
  GET_USER,
  PENALTY_USER,
} from "./actionTypes";
import {
  addUserApi,
  getAllUsersApi,
  getUserApi,
  deleteUserApi,
  editUserApi,
  penaltyUserApi,
} from "api/users";
import {
  addUserFailure,
  addUserSuccess,
  deleteUserFailure,
  deleteUserSuccess,
  editUserFailure,
  editUserSuccess,
  getAllUsersFailure,
  getAllUsersSuccess,
  getUserFailure,
  getUserSuccess,
  penaltyUserFailure,
  penaltyUserSuccess,
} from "./actions";

function* getAllUsers({ payload }) {
  try {
    const { data } = yield call(getAllUsersApi, payload);
    yield put(getAllUsersSuccess(data));
  } catch (error) {
    yield put(getAllUsersFailure(error?.response?.data || error));
  }
}

function* addUser({ payload }) {
  try {
    const { data } = yield call(addUserApi, payload.data);

    yield put(addUserSuccess(data));
    payload.navigate("/users");
  } catch (error) {
    yield put(addUserFailure(error?.response?.data || error));
  }
}

function* getUser({ payload }) {
  try {
    const { data } = yield call(getUserApi, payload);

    yield put(getUserSuccess(data));
  } catch (error) {
    yield put(getUserFailure(error?.response?.data || error));
  }
}

function* editUser({ payload }) {
  try {
    const { data } = yield call(editUserApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editUserSuccess(data));
  } catch (error) {
    yield put(editUserFailure(error?.response?.data || error));
  }
}

function* penaltyUser({ payload }) {
  try {
    const { data } = yield call(penaltyUserApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(penaltyUserSuccess(data));
  } catch (error) {
    yield put(penaltyUserFailure(error?.response?.data || error));
  }
}

function* deleteUser({ payload }) {
  try {
    yield call(deleteUserApi, payload);

    yield put(deleteUserSuccess(payload));
  } catch (error) {
    yield put(deleteUserFailure(error?.response?.data || error));
  }
}

export function* watchGetAllUsers() {
  yield takeEvery(GET_ALL_USERS, getAllUsers);
}

export function* watchAddUser() {
  yield takeEvery(ADD_USER, addUser);
}

export function* watchGetUser() {
  yield takeEvery(GET_USER, getUser);
}
export function* watchEditUser() {
  yield takeEvery(EDIT_USER, editUser);
}
export function* watchPenaltyUser() {
  yield takeEvery(PENALTY_USER, penaltyUser);
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

function* usersSaga() {
  yield all([
    fork(watchGetAllUsers),
    fork(watchAddUser),
    fork(watchGetUser),
    fork(watchEditUser),
    fork(watchPenaltyUser),
    fork(watchDeleteUser),
  ]);
}

export default usersSaga;
