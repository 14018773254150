import {
  ADD_ADDRESS_TYPE, ADD_ADDRESS_TYPE_FAILURE, ADD_ADDRESS_TYPE_SUCCESS,
  DELETE_ADDRESS_TYPE, DELETE_ADDRESS_TYPE_FAILURE, DELETE_ADDRESS_TYPE_SUCCESS,
  EDIT_ADDRESS_TYPE, EDIT_ADDRESS_TYPE_FAILURE, EDIT_ADDRESS_TYPE_SUCCESS,
  GET_ALL_ADDRESS_TYPES, GET_ALL_ADDRESS_TYPES_FAILURE, GET_ALL_ADDRESS_TYPES_SUCCESS,
  GET_ADDRESS_TYPE, GET_ADDRESS_TYPE_FAILURE, GET_ADDRESS_TYPE_SUCCESS
} from "./actionTypes";



const initialState = {
  addressTypes: [],
  loading: false,
  error: "",
  singleAddressType: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all addressTypes  
    case GET_ALL_ADDRESS_TYPES:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_ADDRESS_TYPES_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        addressTypes: action.payload.types,
      };
      break;

    case GET_ALL_ADDRESS_TYPES_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add addressType  
    case ADD_ADDRESS_TYPE:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_ADDRESS_TYPE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleAddressType: action.payload.addressType,
      };
      break;

    case ADD_ADDRESS_TYPE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;


    // get addressType  
    case GET_ADDRESS_TYPE:
      state = {
        ...state,
        error: "",
        loading: true,
        singleAddressType: {}
      };
      break;

    case GET_ADDRESS_TYPE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleAddressType: action.payload.addressType,
      };
      break;

    case GET_ADDRESS_TYPE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit addressType  
    case EDIT_ADDRESS_TYPE:
      state = {
        ...state,
        error: "",
        loading: true,
        singleAddressType: {}
      };
      break;

    case EDIT_ADDRESS_TYPE_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleAddressType: action.payload.addressType,
      };
      break;

    case EDIT_ADDRESS_TYPE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete addressType  
    case DELETE_ADDRESS_TYPE:
      state = {
        ...state,
        error: "",
        loading: true,
        singleAddressType: {}
      };
      break;

    case DELETE_ADDRESS_TYPE_SUCCESS:
      const addressTypesAfterDeleteing = [
        ...state?.addressTypes?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        addressTypes: addressTypesAfterDeleteing,
      };
      break;

    case DELETE_ADDRESS_TYPE_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
