import React, { useEffect } from "react";
import MainSettings from "./mainSettings";
import SocialsSettings from "./SocialsSettings";
import { getAllSettings } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "components/shared/Loader";
import { FormattedMessage } from "react-intl";

const Settings = () => {
  const dispatch = useDispatch();
  const { loading, error, mainSettings, socialSettings } = useSelector(
    (state) => state.settings
  );

  useEffect(() => {
    dispatch(getAllSettings());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(<FormattedMessage id={"sendingErrorMessage"} />, {
        position: "bottom-left",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [error]);

  if (loading) return <Loader />;

  return (
    <>
      <MainSettings
        settings={mainSettings}
        title={<FormattedMessage id={"mainSettings"} />}
      />
      <SocialsSettings
        settings={socialSettings}
        title={<FormattedMessage id={"socialSettings"} />}
      />
    </>
  );
};

export default Settings;
