import {
  ADD_PAGE,
  ADD_PAGE_FAILURE,
  ADD_PAGE_SUCCESS,

  DELETE_PAGE,

  DELETE_PAGE_FAILURE,

  DELETE_PAGE_SUCCESS,

  EDIT_PAGE,

  EDIT_PAGE_FAILURE,

  EDIT_PAGE_SUCCESS,

  GET_ALL_PAGES,
  GET_ALL_PAGES_FAILURE,
  GET_ALL_PAGES_SUCCESS,

  GET_PAGE,
  GET_PAGE_FAILURE,
  GET_PAGE_SUCCESS
} from "./actionTypes";


export const getAllPages = (payload) => {
  return {
    type: GET_ALL_PAGES,
    payload: payload,
  };
}
export const getAllPagesSuccess = (payload) => {

  return {
    type: GET_ALL_PAGES_SUCCESS,
    payload: payload,
  };
}

export const getAllPagesFailure = (error) => {
  return {
    type: GET_ALL_PAGES_FAILURE,
    payload: error,
  };
}

export const addPage = (payload) => {
  return {
    type: ADD_PAGE,
    payload: payload,
  };
}
export const addPageSuccess = (payload) => {
  return {
    type: ADD_PAGE_SUCCESS,
    payload: payload,
  };
}

export const addPageFailure = (error) => {
  return {
    type: ADD_PAGE_FAILURE,
    payload: error,
  };
}

export const getPage = (payload) => {
  return {
    type: GET_PAGE,
    payload: payload,
  };
}
export const getPageSuccess = (payload) => {
  return {
    type: GET_PAGE_SUCCESS,
    payload: payload,
  };
}

export const getPageFailure = (error) => {
  return {
    type: GET_PAGE_FAILURE,
    payload: error,
  };
}

export const editPage = (payload) => {
  return {
    type: EDIT_PAGE,
    payload: payload,
  };
}
export const editPageSuccess = (payload) => {
  return {
    type: EDIT_PAGE_SUCCESS,
    payload: payload,
  };
}

export const editPageFailure = (error) => {
  return {
    type: EDIT_PAGE_FAILURE,
    payload: error,
  };
}

export const deletePage = (payload) => {
  return {
    type: DELETE_PAGE,
    payload: payload,
  };
}

export const deletePageSuccess = (payload) => {
  return {
    type: DELETE_PAGE_SUCCESS,
    payload: payload,
  };
}

export const deletePageFailure = (error) => {
  return {
    type: DELETE_PAGE_FAILURE,
    payload: error,
  };
}