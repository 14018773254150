import React, { forwardRef } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { getId, handleSearchParamsChange } from "helpers/functions";
import { useState } from "react";
import { checkPermissions } from "helpers/authentication/checkPermissions";
import { ReactComponent as DotsIcon } from "assets/svgs/dots.svg";
import { ReactComponent as EditIcon } from "assets/svgs/pen.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/trash.svg";
import Swal from "sweetalert2";
import { deleteJob } from "store/actions";
import { useDispatch } from "react-redux";

const JOBSTATUSES = ["finished", "pending", "approvalPending", "assigned"];

const Table = (props) => {
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedFilter, setSelectedFilter] = useState("All");

  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const handleStatusFilterClick = (e) => {
    e.preventDefault();
    const search = Object.fromEntries([...searchParams]);

    const params = {};
    if (e.target.getAttribute("href")) {
      setSelectedFilter(e.target.getAttribute("href"));
      params["status"] = e.target.getAttribute("href");
    } else {
      setSelectedFilter("All");
      delete search?.status;
    }
    const newSearch = handleSearchParamsChange(search, params);

    navigate(window.location.pathname + newSearch, { replace: true });
  };

  const renderJobsStatusFilter = () => {
    return (
      <Dropdown.Menu>
        <Dropdown.Item href="" onClick={handleStatusFilterClick}>
          <FormattedMessage id="All" />
        </Dropdown.Item>

        {JOBSTATUSES?.map((status) => {
          return (
            <Dropdown.Item
              key={status}
              href={status}
              onClick={handleStatusFilterClick}
            >
              <FormattedMessage id={status} />
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    );
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: formatMessage({ id: "deleteJob" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: "نعم",
      cancelButtonText: "لا",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteJob(id));
      }
    });
  };

  const columns = [
    {
      name: <FormattedMessage id={"jobTitle"} />,
      selector: (row) =>
        checkPermissions(["jobs-show"]) ? (
          <Link to={`/jobs/${row?.id}`}>{row?.title}</Link>
        ) : (
          row?.title
        ),
      sortable: true,
    },

    {
      name: <FormattedMessage id={"price"} />,
      selector: (row) => `${row?.price} ${formatMessage({ id: "sar" })}`,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"status"} />,
      selector: (row) => row?.status,
      sortable: true,
    },

    {
      name: <FormattedMessage id={"provider"} />,
      selector: (row) =>
        checkPermissions(["users-show"]) ? (
          <Link
            to={`/users/show/${row?.provider._id}`}
          >{`${row?.provider?.firstName} ${row?.provider?.lastName}`}</Link>
        ) : (
          `${row?.provider.firstName} ${row?.provider.lastName}`
        ),
      sortable: true,
    },
    {
      name: <FormattedMessage id={"employee"} />,
      selector: (row) =>
        checkPermissions(["users-show"])
          ? row?.employee && (
              <Link
                to={`/users/show/${row?.employee?.id}`}
              >{`${row?.employee?.firstName} ${row?.employee?.lastName}`}</Link>
            )
          : row?.employee &&
            `${row?.employee.firstName} ${row?.employee.lastName}`,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"createdAtDate"} />,
      selector: (row) => {
        return new Date(row?.createdAt).toLocaleString(locale);
      },
      sortable: true,
    },
    {
      cell: (row) => (
        <div className="global-dropdown">
          <Dropdown>
            <Dropdown.Toggle>
              <DotsIcon />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item onClick={(e) => handleEdit(getId(row))}>
                <EditIcon className="bi bi-pencil edit" />
                تعديل
              </Dropdown.Item> */}
              <Dropdown.Item onClick={(e) => handleDelete(getId(row))}>
                <DeleteIcon className="bi bi-trash delete" fill="#fff" />
                حذف
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <div className=" card-head-with-filter">
            <Row className="justify-content-between align-items-center">
              <Col xl={6} md={12} xs={12}>
                <div className="card-head">
                  <div>
                    <h4>
                      <FormattedMessage id={"jobsTableShow"} />{" "}
                    </h4>
                    <p>
                      {" "}
                      <FormattedMessage id={"jobsTableShowAll"} />
                    </p>
                  </div>
                </div>
              </Col>

              <Col xl={4} md={6} xs={12}>
                {/* status filter  */}
                <div className="jobs-status-filter">
                  <span className="title__">
                    <FormattedMessage id={"statusFilter"} />
                  </span>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <FormattedMessage id={selectedFilter} />
                      <svg
                        width="11"
                        height="7"
                        viewBox="0 0 11 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.14376 6.13396L1.06422 2.05441C0.867468 1.85766 0.867468 1.53867 1.06422 1.34194L1.54003 0.866126C1.73645 0.66971 2.05478 0.669332 2.25166 0.865287L5.5 4.09841L8.74832 0.865287C8.9452 0.669332 9.26353 0.66971 9.45995 0.866126L9.93576 1.34194C10.1325 1.53869 10.1325 1.85768 9.93576 2.05441L5.85625 6.13396C5.65949 6.3307 5.34051 6.3307 5.14376 6.13396Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </Dropdown.Toggle>
                    {renderJobsStatusFilter()}
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div>

          <div className="card-body">
            <DataTable
              columns={columns}
              data={props?.data}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              // selectableRows
              subHeader
              // subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
