import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editSocialsSettings } from "store/actions";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect } from "react";

const MainSettings = ({ settings, title }) => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let updatedMainSettings = [];

    for (const [key, value] of Object.entries(data)) {
      updatedMainSettings.push({
        title: key,
        value: value,
      });
    }

    dispatch(editSocialsSettings(updatedMainSettings));
  };

  useEffect(() => {
    settings?.map((setting) => setValue(setting?.title, setting.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  const renderSocialsSettings = () => {
    return settings?.map((setting) => {
      // setValue(setting?.title, setting.value);

      return (
        <Col lg={12} xs={12} key={setting?.title}>
          <div className="form-group required">
            <h5>{setting.name[locale]}</h5>
            <div>
              <input
                type="text"
                className="form-control form-outline"
                placeholder={setting.name[locale]}
                {...register(setting?.title, { required: true })}
              />
            </div>
            <p className="error-hint">
              {errors?.setting?.title && (
                <FormattedMessage id={"requiredField"} />
              )}
            </p>
          </div>
        </Col>
      );
    });
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>{title}</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  {renderSocialsSettings()}
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"saveChanges"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSettings;
