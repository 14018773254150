import {
  ADD_SKILL, ADD_SKILL_FAILURE, ADD_SKILL_SUCCESS,
  DELETE_SKILL, DELETE_SKILL_FAILURE, DELETE_SKILL_SUCCESS,
  EDIT_SKILL, EDIT_SKILL_FAILURE, EDIT_SKILL_SUCCESS,
  GET_ALL_SKILLS, GET_ALL_SKILLS_FAILURE, GET_ALL_SKILLS_SUCCESS,
  GET_SKILL, GET_SKILL_FAILURE, GET_SKILL_SUCCESS
} from "./actionTypes";



const initialState = {
  skills: [],
  loading: false,
  error: "",
  singleSkill: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get all skills  
    case GET_ALL_SKILLS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ALL_SKILLS_SUCCESS:

      state = {
        ...state,
        error: "",
        loading: false,
        skills: action.payload.skills,
      };
      break;

    case GET_ALL_SKILLS_FAILURE:

      state = { ...state, error: action.payload, loading: false };
      break;

    // add skill  
    case ADD_SKILL:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case ADD_SKILL_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleSkill: action.payload.skill,
      };
      break;

    case ADD_SKILL_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // get skill  
    case GET_SKILL:
      state = {
        ...state,
        error: "",
        loading: true,
        singleSkill: {}
      };
      break;

    case GET_SKILL_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleSkill: action.payload.skill,
      };
      break;

    case GET_SKILL_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // edit skill  
    case EDIT_SKILL:
      state = {
        ...state,
        error: "",
        loading: true,
        singleSkill: {}
      };
      break;

    case EDIT_SKILL_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleSkill: action.payload.skill,
      };
      break;

    case EDIT_SKILL_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // delete skill  
    case DELETE_SKILL:
      state = {
        ...state,
        error: "",
        loading: true,
        singleSkill: {}
      };
      break;

    case DELETE_SKILL_SUCCESS:
      const skillsAfterDeleteing = [
        ...state?.skills?.filter((type) => type?.id !== action.payload),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        skills: skillsAfterDeleteing,
      };
      break;

    case DELETE_SKILL_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;



    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
