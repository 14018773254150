import DataTable from "react-data-table-component";
import { Col } from "react-bootstrap";

import { Link } from "react-router-dom";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";

const Table = (props) => {
  const { formatMessage } = useIntl();

  const columns = [
    {
      name: <FormattedMessage id={"requestNo"} />,
      selector: (row) => (
        <Link to={`/transactionRequests/${row?.id}`}>{row?.id}</Link>
      ),
      sortable: true,
    },
    {
      name: <FormattedMessage id={"amount"} />,
      selector: (row) => `${row?.amount} ${formatMessage({ id: "sar" })}`,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"createdAtDate"} />,
      selector: (row) => {
        return new Date(row?.createdAt).toLocaleString("en");
      },
      sortable: true,
    },
    {
      name: <FormattedMessage id={"status"} />,
      selector: (row) => <FormattedMessage id={row?.status} />,
      sortable: true,
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>{props?.title} </h4>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={props?.data}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              subHeader
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
