import {
  GET_ALL_JOBS,
  GET_ALL_JOBS_FAILURE,
  GET_ALL_JOBS_SUCCESS,
  GET_JOB,
  GET_JOB_FAILURE,
  GET_JOB_SUCCESS,
  GET_ALL_EMPLOYEE_JOBS,
  GET_ALL_EMPLOYEE_JOBS_FAILURE,
  GET_ALL_EMPLOYEE_JOBS_SUCCESS,
  GET_ALL_PROVIDER_JOBS,
  GET_ALL_PROVIDER_JOBS_FAILURE,
  GET_ALL_PROVIDER_JOBS_SUCCESS,
  DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,
} from "./actionTypes";

export const getAllJobs = (payload) => {
  return {
    type: GET_ALL_JOBS,
    payload: payload,
  };
};
export const getAllJobsSuccess = (payload) => {
  return {
    type: GET_ALL_JOBS_SUCCESS,
    payload: payload,
  };
};

export const getAllJobsFailure = (error) => {
  return {
    type: GET_ALL_JOBS_FAILURE,
    payload: error,
  };
};
export const getJob = (payload) => {
  return {
    type: GET_JOB,
    payload: payload,
  };
};
export const getJobSuccess = (payload) => {
  return {
    type: GET_JOB_SUCCESS,
    payload: payload,
  };
};

export const getJobFailure = (error) => {
  return {
    type: GET_JOB_FAILURE,
    payload: error,
  };
};

export const deleteJob = (payload) => {
  return {
    type: DELETE_JOB,
    payload: payload,
  };
};
export const deleteJobSuccess = (payload) => {
  return {
    type: DELETE_JOB_SUCCESS,
    payload: payload,
  };
};

export const deleteJobFailure = (error) => {
  return {
    type: DELETE_JOB_FAILURE,
    payload: error,
  };
};

export const getAllUserEmployeeJobs = (payload) => {
  return {
    type: GET_ALL_EMPLOYEE_JOBS,
    payload: payload,
  };
};
export const getAllUserEmployeeJobsSuccess = (payload) => {
  return {
    type: GET_ALL_EMPLOYEE_JOBS_SUCCESS,
    payload: payload,
  };
};

export const getAllUserEmployeeJobsFailure = (error) => {
  return {
    type: GET_ALL_EMPLOYEE_JOBS_FAILURE,
    payload: error,
  };
};

export const getAllUserProviderJobs = (payload) => {
  return {
    type: GET_ALL_PROVIDER_JOBS,
    payload: payload,
  };
};
export const getAllUserProviderJobsSuccess = (payload) => {
  return {
    type: GET_ALL_PROVIDER_JOBS_SUCCESS,
    payload: payload,
  };
};

export const getAllUserProviderJobsFailure = (error) => {
  return {
    type: GET_ALL_PROVIDER_JOBS_FAILURE,
    payload: error,
  };
};
