import ControlArea from 'components/Layout/ControlArea';
import BootyPagination from 'components/shared/Pagination';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllBanks } from 'store/actions';
import Icon from "assets/svgs/banks.svg";
import Table from './Table';
import { checkPermissions } from 'helpers/authentication/checkPermissions';

const Banks = () => {

  const { banks, loading, error } = useSelector((state) => state.banks);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false)
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllBanks(searchParams));
  }, [dispatch, searchParams]);

  // error 
  useEffect(() => {
    if (error && isDispatched) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error, formatMessage, locale, isDispatched]);

  return (
    <>
      <div className="banks-wrap">
        {checkPermissions(["banks-store"]) && <ControlArea
          btnTxt={<FormattedMessage id={"addBank"} />}
          cardTxt={<FormattedMessage id={"banks"} />}
          icon={Icon}
          url="/banks/addBank"
        />}

        <Table data={banks?.banks} loading={loading} />
        <BootyPagination metaData={banks?.metadata} />

      </div>


    </>
  )
}

export default Banks

