import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllPages } from 'store/actions';
// import ControlArea from 'components/Layout/ControlArea';
// import Icon from "assets/svgs/blog.svg";
import Table from './Table';
// import { checkPermissions } from 'helpers/authentication/checkPermissions';

const Pages = () => {

  const { pages, loading, error } = useSelector((state) => state.pages);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();


  // error 
  useEffect(() => {
    if (error) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error, formatMessage, locale]);
  useEffect(() => {
    dispatch(getAllPages());
  }, [dispatch]);

  return (
    <>
      <div className="pages-wrap">
        {/* {checkPermissions(["pages-store"]) && <ControlArea
          btnTxt={<FormattedMessage id={"addPage"} />}
          cardTxt={<FormattedMessage id={"pages"} />}
          icon={Icon}
          url="/pages/addPage"
        />} */}

        <Table data={pages} loading={loading} />
      </div>
    </>
  )
}

export default Pages