import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_BANK,
  DELETE_BANK,
  EDIT_BANK,
  GET_ALL_BANKS,
  GET_BANK,
} from "./actionTypes";
import {
  addBankApi,
  getAllBanksApi,
  getBankApi,
  editBankApi,
  deleteBankApi,
} from "api/banks";
import {
  addBankFailure,
  addBankSuccess,
  deleteBankFailure,
  deleteBankSuccess,
  editBankFailure,
  editBankSuccess,
  getAllBanksFailure,
  getAllBanksSuccess,
  getBankFailure,
  getBankSuccess,
} from "./actions";

function* getAllBanks({ payload }) {
  try {
    const { data } = yield call(getAllBanksApi, payload);

    yield put(getAllBanksSuccess(data));
  } catch (error) {
    yield put(getAllBanksFailure(error?.response?.data || error));
  }
}

function* addBank({ payload }) {
  try {
    const { data } = yield call(addBankApi, payload.data);

    yield put(addBankSuccess(data));
    payload.navigate("/banks");
  } catch (error) {
    yield put(addBankFailure(error?.response?.data || error));
  }
}

function* getBank({ payload }) {
  try {
    const { data } = yield call(getBankApi, payload);
    yield put(getBankSuccess(data));
  } catch (error) {
    yield put(getBankFailure(error?.response?.data || error));
  }
}

function* editBank({ payload }) {
  try {
    const { data } = yield call(editBankApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editBankSuccess(data));
  } catch (error) {
    yield put(editBankFailure(error?.response?.data || error));
  }
}

function* deleteBank({ payload }) {
  try {
    yield call(deleteBankApi, payload);
    yield put(deleteBankSuccess(payload));
  } catch (error) {
    yield put(deleteBankFailure(error?.response?.data || error));
  }
}

export function* watchGetAllBanks() {
  yield takeEvery(GET_ALL_BANKS, getAllBanks);
}

export function* watchAddBank() {
  yield takeEvery(ADD_BANK, addBank);
}

export function* watchGetBank() {
  yield takeEvery(GET_BANK, getBank);
}

export function* watchEditBank() {
  yield takeEvery(EDIT_BANK, editBank);
}

export function* watchDeleteBank() {
  yield takeEvery(DELETE_BANK, deleteBank);
}

function* banksSaga() {
  yield all([
    fork(watchGetAllBanks),
    fork(watchAddBank),
    fork(watchGetBank),
    fork(watchEditBank),
    fork(watchDeleteBank),
  ]);
}

export default banksSaga;
