import React, { useEffect, useState } from "react";
import Loader from "../../../components/shared/Loader";
import { useForm } from "react-hook-form";
import cookie from "js-cookie";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import server from "api/server";

const BlogCategoriesShow = () => {
  const [loading, setLoading] = useState(false);
  const { blogCategoryID } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const onStart = () => {
    // dispatch(
    //   updateMeta({
    //     name: "اضافة نوع جديدة",
    //     breadcrumb: "الانواع",
    //     button: {
    //       show: false,
    //       text: null,
    //       link: null,
    //     },
    //   })
    // );
    server(Cookies)
      .get(`/blogCategories/${blogCategoryID}`)
      .then((data) => {
        setValue("name", data.data.data.category.name);
      });
  };

  // handleMethods
  const submitHandler = async (data) => {
    setLoading(true);
    try {
      const dataResponse = await server(cookie).put(
        `/blogCategories/${blogCategoryID}`,
        {
          ...data,
          image: " ",
          description: {
            ar: "",
            en: "",
          },
        }
      );
      reset();
      onStart();
      navigate("/blogCategories/index");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // watchers
  useEffect(() => {
    onStart();
  }, []);

  return (
    <section className="acc-form card">
      <div className="card-head">
        <h4>تعديل تصنيف مقالة</h4>
      </div>
      {loading && <Loader />}
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="row">
          <div className="col-md-12">
            <div className="">
              <div className="card-body">
                <div className="form-item form-group">
                  <h5 htmlFor="nameAr">اسم الفئة باللغة العربية</h5>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    id="nameAr"
                    {...register("name.ar", {
                      required: true,
                    })}
                  />
                </div>
              </div>
              <div className="card-body">
                <div className="form-item form-group">
                  <h5 htmlFor="nameEn">اسم الفئة بالغة الانجليزية</h5>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    id="nameEn"
                    {...register("name.en", {
                      required: true,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-item form-group mt-3">
              <button className="btn btn-success w-auto">ارسال</button>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default BlogCategoriesShow;
