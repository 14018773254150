import React from 'react'

const StarRate = ({ rate }) => {

  const renderRate = () => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      if (rate <= i) {
        stars[i] = <svg key={`star__${i}`} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.9289 4.72215L8.60804 4.24448L7.10681 1.21929C6.85661 0.696133 6.08325 0.673387 5.8103 1.21929L4.33182 4.24448L0.988181 4.72215C0.396789 4.81313 0.169331 5.541 0.601502 5.97317L2.98982 8.31599L2.42117 11.6141C2.33019 12.2055 2.96707 12.6604 3.49022 12.3875L6.46993 10.818L9.42689 12.3875C9.95004 12.6604 10.5869 12.2055 10.4959 11.6141L9.9273 8.31599L12.3156 5.97317C12.7478 5.541 12.5203 4.81313 11.9289 4.72215ZM8.74451 7.92931L9.29041 11.0682L6.46993 9.58976L3.6267 11.0682L4.1726 7.92931L1.87527 5.70022L5.03694 5.2453L6.46993 2.37933L7.88017 5.2453L11.0418 5.70022L8.74451 7.92931Z" fill="#F5B400" />
        </svg>
      } else {
        stars[i] = <svg key={`star__${i}`} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.09741 1.21929L4.61893 4.24448L1.27529 4.72215C0.683899 4.81313 0.45644 5.541 0.888611 5.97317L3.27692 8.31599L2.70828 11.6141C2.6173 12.2055 3.25418 12.6604 3.77733 12.3875L6.75704 10.818L9.714 12.3875C10.2372 12.6604 10.874 12.2055 10.7831 11.6141L10.2144 8.31599L12.6027 5.97317C13.0349 5.541 12.8074 4.81313 12.216 4.72215L8.89515 4.24448L7.39392 1.21929C7.14372 0.696133 6.37036 0.673387 6.09741 1.21929Z" fill="#F5B400" />
        </svg>
      }
    }
    return stars;
  }
  return (
    <div className='rate__'>
      {renderRate()}
    </div>
  )
}

export default StarRate