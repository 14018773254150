import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  DELETE_JOB,
  GET_ALL_EMPLOYEE_JOBS,
  GET_ALL_JOBS,
  GET_ALL_PROVIDER_JOBS,
  GET_JOB,
} from "./actionTypes";
import {
  getAllUserEmployeeJobsApi,
  getAllUserProviderJobsApi,
  getAllJobsApi,
  getJobApi,
  deleteJobApi,
} from "api/jobs";
import {
  deleteJobFailure,
  deleteJobSuccess,
  getAllJobsFailure,
  getAllJobsSuccess,
  getAllUserEmployeeJobsFailure,
  getAllUserEmployeeJobsSuccess,
  getAllUserProviderJobsFailure,
  getAllUserProviderJobsSuccess,
  getJobFailure,
  getJobSuccess,
} from "./actions";

function* getAllJobs({ payload }) {
  try {
    const { data } = yield call(getAllJobsApi, payload);
    yield put(getAllJobsSuccess(data));
  } catch (error) {
    yield put(getAllJobsFailure(error?.response?.data || error));
  }
}

function* getAllUserEmployeeJobs({ payload }) {
  try {
    const { data } = yield call(getAllUserEmployeeJobsApi, payload);
    yield put(getAllUserEmployeeJobsSuccess(data));
  } catch (error) {
    yield put(getAllUserEmployeeJobsFailure(error?.response?.data || error));
  }
}

function* getAllUserProviderJobs({ payload }) {
  try {
    const { data } = yield call(getAllUserProviderJobsApi, payload);
    yield put(getAllUserProviderJobsSuccess(data));
  } catch (error) {
    yield put(getAllUserProviderJobsFailure(error?.response?.data || error));
  }
}

function* getJob({ payload }) {
  try {
    const { data } = yield call(getJobApi, payload);
    yield put(getJobSuccess(data));
  } catch (error) {
    yield put(getJobFailure(error?.response?.data || error));
  }
}

function* deleteJob({ payload }) {
  try {
    yield call(deleteJobApi, payload);
    yield put(deleteJobSuccess(payload));
  } catch (error) {
    console.log("error", error);
    yield put(deleteJobFailure(error?.response?.data || error));
  }
}

export function* watchGetAllJobs() {
  yield takeEvery(GET_ALL_JOBS, getAllJobs);
}
export function* watchGetJob() {
  yield takeEvery(GET_JOB, getJob);
}
export function* watchDeleteJob() {
  yield takeEvery(DELETE_JOB, deleteJob);
}
export function* watchGetAllEmployeeJobs() {
  yield takeEvery(GET_ALL_EMPLOYEE_JOBS, getAllUserEmployeeJobs);
}

export function* watchGetAllProviderJobs() {
  yield takeEvery(GET_ALL_PROVIDER_JOBS, getAllUserProviderJobs);
}

function* jobsSaga() {
  yield all([
    fork(watchGetAllJobs),
    fork(watchGetJob),
    fork(watchDeleteJob),
    fork(watchGetAllEmployeeJobs),
    fork(watchGetAllProviderJobs),
  ]);
}

export default jobsSaga;
