import server from "./server";

export const getAdsApi = async () => {
  const response = await server().get(`/advertisements`);

  return response.data;
};

export const addAdApi = async (data) => {
  const response = await server().post(`/advertisements`, data);

  return response.data;
};

export const getSingleAdApi = async (id) => {
  const response = await server().get(`/advertisements/${id}`);

  return response.data;
};

export const editAdApi = async ({ id, data }) => {
  const response = await server().put(`/advertisements/${id}`, data);

  return response.data;
};

export const deleteAdApi = async (id) => {
  const response = await server().delete(`/advertisements/${id}`);

  return response.data;
};
