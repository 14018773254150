import { getId } from "helpers/functions";
import {
  GET_ADS,
  GET_ADS_FAILURE,
  GET_ADS_SUCCESS,
  EDIT_AD,
  EDIT_AD_FAILURE,
  EDIT_AD_SUCCESS,
  ADD_AD,
  ADD_AD_SUCCESS,
  ADD_AD_FAILURE,
  DELETE_AD,
  DELETE_AD_SUCCESS,
  DELETE_AD_FAILURE,
  GET_SINGLE_AD,
  GET_SINGLE_AD_SUCCESS,
  GET_SINGLE_AD_FAILURE,
} from "./actionTypes";

const initialState = {
  ads: [],
  loading: false,
  error: "",
  singleAd: {},
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADS:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_ADS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        ads: action.payload.ads,
      };
      break;

    case GET_ADS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // -----------------------------------------
    case GET_SINGLE_AD:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case GET_SINGLE_AD_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleAd: action.payload.ad,
      };
      break;

    case GET_SINGLE_AD_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // -----------------------------------------

    case ADD_AD:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case ADD_AD_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        ads: action.payload.ads,
      };
      break;

    case ADD_AD_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ------------------------------------

    case EDIT_AD:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case EDIT_AD_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        singleAd: action.payload.ad,
      };
      break;

    case EDIT_AD_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // --------------------------------------

    case DELETE_AD:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;
    case DELETE_AD_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        ads: state?.ads?.filter((e) => getId(e) !== action.payload),
      };
      break;

    case DELETE_AD_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
