import server from "./server";

export const getAllSkillsApi = async () => {
  const response = await server().get("/skills");

  return response.data;
}

export const addSkillApi = async (data) => {
  const response = await server().post("/skills", data);

  return response.data;
}


export const getSkillApi = async (id) => {
  const response = await server().get(`/skills/${id}`);

  return response.data;
}

export const editSkillApi = async ({ id, data }) => {
  const response = await server().put(`/skills/${id}`, data);

  return response.data;
}

export const deleteSkillApi = async (id) => {
  const response = await server().delete(`/skills/${id}`);
  return response.data;
}