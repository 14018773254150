import Loader from 'components/shared/Loader';
import BootyPagination from 'components/shared/Pagination';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllUserTransactionRequests, getUser } from 'store/actions';
import TransactionRequestsTable from "./TransactionRequestsTable"
import NotFound from "views/NotFound";
import Stars from "components/Stars";

const TransactionRequests = () => {

  const { transactionRequests, loading, error } = useSelector((state) => state.transactionRequests);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [searchParams] = useSearchParams();
  const [isDispatched, setIsDispatched] = useState(false)
  const { id } = useParams();
  const { singleUser } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getUser(id));
  }, [dispatch, id]);

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllUserTransactionRequests({ id, searchParams }));
  }, [dispatch, searchParams, id]);

  // error 
  useEffect(() => {
    if (error && isDispatched) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error, formatMessage, locale, isDispatched]);


  const renderMainUserInfo = () => {

    if (singleUser && Object.keys(singleUser).length === 0) return <Loader />;

    return <div className="card">
      <div className="card-body user-details jobs-details-user-info">
        <div className="user-main-info-wrapper">
          <Link className="user-main-block" to={`/users/show/${id}`} >
            <div className="img-block">
              <img className="store-logo" src={singleUser?.image} alt="" />
            </div>
            <div className="user-main-details">
              <h4 className="user-main-head">
                {`${singleUser?.firstName} ${singleUser?.lastName}`}
              </h4>
              <Stars rate={singleUser?.rate} />
            </div>
          </Link>

          <Link className='back-icon' to={`/users/show/${id}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-square-fill" viewBox="0 0 16 16">
              <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z" />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  }

  if (error) return <NotFound />

  return (
    <>
      {renderMainUserInfo()}
      <TransactionRequestsTable data={transactionRequests?.requests} title={<FormattedMessage id={"transactionRequestsTableShow"} />} loading={loading} />
      <BootyPagination metaData={transactionRequests?.metadata} />
    </>
  )
}

export default TransactionRequests;