export const GET_ALL_SKILLS = "GET_ALL_SKILLS";
export const GET_ALL_SKILLS_SUCCESS = "GET_ALL_SKILLS_SUCCESS";
export const GET_ALL_SKILLS_FAILURE = "GET_ALL_SKILLS_FAILURE";

export const GET_SKILL = "GET_SKILL";
export const GET_SKILL_SUCCESS = "GET_SKILL_SUCCESS";
export const GET_SKILL_FAILURE = "GET_SKILL_FAILURE";

export const ADD_SKILL = "ADD_SKILL";
export const ADD_SKILL_SUCCESS = "ADD_SKILL_SUCCESS";
export const ADD_SKILL_FAILURE = "ADD_SKILL_FAILURE";

export const EDIT_SKILL = "EDIT_SKILL";
export const EDIT_SKILL_SUCCESS = "EDIT_SKILL_SUCCESS";
export const EDIT_SKILL_FAILURE = "EDIT_SKILL_FAILURE";

export const DELETE_SKILL = "DELETE_SKILL";
export const DELETE_SKILL_SUCCESS = "DELETE_SKILL_SUCCESS";
export const DELETE_SKILL_FAILURE = "DELETE_SKILL_FAILURE";