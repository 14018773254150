import ControlArea from 'components/Layout/ControlArea';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAllCategories } from 'store/actions';
import Icon from "assets/svgs/categories.svg";
import Table from './Table';
import { checkPermissions } from 'helpers/authentication/checkPermissions';

const Categories = () => {

  const { categories, loading, error } = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false)



  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAllCategories());
  }, [dispatch]);


  // error 
  useEffect(() => {
    if (error && isDispatched) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }, [error, formatMessage, locale, isDispatched]);

  return (
    <>
      <div className="categories-wrap">

        {checkPermissions(["categories-store"]) && <ControlArea
          btnTxt={<FormattedMessage id={"addCategory"} />}
          cardTxt={<FormattedMessage id={"categories"} />}
          icon={Icon}
          url="/categories/addCategory"
        />}
        <Table data={categories} loading={loading} />
      </div>


    </>
  )
}

export default Categories

