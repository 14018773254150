import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import countriesIcon from "assets/svgs/dots.svg";
import Table from "./Table";
const CountriesIndex = () => {
  const { countries } = useSelector((state) => state.countries);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt="اضافة دولة جديدة"
          cardTxt="جميع الدول"
          icon={countriesIcon}
          url="/countries/addcountry"
        />
        <Table data={countries} />
      </div>
    </>
  );
};

export default CountriesIndex;
