import server from "./server";

export const getAllPagesApi = async () => {
  const response = await server().get("/pages");

  return response.data;
}

export const addPageApi = async (data) => {

  const response = await server().post("/pages", data);
  return response.data;
}


export const getPageApi = async (id) => {
  const response = await server().get(`/pages/${id}`);

  return response.data;
}

export const editPageApi = async ({ id, data }) => {
  const response = await server().put(`/pages/${id}`, data);

  return response.data;
}

export const deletePageApi = async (id) => {
  const response = await server().delete(`/pages/${id}`);

  return response.data;
}