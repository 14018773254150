import React, { useMemo, useState, forwardRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { withdrawalRequest } from "store/actions";
import { ReactComponent as SettingIcon } from "assets/svgs/save.svg";
import axios from "axios";
import cookie from "js-cookie";
import server from "api/server";

const Table = (props) => {
  const dispatch = useDispatch();
  const { formatMessage, locale, formatDate } = useIntl();
  const { appPresntage } = useSelector((state) => state.requestsReports);
  const BootyCheckbox = new forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check">
      <input
        htmlFor="booty-check"
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" id="booty-check" />
    </div>
  ));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  // const handleRejectTransactionRequest = (id) => {
  //   dispatch(rejectTransactionRequest(id));
  // };

  // const handleApproveTransactionRequest = (id) => {
  //   dispatch(
  //     approveTransactionRequest({
  //       id,
  //       toast,
  //       locale,
  //     })
  //   );
  // };

  const columns = [
    {
      name: <FormattedMessage id={"feeType"} />,
      selector: (row) => (
        // checkPermissions(["userTransactionRequests-show"]) ? (
        //   <Link to={`/transactionRequests/${row?.id}`}>{row?.type}</Link>
        // ) : (
        <FormattedMessage id={row?.type || " "} />
      ),
      // )
      sortable: true,
    },
    {
      name: <FormattedMessage id={"jobPrice"} />,
      selector: (row) =>
        `${parseFloat(row?.job?.price || 0)?.toFixed(2)} ${formatMessage({
          id: "sar",
        })}`,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"vatTitle"} />,
      selector: (row) =>
        `${parseFloat(row?.amount || 0)?.toFixed(2)} ${formatMessage({
          id: "sar",
        })}`,
      sortable: true,
    },
    {
      name: <FormattedMessage id={"jobTitle"} />,
      selector: (row) =>
        row?.job?.title ? (
          <Link to={`/jobs/${row?.job?._id}`}>{row?.job?.title}</Link>
        ) : (
          "--"
        ),
      sortable: true,
    },
    // {
    //   name: <FormattedMessage id={"status"} />,
    //   selector: (row) =>
    //     row?.status === "pending" ? (
    //       <Badge bg="warning">
    //         <FormattedMessage id={"pending"} />
    //       </Badge>
    //     ) : row?.status === "approved" ? (
    //       <Badge bg="success">
    //         <FormattedMessage id={"approved"} />
    //       </Badge>
    //     ) : (
    //       <Badge bg="danger">
    //         <FormattedMessage id={"rejected"} />
    //       </Badge>
    //     ),
    //   sortable: true,
    // },
    {
      name: <FormattedMessage id={"processDate"} />,
      selector: (row) => {
        return new Date(row?.createdAt).toLocaleString("en");
      },
      sortable: true,
    },
  ];

  const [xlsxData, setXlsxData] = useState([]);

  useEffect(() => {
    // fetchMetaData();
    if (props?.data?.length > 0)
      setXlsxData(
        props?.data.map((user) => {
          return {
            [formatMessage({ id: "feeType" })]: formatMessage({
              id: user?.type || " ",
            }),
            [formatMessage({ id: "jobPrice" })]: `${parseFloat(
              user?.job?.price || 0
            )?.toFixed(2)} ${formatMessage({
              id: "sar",
            })}`,
            [formatMessage({ id: "vatTitle" })]: `${parseFloat(
              user?.amount || 0
            )?.toFixed(2)} ${formatMessage({
              id: "sar",
            })}`,
            [formatMessage({ id: "processDate" })]: new Date(
              user?.createdAt
            ).toLocaleString("en"),
          };
        })
      );
  }, [formatMessage, locale, props?.data]);

  const exportToXLSX = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/appTransactions?exportType=excel`,
      {
        method: "GET",
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }
    );
    const blob = await response?.blob();
    const url = window.URL.createObjectURL(
      blob
      // new Blob([response.data], {
      //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Ehtiaj-Requests-Reports.xlsx");
    document.body.appendChild(link);
    link.click();

    // const worksheet = XLSX.utils.json_to_sheet(xlsxData);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // XLSX.writeFile(
    //   workbook,
    //   `Ehtiaj-Requests-Reports-${formatDate(new Date(), {
    //     year: "numeric",
    //     month: "numeric",
    //     day: "numeric",
    //   })}.xlsx`
    // );
  };

  const exportToCSV = async () => {
    const response = await server().get(`/appTransactions?exportType=csv`, {
      headers: {
        responseType: "blob",
      },
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Ehtiaj-Requests-Reports.csv");
    document.body.appendChild(link);
    link.click();

    // // Ensure data is an array of objects with keys representing column headers
    // if (
    //   !Array.isArray(xlsxData) ||
    //   xlsxData.length === 0 ||
    //   typeof xlsxData[0] !== "object"
    // ) {
    //   console.error("Invalid data format for CSV export");
    //   return;
    // }
    // // Convert data to a CSV string
    // // ===========================================================
    // const csv = Papa.unparse(xlsxData);
    // // Create a Blob with the CSV data
    // const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // // Create a download link and trigger the download
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);
    // link.download = `Ehtiaj-Requests-Reports-${formatDate(new Date(), {
    //   year: "numeric",
    //   month: "numeric",
    //   day: "numeric",
    // })}.csv`;
    // link.click();
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="filter-wrp">
        <div className="form-group">
          <input
            id="search"
            type="text"
            placeholder={formatMessage({ id: "search" })}
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="form-control"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#000"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </div>
        <Dropdown>
          <Dropdown.Toggle className="rounded-2">
            <button className="btn btn-blue rounded-2">
              <SettingIcon style={{ marginInlineEnd: "8px" }} />
              <FormattedMessage id="export" />
            </button>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={exportToXLSX}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi p-0 rounded-0 bi-filetype-xlsx bg-transparent"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM7.86 14.841a1.13 1.13 0 0 0 .401.823q.195.162.479.252.284.091.665.091.507 0 .858-.158.355-.158.54-.44a1.17 1.17 0 0 0 .187-.656q0-.336-.135-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.565-.21l-.621-.144a1 1 0 0 1-.405-.176.37.37 0 0 1-.143-.299q0-.234.184-.384.188-.152.513-.152.214 0 .37.068a.6.6 0 0 1 .245.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.199-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.44 0-.777.15-.336.149-.527.421-.19.273-.19.639 0 .302.123.524t.351.367q.229.143.54.213l.618.144q.31.073.462.193a.39.39 0 0 1 .153.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.168.07-.413.07-.176 0-.32-.04a.8.8 0 0 1-.249-.115.58.58 0 0 1-.255-.384zm-3.726-2.909h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415H1.5l1.24-2.016-1.228-1.983h.931l.832 1.438h.036zm1.923 3.325h1.697v.674H5.266v-3.999h.791zm7.636-3.325h.893l-1.274 2.007 1.254 1.992h-.908l-.85-1.415h-.035l-.853 1.415h-.861l1.24-2.016-1.228-1.983h.931l.832 1.438h.036z"
                />
              </svg>
              <FormattedMessage id="exportXlsx" />
            </Dropdown.Item>
            <Dropdown.Item onClick={exportToCSV}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi p-0 rounded-0 bi-filetype-csv bg-transparent"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z"
                />
              </svg>
              <FormattedMessage id="exportCsv" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      // <Filter
      //   extraElem={

      //   }
      //   onFilter={(e) => setFilterText(e.target.value)}
      //   onClear={handleClear}
      //   filterText={filterText}
      // />
    );
  }, [xlsxData, filterText, resetPaginationToggle]);

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
                <h4>
                  <FormattedMessage id={"requestsReportsTableShow"} />
                </h4>
                <p>
                  <FormattedMessage id={"requestsReportsTableShowAll"} />
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            {props?.topElement}

            <div className="my-4">
              <button
                title={formatMessage({ id: "emptyBalance" })}
                className="btn px-3 rounded-2 border-0 mx-1 btn-green"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
                onClick={() =>
                  dispatch(withdrawalRequest({ amount: Number(appPresntage) }))
                }
                disabled={!(Number(appPresntage) > 0)}
              >
                <FormattedMessage id="emptyBalance" />
              </button>
            </div>
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={props.loading}
              progressComponent={<Loader />}
              defaultSortField="name"
              selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
