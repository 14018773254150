import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_SKILL,
  DELETE_SKILL,
  EDIT_SKILL,
  GET_ALL_SKILLS,
  GET_SKILL,
} from "./actionTypes";
import {
  addSkillApi,
  getAllSkillsApi,
  getSkillApi,
  editSkillApi,
  deleteSkillApi,
} from "api/skills";
import {
  addSkillFailure,
  addSkillSuccess,
  deleteSkillFailure,
  deleteSkillSuccess,
  editSkillFailure,
  editSkillSuccess,
  getAllSkillsFailure,
  getAllSkillsSuccess,
  getSkillFailure,
  getSkillSuccess,
} from "./actions";

function* getAllSkills() {
  try {
    const { data } = yield call(getAllSkillsApi);
    yield put(getAllSkillsSuccess(data));
  } catch (error) {
    yield put(getAllSkillsFailure(error?.response?.data || error));
  }
}

function* addSkill({ payload }) {
  try {
    const { data } = yield call(addSkillApi, payload.data);

    yield put(addSkillSuccess(data));
    payload.navigate("/skills");
  } catch (error) {
    yield put(addSkillFailure(error?.response?.data || error));
  }
}

function* getSkill({ payload }) {
  try {
    const { data } = yield call(getSkillApi, payload);

    yield put(getSkillSuccess(data));
  } catch (error) {
    yield put(getSkillFailure(error?.response?.data || error));
  }
}

function* editSkill({ payload }) {
  try {
    const { data } = yield call(editSkillApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editSkillSuccess(data));
  } catch (error) {
    yield put(editSkillFailure(error?.response?.data || error));
  }
}

function* deleteSkill({ payload }) {
  try {
    yield call(deleteSkillApi, payload);
    yield put(deleteSkillSuccess(payload));
  } catch (error) {
    yield put(deleteSkillFailure(error?.response?.data || error));
  }
}

export function* watchGetAllSkills() {
  yield takeEvery(GET_ALL_SKILLS, getAllSkills);
}

export function* watchAddSkill() {
  yield takeEvery(ADD_SKILL, addSkill);
}

export function* watchGetSkill() {
  yield takeEvery(GET_SKILL, getSkill);
}

export function* watchEditSkill() {
  yield takeEvery(EDIT_SKILL, editSkill);
}

export function* watchDeleteSkill() {
  yield takeEvery(DELETE_SKILL, deleteSkill);
}

function* skillsSaga() {
  yield all([
    fork(watchGetAllSkills),
    fork(watchAddSkill),
    fork(watchGetSkill),
    fork(watchEditSkill),
    fork(watchDeleteSkill),
  ]);
}

export default skillsSaga;
