
import { store } from "../../store";

export const checkPermissions = (permissions) => {
  const currentUserPermissions = store.getState().authentication?.user?.role?.[0]?.permissions;
  const found = currentUserPermissions?.some(r => permissions?.indexOf(r) >= 0);

  return found;

}
