import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT,
  GET_ALL_BANK_ACCOUNTS,
  GET_BANK_ACCOUNT,
} from "./actionTypes";
import {
  addBankAccountApi,
  getAllBankAccountsApi,
  getBankAccountApi,
  editBankAccountApi,
  deleteBankAccountApi,
} from "api/bankAccounts";
import {
  addBankAccountFailure,
  addBankAccountSuccess,
  deleteBankAccountFailure,
  deleteBankAccountSuccess,
  editBankAccountFailure,
  editBankAccountSuccess,
  getAllBankAccountsFailure,
  getAllBankAccountsSuccess,
  getBankAccountFailure,
  getBankAccountSuccess,
} from "./actions";

function* getAllBankAccounts() {
  try {
    const { data } = yield call(getAllBankAccountsApi);
    yield put(getAllBankAccountsSuccess(data));
  } catch (error) {
    yield put(getAllBankAccountsFailure(error?.response?.data || error));
  }
}

function* addBankAccount({ payload }) {
  try {
    const { data } = yield call(addBankAccountApi, payload.data);

    yield put(addBankAccountSuccess(data));
    payload.navigate("/bankAccounts");
  } catch (error) {
    yield put(addBankAccountFailure(error?.response?.data || error));
  }
}

function* getBankAccount({ payload }) {
  try {
    const { data } = yield call(getBankAccountApi, payload);

    yield put(getBankAccountSuccess(data));
  } catch (error) {
    yield put(getBankAccountFailure(error?.response?.data || error));
  }
}

function* editBankAccount({ payload }) {
  try {
    const { data } = yield call(editBankAccountApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editBankAccountSuccess(data));
  } catch (error) {
    yield put(editBankAccountFailure(error?.response?.data || error));
  }
}

function* deleteBankAccount({ payload }) {
  try {
    yield call(deleteBankAccountApi, payload);
    yield put(deleteBankAccountSuccess(payload));
  } catch (error) {
    yield put(deleteBankAccountFailure(error?.response?.data || error));
  }
}

export function* watchGetAllBankAccounts() {
  yield takeEvery(GET_ALL_BANK_ACCOUNTS, getAllBankAccounts);
}

export function* watchAddBankAccount() {
  yield takeEvery(ADD_BANK_ACCOUNT, addBankAccount);
}

export function* watchGetBankAccount() {
  yield takeEvery(GET_BANK_ACCOUNT, getBankAccount);
}

export function* watchEditBankAccount() {
  yield takeEvery(EDIT_BANK_ACCOUNT, editBankAccount);
}

export function* watchDeleteBankAccount() {
  yield takeEvery(DELETE_BANK_ACCOUNT, deleteBankAccount);
}

function* bankAccountsSaga() {
  yield all([
    fork(watchGetAllBankAccounts),
    fork(watchAddBankAccount),
    fork(watchGetBankAccount),
    fork(watchEditBankAccount),
    fork(watchDeleteBankAccount),
  ]);
}

export default bankAccountsSaga;
