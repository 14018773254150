import ControlArea from "components/Layout/ControlArea";
import { useState } from "react";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getAds } from "store/actions";
// import Icon from "assets/svgs/medias.svg";
import Table from "./Table";
import { checkPermissions } from "helpers/authentication/checkPermissions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

const Ads = () => {
  const dispatch = useDispatch();
  const { ads, loading, error } = useSelector((state) => state.ads);
  const { formatMessage, locale } = useIntl();
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    setIsDispatched(true);
    dispatch(getAds());
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error && isDispatched) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale, isDispatched]);

  return (
    <>
      <div className="ads-wrap">
        {/* {checkPermissions(["ads-store"]) && ( */}
        <ControlArea
          btnTxt={<FormattedMessage id={"addAd"} />}
          cardTxt={<FormattedMessage id={"ads"} />}
          // icon={Icon}
          url="/ads/add"
        />
        {/* )} */}

        <Table data={ads} loading={loading} />
      </div>
    </>
  );
};

export default Ads;
