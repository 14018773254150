import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';

import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import { editSkill, getAllCategories, getSkill } from "store/actions";
import Loader from "components/shared/Loader";


const EditSkill = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, error, singleSkill } = useSelector((state) => state.skills);
  const { categories } = useSelector((state) => state.categories);


  const { register, handleSubmit, reset, control, formState: { errors } } = useForm();


  // effects
  useEffect(() => {
    dispatch(getSkill(id));
    dispatch(getAllCategories());
  }, [dispatch, id]);

  useEffect(() => {

    reset({
      name: singleSkill?.name,
      category: {
        value: singleSkill?.category?.id,
        label: singleSkill?.category?.name?.[locale]
      }
    });


  }, [locale, reset, singleSkill]);

  // error 
  useEffect(() => {

    if (error) {


      if (error) {
        error?.errors.forEach(error => {
          toast.error(error?.msg, {
            position: locale === "ar" ? "bottom-left" : "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      }

    }

  }, [error, formatMessage, locale, navigate]);


  const onSubmit = (data) => {

    dispatch(editSkill({ id, data, navigate }));
  };

  const renderListCategories = () => {
    return categories?.map((category) => ({
      label: category.name?.[locale],
      value: category?.name?.[locale],
      id: category?.id,
    }));
  };


  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"editSkill"} /></h4>
          </div>

          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>

                  {/* name  */}
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"name"} />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "name" })}
                          {...register("name", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5><FormattedMessage id={"category"} /> </h5>
                      <div>
                        <Controller
                          control={control}
                          render={({ field: { onChange, value, name, ref, selected } }) => (
                            <Select
                              inputRef={ref}
                              name={name}
                              placeholder={<FormattedMessage id={"category"} />}
                              options={renderListCategories()}
                              defaultValue={renderListCategories()?.filter(
                                (value) =>
                                  value?.id === singleSkill?.category?.id
                              )}
                              onChange={(selected) => {
                                onChange(selected?.id);
                              }}
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"

                            />
                          )}
                          name={"category"}
                          rules={{ required: true }}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.category?.type === "required" &&
                          <FormattedMessage id={"requiredField"} />}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"saveChanges"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSkill;
