import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addCountryApi,
  deleteCountryApi,
  editCountryApi,
  getCountriesApi,
  getSingleCountryApi,
  toggleCountryApi,
} from "api/countries";

// Login Redux States
import {
  ADD_COUNTRY,
  DELETE_COUNTRY,
  EDIT_COUNTRY,
  GET_COUNTRIES,
  GET_SINGLE_COUNTRY,
  TOGGLE_COUNTRY,
} from "./actionTypes";

import {
  addCountryFailure,
  addCountrySuccess,
  deleteCountryFailure,
  deleteCountrySuccess,
  editCountryFailure,
  editCountrySuccess,
  getCountriesFailure,
  getCountriesSuccess,
  getSingleCountryFailure,
  getSingleCountrySuccess,
  toggleCountryFailure,
  toggleCountrySuccess,
} from "./actions";

import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getCountries() {
  try {
    const { data } = yield call(getCountriesApi);
    yield put(getCountriesSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data });
    yield put(getCountriesFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getSingleCountry({ payload }) {
  try {
    const { data } = yield call(getSingleCountryApi, payload);
    yield put(getSingleCountrySuccess(data));
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(getSingleCountryFailure(error.response?.data?.message || error));
  }
}

function* addCountry({ payload }) {
  try {
    const { data } = yield call(addCountryApi, payload.data);
    yield put(addCountrySuccess(data));
    yield payload.navigate("/countries");
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(addCountryFailure(error.response?.data?.message || error));
  }
}

function* toggleCountry({ payload }) {
  try {
    const { data } = yield call(toggleCountryApi, payload);
    yield put(toggleCountrySuccess(data));
    // yield payload.navigate("/countries");
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(toggleCountryFailure(error.response?.data?.message || error));
  }
}

function* editCountry({ payload }) {
  try {
    yield call(editCountryApi, payload);
    yield put(editCountrySuccess(payload));
    yield payload.navigate("/countries");
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(editCountryFailure(error?.response?.data || error));
  }
}

function* deleteCountry({ payload }) {
  try {
    yield call(deleteCountryApi, payload);
    yield put(deleteCountrySuccess(payload));
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(deleteCountryFailure(error?.response?.data || error));
  }
}

export function* watchGetCountries() {
  yield takeEvery(GET_COUNTRIES, getCountries);
}

export function* watchSingleCountry() {
  yield takeEvery(GET_SINGLE_COUNTRY, getSingleCountry);
}

export function* watchAddCountry() {
  yield takeEvery(ADD_COUNTRY, addCountry);
}

export function* watchToggleCountry() {
  yield takeEvery(TOGGLE_COUNTRY, toggleCountry);
}

export function* watchEditCountry() {
  yield takeEvery(EDIT_COUNTRY, editCountry);
}

export function* watchDeleteCountry() {
  yield takeEvery(DELETE_COUNTRY, deleteCountry);
}

function* countriesSaga() {
  yield all([fork(watchGetCountries)]);
  yield all([fork(watchSingleCountry)]);
  yield all([fork(watchToggleCountry)]);
  yield all([fork(watchAddCountry)]);
  yield all([fork(watchEditCountry)]);
  yield all([fork(watchDeleteCountry)]);
}

export default countriesSaga;
