export const GET_ALL_REQUESTS = "GET_ALL_REQUESTS";
export const GET_ALL_REQUESTS_SUCCESS = "GET_ALL_REQUESTS_SUCCESS";
export const GET_ALL_REQUESTS_FAILURE = "GET_ALL_REQUESTS_FAILURE";

export const GET_ALL_USER_REQUESTS = "GET_ALL_USER_REQUESTS";
export const GET_ALL_USER_REQUESTS_SUCCESS = "GET_ALL_USER_REQUESTS_SUCCESS";
export const GET_ALL_USER_REQUESTS_FAILURE = "GET_ALL_USER_REQUESTS_FAILURE";

export const GET_REQUEST = "GET_REQUEST";
export const GET_REQUEST_SUCCESS = "GET_REQUEST_SUCCESS";
export const GET_REQUEST_FAILURE = "GET_REQUEST_FAILURE";

export const APPROVE_REQUEST = "APPROVE_REQUEST";
export const APPROVE_REQUEST_SUCCESS = "APPROVE_REQUEST_SUCCESS";
export const APPROVE_REQUEST_FAILURE = "APPROVE_REQUEST_FAILURE";

export const APPROVE_ALL_REQUESTS = "APPROVE_ALL_REQUESTS";
export const APPROVE_ALL_REQUESTS_SUCCESS = "APPROVE_ALL_REQUESTS_SUCCESS";
export const APPROVE_ALL_REQUESTS_FAILURE = "APPROVE_ALL_REQUESTS_FAILURE";

export const REJECT_REQUEST = "REJECT_REQUEST";
export const REJECT_REQUEST_SUCCESS = "REJECT_REQUEST_SUCCESS";
export const REJECT_REQUEST_FAILURE = "REJECT_REQUEST_FAILURE";

export const DELETE_REQUEST = "DELETE_REQUEST";
export const DELETE_REQUEST_SUCCESS = "DELETE_REQUEST_SUCCESS";
export const DELETE_REQUEST_FAILURE = "DELETE_REQUEST_FAILURE";
