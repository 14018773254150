import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  ADD_ADMIN,
  DELETE_ADMIN,
  EDIT_ADMIN,
  GET_ALL_ADMINS,
  GET_ADMIN,
} from "./actionTypes";
import {
  addAdminApi,
  getAllAdminsApi,
  getAdminApi,
  deleteAdminApi,
  editAdminApi,
} from "api/admins";
import {
  addAdminFailure,
  addAdminSuccess,
  deleteAdminFailure,
  deleteAdminSuccess,
  editAdminFailure,
  editAdminSuccess,
  getAllAdminsFailure,
  getAllAdminsSuccess,
  getAdminFailure,
  getAdminSuccess,
} from "./actions";

function* getAllAdmins({ payload }) {
  try {
    const { data } = yield call(getAllAdminsApi, payload);
    yield put(getAllAdminsSuccess(data));
  } catch (error) {
    yield put(getAllAdminsFailure(error?.response?.data || error));
  }
}

function* addAdmin({ payload }) {
  try {
    const { data } = yield call(addAdminApi, payload.data);

    yield put(addAdminSuccess(data));
    payload.navigate("/admins");
  } catch (error) {
    yield put(addAdminFailure(error?.response?.data || error));
  }
}

function* getAdmin({ payload }) {
  try {
    const { data } = yield call(getAdminApi, payload);

    yield put(getAdminSuccess(data));
  } catch (error) {
    yield put(getAdminFailure(error?.response?.data || error));
  }
}

function* editAdmin({ payload }) {
  try {
    const { data } = yield call(editAdminApi, {
      data: payload.data,
      id: payload.id,
    });
    yield put(editAdminSuccess(data));
    payload.navigate("/admins");
  } catch (error) {
    yield put(editAdminFailure(error?.response?.data || error));
  }
}

function* deleteAdmin({ payload }) {
  try {
    yield call(deleteAdminApi, payload);

    yield put(deleteAdminSuccess(payload));
  } catch (error) {
    yield put(deleteAdminFailure(error?.response?.data || error));
  }
}

export function* watchGetAllAdmins() {
  yield takeEvery(GET_ALL_ADMINS, getAllAdmins);
}

export function* watchAddAdmin() {
  yield takeEvery(ADD_ADMIN, addAdmin);
}

export function* watchGetAdmin() {
  yield takeEvery(GET_ADMIN, getAdmin);
}
export function* watchEditAdmin() {
  yield takeEvery(EDIT_ADMIN, editAdmin);
}

export function* watchDeleteAdmin() {
  yield takeEvery(DELETE_ADMIN, deleteAdmin);
}

function* adminsSaga() {
  yield all([
    fork(watchGetAllAdmins),
    fork(watchAddAdmin),
    fork(watchGetAdmin),
    fork(watchEditAdmin),
    fork(watchDeleteAdmin),
  ]);
}

export default adminsSaga;
