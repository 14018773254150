import BootyPagination from "components/shared/Pagination";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  approveAllTransactionsRequest,
  getAllTransactionRequests,
} from "store/actions";
import Table from "./Table";
import { FormattedMessage, useIntl } from "react-intl";

const TransactionRequests = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { transactionRequests, loading } = useSelector(
    (state) => state.transactionRequests
  );
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getAllTransactionRequests(searchParams));
  }, [dispatch, searchParams]);

  return (
    <>
      <div className="requests-wrap">
        <Table data={transactionRequests?.requests} loading={loading} />

        <BootyPagination metaData={transactionRequests?.metadata} />
      </div>
    </>
  );
};

export default TransactionRequests;
