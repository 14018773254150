export const GET_ALL_BANK_ACCOUNTS = "GET_ALL_BANK_ACCOUNTS";
export const GET_ALL_BANK_ACCOUNTS_SUCCESS = "GET_ALL_BANK_ACCOUNTS_SUCCESS";
export const GET_ALL_BANK_ACCOUNTS_FAILURE = "GET_ALL_BANK_ACCOUNTS_FAILURE";

export const GET_BANK_ACCOUNT = "GET_BANK_ACCOUNT";
export const GET_BANK_ACCOUNT_SUCCESS = "GET_BANK_ACCOUNT_SUCCESS";
export const GET_BANK_ACCOUNT_FAILURE = "GET_BANK_ACCOUNT_FAILURE";

export const ADD_BANK_ACCOUNT = "ADD_BANK_ACCOUNT";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAILURE = "ADD_BANK_ACCOUNT_FAILURE";

export const EDIT_BANK_ACCOUNT = "EDIT_BANK_ACCOUNT";
export const EDIT_BANK_ACCOUNT_SUCCESS = "EDIT_BANK_ACCOUNT_SUCCESS";
export const EDIT_BANK_ACCOUNT_FAILURE = "EDIT_BANK_ACCOUNT_FAILURE";

export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";
export const DELETE_BANK_ACCOUNT_SUCCESS = "DELETE_BANK_ACCOUNT_SUCCESS";
export const DELETE_BANK_ACCOUNT_FAILURE = "DELETE_BANK_ACCOUNT_FAILURE";