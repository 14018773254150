import server from "./server";

export const getAllReportRequestsApi = async (searchParams) => {
  const response = await server().get(
    `/appTransactions/?${searchParams || ""}`
  );

  return response.data;
};

export const getReportRequestApi = async (id) => {
  const response = await server().get(`/appTransactions/${id}`);
  return response.data;
};

export const deleteReportRequestApi = async (id) => {
  const response = await server().delete(`/appTransactions/${id}`);
  return response.data;
};

export const approveReportRequestApi = async ({ id }) => {
  const response = await server().post(`/appTransactions/approve/${id}`);

  return response.data;
};

export const approveAllReportsRequestApi = async () => {
  const response = await server().post(`/appTransactions/approveAll/`);

  return response.data;
};

export const withdrawRequestApi = async (body) => {
  const response = await server().post(`/appTransactions/withdrawal/`, body);

  return response.data;
};

export const rejectReportRequestApi = async (id) => {
  const response = await server().post(`/appTransactions/reject/${id}`);

  return response.data;
};

export const getAllUserReportRequestsApi = async (id) => {
  const response = await server().get(`/appTransactions/dashboard/${id}`);

  return response.data;
};
