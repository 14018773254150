import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  DELETE_REQUEST,
  GET_ALL_REQUESTS,
  GET_REQUEST,
  APPROVE_REQUEST,
  REJECT_REQUEST,
  GET_ALL_USER_REQUESTS,
  APPROVE_ALL_REQUESTS,
  WITHDRAWAL_REQUEST,
} from "./actionTypes";
import {
  getAllReportRequestsApi,
  getReportRequestApi,
  deleteReportRequestApi,
  approveReportRequestApi,
  rejectReportRequestApi,
  getAllUserReportRequestsApi,
  approveAllReportsRequestApi,
  withdrawRequestApi,
} from "api/reportRequests";
import {
  approveAllReportsRequestFailure,
  approveAllReportsRequestSuccess,
  approveReportRequestFailure,
  approveReportRequestSuccess,
  deleteReportRequestFailure,
  deleteReportRequestSuccess,
  getAllReportRequestsFailure,
  getAllReportRequestsSuccess,
  getAllUserReportRequestsFailure,
  getAllUserReportRequestsSuccess,
  getReportRequestFailure,
  getReportRequestSuccess,
  rejectReportRequestFailure,
  rejectReportRequestSuccess,
  withdrawalRequestFailure,
  withdrawalRequestSuccess,
} from "./actions";
import { toast } from "react-toastify";

function* getAllReportRequests({ payload }) {
  try {
    const { data } = yield call(getAllReportRequestsApi, payload);
    yield put(getAllReportRequestsSuccess(data));
  } catch (error) {
    yield put(getAllReportRequestsFailure(error?.response?.data || error));
  }
}

function* getReportRequest({ payload }) {
  try {
    const { data } = yield call(getReportRequestApi, payload);
    yield put(getReportRequestSuccess(data));
  } catch (error) {
    yield put(getReportRequestFailure(error?.response?.data || error));
  }
}

function* deleteReportRequest({ payload }) {
  try {
    yield call(deleteReportRequestApi, payload);
    yield put(deleteReportRequestSuccess(payload));
  } catch (error) {
    yield put(deleteReportRequestFailure(error?.response?.data || error));
  }
}

function* approveReportRequest({ payload }) {
  try {
    const { data } = yield call(approveReportRequestApi, payload);
    yield put(approveReportRequestSuccess(data));
  } catch (error) {
    yield put(approveReportRequestFailure(error?.response?.data || error));
    yield payload.toast.error(
      error.response?.data?.errors.msg?.[payload?.locale] || error,
      {
        position: payload.locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );
  }
}

function* approveAllReportsRequest({ payload }) {
  try {
    const { data } = yield call(approveAllReportsRequestApi, payload);
    yield put(approveAllReportsRequestSuccess(data));
    if (payload?.formatMessage)
      toast.success(
        payload?.formatMessage({ id: "approvedDoneOn" }) +
          data?.ReportRequests?.length,
        {
          theme: "colored",
        }
      );
  } catch (error) {
    console.log("error", error);
    yield put(approveAllReportsRequestFailure(error?.response?.data || error));
  }
}

function* rejectReportRequest({ payload }) {
  try {
    const { data } = yield call(rejectReportRequestApi, payload);
    yield put(rejectReportRequestSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(rejectReportRequestFailure(error?.response?.data || error));
  }
}

function* withdrawalRequest({ payload }) {
  try {
    yield call(withdrawRequestApi, payload);
    yield put(withdrawalRequestSuccess());
    yield getAllReportRequests({});
  } catch (error) {
    console.log(error);
    yield put(withdrawalRequestFailure(error?.response?.data || error));
  }
}

function* getAllUserReportRequests({ payload }) {
  try {
    const { data } = yield call(getAllUserReportRequestsApi, payload.id);
    yield put(getAllUserReportRequestsSuccess(data));
  } catch (error) {
    yield put(getAllUserReportRequestsFailure(error?.response?.data || error));
  }
}

export function* watchGetAllReportRequests() {
  yield takeEvery(GET_ALL_REQUESTS, getAllReportRequests);
}

export function* watchGetReportRequest() {
  yield takeEvery(GET_REQUEST, getReportRequest);
}

export function* watchDeleteReportRequest() {
  yield takeEvery(DELETE_REQUEST, deleteReportRequest);
}

export function* watchApproveReportRequest() {
  yield takeEvery(APPROVE_REQUEST, approveReportRequest);
}

export function* watchApproveAllReportsRequest() {
  yield takeEvery(APPROVE_ALL_REQUESTS, approveAllReportsRequest);
}

export function* watchWithdrwalRequest() {
  yield takeEvery(WITHDRAWAL_REQUEST, withdrawalRequest);
}
export function* watchRejectReportRequest() {
  yield takeEvery(REJECT_REQUEST, rejectReportRequest);
}
export function* watchGetAllUserReportRequests() {
  yield takeEvery(GET_ALL_USER_REQUESTS, getAllUserReportRequests);
}

function* ReportRequestsSaga() {
  yield all([
    fork(watchGetAllReportRequests),
    fork(watchGetReportRequest),
    fork(watchDeleteReportRequest),
    fork(watchApproveReportRequest),
    fork(watchApproveAllReportsRequest),
    fork(watchRejectReportRequest),
    fork(watchWithdrwalRequest),
    fork(watchGetAllUserReportRequests),
  ]);
}

export default ReportRequestsSaga;
